export const DefectDoc_Date_TXT = []
DefectDoc_Date_TXT["en"] = "Date"
DefectDoc_Date_TXT["th"] = "วันที่"

export const DefectDoc_DateEdit_TXT = []
DefectDoc_DateEdit_TXT["en"] = "Modified date"
DefectDoc_DateEdit_TXT["th"] = "วันที่แก้ไข"

export const DefectDoc_Title_TXT = []
DefectDoc_Title_TXT["en"] = "Defect Report"
DefectDoc_Title_TXT["th"] = "รายงาน defect"