import React , { useContext } from 'react'
import { LoadingContext } from '../contexts/LoadingContext'
import Loading from './Loading'
const MasterPage = ({ children }) => {
    const loadingContext = useContext(LoadingContext)

    return (
        <>
            {loadingContext?.state?.isLoading === true && <Loading /> }
            {loadingContext?.state?.isLoading === false && children }
        </>
    )
}

export default MasterPage