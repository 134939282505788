export const checkProductType = (pm, rental) => {
    let cid_pm = pm || localStorage.getItem("cid_pm")
    let cid_rental = rental || localStorage.getItem("cid_rental")

    if (cid_rental) {
        return "rental"
    }

    if (cid_pm) {
        return "pmlite"
    }

    return "remlite"

}
