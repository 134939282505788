import {
    Stack, 
    Card, 
    Typography, 
    Box, 
} from '@mui/material'
import * as api from '../../api/Regulation'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext'
import { useLocation, useNavigate } from 'react-router-dom';
import { BackDropLoading } from '../../ui-component';
export const Regulations = () => {

    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(ProfileContext)

    const location = useLocation()
    const navigate = useNavigate()


    useEffect(() => {

        const loadList = async () => {
            setLoading(true)
            let res = await api.getRegulationList({
                "line_id": profile?.line_id,
                "product_type": houseInfo?.product_type,
                "project_id": houseInfo?.project_id
            })
    
            setList(res.data?.data)
            setLoading(false)
        }

        if (profile !== null) {
            loadList()
        }

    }, [profile, houseInfo]);


    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }

    }, [context]);

    return (
        <>
            <Box sx={{ height: "100vh", backgroundColor: "#F2F2F2" }}>
                <Stack direction={"column"} alignItems={"center"} >

                    {
                        list?.length > 0 && list.map((element, index) => <Card key={index} variant="outlined" sx={{
                            width: "100%", display: "flex",
                            height: "100%",
                            marginTop: 2,
                            justifyContent: "center"
                        }} onClick={() => {
                            // setIdSelected(element.id)
                            // setIsDetailVisible(true)
                            navigate(`/regulations/detail${location.search}`, {
                                state: {
                                    idSelected: element.id,
                                    title: element.title
                                }
                            })
                        }}>
                            <Stack direction={"column"} alignItems={"center"} sx={{ width: "100%", padding: 2 }}>

                                <Box
                                    sx={{
                                        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url('${element["images"]["image1024"]}')`,
                                        height: "200px",
                                        width: "85%",
                                        borderRadius: "10px",
                                        marginTop: 2,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",
                                    }}
                                >

                                    <Box sx={{ height: "70px", width: "100%" }}>
                                        <Stack direction="row" spacing={0.2}>
                                            <Typography variant={"body1"} ml={2} sx={{ color: "white", textShadow: "1px 0.5px #FFFFFF", textAlign: "justify" }}>
                                                {element.title}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Stack>

                        </Card>)
                    }
                </Stack>
                <BackDropLoading
                    loading={loading}
                />
            </Box>
        </>
    )
}
