
export const Service_TXT = []
Service_TXT["en"] = "Service"
Service_TXT["th"] = "บริการ"

export const Booking_TXT = []
Booking_TXT["en"] = "Booking"
Booking_TXT["th"] = "กำลังจอง"

export const System_Save_Cancel_TXT = []
System_Save_Cancel_TXT["en"] = "The system has saved your cancellation."
System_Save_Cancel_TXT["th"] = "ระบบได้บันทึกการยกเลิกการจองของคุณแล้ว"

export const System_Save_Access_TXT = []
System_Save_Access_TXT["en"] = "The system has saved your access confirmation."
System_Save_Access_TXT["th"] = "ระบบได้บันทึกการยืนยันการเข้าใช้งานของคุณแล้ว"

export const Date_TXT = []
Date_TXT["en"] = "Date"
Date_TXT["th"] = "วันที่"

export const Period_TXT = []
Period_TXT["en"] = "Period"
Period_TXT["th"] = "ช่วงเวลา"

export const Booking_Code_TXT = []
Booking_Code_TXT["en"] = "booking code"
Booking_Code_TXT["th"] = "รหัสการจอง"

export const Confirm_Access_TXT = []
Confirm_Access_TXT["en"] = "Confirm Booking"
Confirm_Access_TXT["th"] = "ยืนยันการจอง"

export const Cancel_Booking_TXT = []
Cancel_Booking_TXT["en"] = "Cancel booking"
Cancel_Booking_TXT["th"] = "ยกเลิกจอง"

export const Are_you_sure_TXT = []
Are_you_sure_TXT["en"] = "Are you sure?"
Are_you_sure_TXT["th"] = "คุณแน่ใจหรือไม่?"

export const Cancel_Booking_Alert_TXT = []
Cancel_Booking_Alert_TXT["en"] = "When you click cancel booking The system will cancel your reservation automatically."
Cancel_Booking_Alert_TXT["th"] = "เมื่อคุณคลิกยกเลิกการจอง ระบบจะยกเลิกการจองของคุณอัติโนมัติ"

export const Time_TXT = []
Time_TXT["en"] = "Time"
Time_TXT["th"] = "เวลา"

export const Book_Again_TXT = []
Book_Again_TXT["en"] = "Book Again"
Book_Again_TXT["th"] = "จองอีกครั้ง"

export const System_Save_Book_TXT = []
System_Save_Book_TXT["en"] = "The system has saved your reservation."
System_Save_Book_TXT["th"] = "ระบบได้บันทึกการจองของคุณแล้ว"

export const Room_info_TXT = []
Room_info_TXT["en"] = "Room information"
Room_info_TXT["th"] = "ข้อมูลห้อง"

export const Specify_Date_Book_TXT = []
Specify_Date_Book_TXT["en"] = "Specify the date of booking"
Specify_Date_Book_TXT["th"] = "ระบุวันที่จอง"

export const Fix_Specify_Date_Book_TXT = []
Fix_Specify_Date_Book_TXT["en"] = "Please specify the reservation date."
Fix_Specify_Date_Book_TXT["th"] = "กรุณาระบุวันที่จอง"

export const Fix_Select_Date_Book_TXT = []
Fix_Select_Date_Book_TXT["en"] = "Please select a reservation date."
Fix_Select_Date_Book_TXT["th"] = "กรุณาเลือกวันที่จอง"

export const Fix_Specify_Time_Book_TXT = []
Fix_Specify_Time_Book_TXT["en"] = "Please specify the time of booking."
Fix_Specify_Time_Book_TXT["th"] = "กรุณาระบุเวลาจอง"

export const Fix_Select_Book_Time_TXT = []
Fix_Select_Book_Time_TXT["en"] = "Please specify the time of booking."
Fix_Select_Book_Time_TXT["th"] = "กรุณาเลือกช่วงเวลาจอง"

export const Select_Book_Time_TXT = []
Select_Book_Time_TXT["en"] = "Choose a booking period"
Select_Book_Time_TXT["th"] = "เลือกช่วงเวลาจอง"


export const Accessories_TXT = []
Accessories_TXT["en"] = "Accessories"
Accessories_TXT["th"] = "อุปกรณ์เสริม"

export const No_Accessories_TXT = []
No_Accessories_TXT["en"] = "No accessories"
No_Accessories_TXT["th"] = "ไม่มีอุปกรณ์เสริม"

export const Please_Confirm_Before_TXT = []
Please_Confirm_Before_TXT["en"] = "Please confirm 30 minutes before entering."
Please_Confirm_Before_TXT["th"] = "กรุณายืนยันก่อนเข้าใช้งาน 30 นาที"

export const Cancel_Book_Auto_TXT = []
Cancel_Book_Auto_TXT["en"] = "In case of not confirming before 30 minutes of use, the system will cancel the reservation automatically immediately."
Cancel_Book_Auto_TXT["th"] = "กรณีไม่ยืนยันก่อนเข้าใช้งาน 30 นาที ระบบจะทำการยกเลิกการจองอัติโนมัติทันที"

export const Opening_TXT = []
Opening_TXT["en"] = "opening day"
Opening_TXT["th"] = "วันเปิดทำการ"
export const Opening_Hours_TXT = []
Opening_Hours_TXT["en"] = "opening hours"
Opening_Hours_TXT["th"] = "เวลาเปิดทำการ"