import axios from 'axios'


export const getLoanListAll = async (data) => {
    try {

        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/loan/all`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_number": data.unit_number
            }
        })

        return result

    } catch (error) {
        return error
    }

}


export const getLoanById = async (data) => {
    try {

        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/loan/detail`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "loan_id": data.loan_id,
            }
        })

        return result

    } catch (error) {
        return error
    }

}

export const getLoanListAllMock = async (data) => {
    try {

        let result = await axios.get(`http://localhost:3004/loanAll`)
        return result

    } catch (error) {
        return error
    }

}

export const getLoanCustomerChoose = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/loan/loan_customer_choose`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_id": data.unit_id
            }
        })
        return result
    } catch (error) {
        return error
    }
}