import {
    Stack,
    Container,
    styled,
    Box,
    Typography,
    Card,
    Divider,
    Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { FileDownload } from '@mui/icons-material';
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import {
    ModalError,
    BackDropLoading,
    BlankPage
} from '../../ui-component';
import * as dict from '../../dictionary/Transfer'
import * as api from '../../api/Transfer'
export const TransferExpense = () => {
    const theme = useTheme()
    const [data, setData] = useState(null)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [loading, setLoading] = useState(false)
    const context = useContext(ProfileContext)
    const formatCurrency = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");


    useEffect(() => {
        if (profile !== null) {
            //do something
            const loadDetail = async () => {
                setLoading(true)
                let res = await api.getExpenseTransfer({
                    "line_id": profile?.line_id,
                    "product_type": houseInfo?.product_type,
                    "unit_id": houseInfo?.unit_id,
                    "contract_id": houseInfo?.contract_id
                })
                if (res?.status === 200) {
                    setData(res.data?.data)
                    setLoading(false)
                    return
                }
                setLoading(false)
                setModalError({ errorResponse: res, open: true })
                return
            }
            loadDetail()
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    <Stack mt={1} spacing={2}>
                        <Typography variant='h6' fontWeight={"bold"}>{dict.Transfer_Title_Detail_TXT[profile?.language]}</Typography>
                        
                        { data ? <CardNormal >
                            <Stack spacing={1} sx={{ pl: 3, pr: 3, pt: 2, pb: 2 }}>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant='caption' sx={{ flexShrink: 0 }}>{dict.Transfer_ContractNo_TXT[profile?.language]}</Typography>
                                    <Typography variant='caption' sx={{ wordBreak: "break-word" , textAlign:"end"}}>{data?.contract_no}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant='caption' >{dict.Transfer_PricePer_TXT[profile?.language]}</Typography>
                                    <Typography variant='caption' sx={{ wordBreak: "break-word" }}>{formatCurrency(data?.price_per_area)}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant='caption' >{dict.Transfer_NetPrice_TXT[profile?.language]}</Typography>
                                    <Typography variant='caption' sx={{ wordBreak: "break-word" }}>{formatCurrency(data?.total_selling_price)}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant='caption'>{dict.Transfer_Date_TXT[profile?.language]}</Typography>
                                    <Typography variant='caption'>{data?.transfer_date}</Typography>
                                </Stack>
                            </Stack>
                            <Divider />
                            <Stack direction={"column"} spacing={1} sx={{ p: 2 }} mt={1} >
                                <ButtonDownload variant="outlined" startIcon={<FileDownload />} onClick={() => window.open(data?.document[0].url_th)}><Typography variant='body2'>{dict.Transfer_Pdf_TH_TXT[profile?.language]}</Typography></ButtonDownload>
                                <ButtonDownload variant="outlined" sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.main
                                    }
                                }} startIcon={<FileDownload />} onClick={() => window.open(data?.document[0].url_en)}>
                                    <Typography variant='body2'>{dict.Transfer_Pdf_EN_TXT[profile?.language]}</Typography>
                                </ButtonDownload>
                            </Stack>
                        </CardNormal> : <BlankPage  lang={profile?.language} />
                        }
                    </Stack>
                </Container>
            </Box >
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={loading}
            />

        </>
    )

}

const CardNormal = styled(Card)(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0px 0px 7px 0px #00000040",
}))

const ButtonDownload = styled(Button)(({ theme }) => ({
    width: "100%",
    height: "36px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
}))
