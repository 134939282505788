

export const Wait_Pay_TXT = []
Wait_Pay_TXT["en"] = "Waiting For Payment"
Wait_Pay_TXT["th"] = "รอการชำระเงิน"


export const Room_TXT = []
Room_TXT["en"] = "Room"
Room_TXT["th"] = "ห้อง"

export const General_Expense_TXT = []
General_Expense_TXT["en"] = "General Expenses"
General_Expense_TXT["th"] = "ค่าใช้จ่ายทั่วไป"

export const Pay_Within_TXT = []
Pay_Within_TXT["en"] = "Pay Within"
Pay_Within_TXT["th"] = "ชำระเงินภายใน"

export const Baht_TXT = []
Baht_TXT["en"] = "Baht"
Baht_TXT["th"] = "บาท"

export const Pending_Pay_TXT = []
Pending_Pay_TXT["en"] = "Pending Payment"
Pending_Pay_TXT["th"] = "ค่างวดรอชำระ"

export const Choose_Method_TXT = []
Choose_Method_TXT["en"] = "Choose a payment method"
Choose_Method_TXT["th"] = "เลือกวิธีการชำระเงิน"

export const Pay_via_Qr_TXT = []
Pay_via_Qr_TXT["en"] = "Pay via QR Code"
Pay_via_Qr_TXT["th"] = "ชำระผ่าน QR Code"

export const Pay_via_Card_TXT = []
Pay_via_Card_TXT["en"] = "Pay via Credit Card"
Pay_via_Card_TXT["th"] = "ชำระผ่าน Credit Card"

export const Pay_via_MobileBank_TXT = []
Pay_via_MobileBank_TXT["en"] = "Pay via Mobile Banking"
Pay_via_MobileBank_TXT["th"] = "ชำระผ่าน Mobile Banking"

export const Pay_Due_TXT = []
Pay_Due_TXT["en"] = "Payment Due"
Pay_Due_TXT["th"] = "กำหนดชำระ"

export const Pay_Date_TXT = []
Pay_Date_TXT["en"] = "Payment Date"
Pay_Date_TXT["th"] = "วันที่ชำระ"

export const Bill_Type_TXT = []
Bill_Type_TXT["en"] = "Type"
Bill_Type_TXT["th"] = "ประเภท"

export const Pay_Via_TXT = []
Pay_Via_TXT["en"] = "Pay Via"
Pay_Via_TXT["th"] = "ชำระโดย"

export const Bill_Amount_TXT = []
Bill_Amount_TXT["en"] = "Amount"
Bill_Amount_TXT["th"] = "จำนวนเงิน"

export const Bill_Status_TXT = []
Bill_Status_TXT["en"] = "Status"
Bill_Status_TXT["th"] = "สถานะ"

export const Over_Payment_Due_TXT = []
Over_Payment_Due_TXT["en"] = "Overdue"
Over_Payment_Due_TXT["th"] = "เกินกำหนดชำระ"

export const Wait_Payment_TXT = []
Wait_Payment_TXT["en"] = "Pending"
Wait_Payment_TXT["th"] = "รอดำเนินการ"

export const Receipt_Payment_TXT = []
Receipt_Payment_TXT["en"] = "Receipt"
Receipt_Payment_TXT["th"] = "ใบเสร็จรับเงิน"