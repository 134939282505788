export const Amount_Paid_TXT = []
Amount_Paid_TXT["en"] = "Amount Paid"
Amount_Paid_TXT["th"] = "จำนวนที่จ่าย"

export const Scan_To_Paid_TXT = []
Scan_To_Paid_TXT["en"] = "Scan QR code/Barcode to transfer payment"
Scan_To_Paid_TXT["th"] = "สแกนคิวอาร์โค้ด/บาร์โค้ด เพื่อโอนชำระเงิน"

export const Ref_Number_TXT = []
Ref_Number_TXT["en"] = "Reference Number"
Ref_Number_TXT["th"] = "เลขที่อ้างอิง"

export const Remaining_TXT = []
Remaining_TXT["en"] = "Remaining Time"
Remaining_TXT["th"] = "เวลาที่เหลือ"

export const Minutes_TXT = []
Minutes_TXT["en"] = "Minute"
Minutes_TXT["th"] = "นาที"

export const Second_TXT = []
Second_TXT["en"] = "Second"
Second_TXT["th"] = "วินาที"

export const Transaction_Number_TXT = []
Transaction_Number_TXT["en"] = "Transaction Number"
Transaction_Number_TXT["th"] = "เลขที่ทำรายการ"

export const AfterPaymentMessage_TXT = []
AfterPaymentMessage_TXT["en"] = "After payment is completed, the system will notify the payment status to your Line Message"
AfterPaymentMessage_TXT["th"] = "หลังจากชำระเงินเสร็จสิ้นระบบจะแจ้งสถานะการชำระเงินไปยัง บัญชี Line ของท่าน"

export const AfterPaymentBtn_TXT = []
AfterPaymentBtn_TXT["en"] = "Return to the Line Application."
AfterPaymentBtn_TXT["th"] = "กลับไปยังหน้าเมนู Line"