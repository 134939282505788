import {
    Stack,
    Container,
    Box,
} from '@mui/material'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import * as api from '../../api/Transfer'
import { ModalError, BackDropLoading, CardMenu, BlankPage } from '../../ui-component';
export const TransferInfo = () => {

    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [listInfo, setListInfo] = useState([])
    const context = useContext(ProfileContext)

    useEffect(() => {
        if (profile !== null) {
            const loadInfo = async () => {
                setLoading(true)
                let res = await api.getListTransferInfo({
                    "line_id": profile?.line_id,
                    "product_type": houseInfo?.product_type,
                    "project_id": houseInfo?.project_id,
                })
                if (res?.status === 200) {
                    setListInfo(res.data?.data?.post)
                    setLoading(false)
                    return
                }
                setLoading(false)
                setModalError({ errorResponse: res, open: true })
                return
            }
            loadInfo()
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    <Stack mt={1} spacing={2}>
                        {
                            listInfo?.length > 0 ? listInfo?.map((element,i) => <CardMenu 
                                iconImg={"pdfIcon"}
                                key={i} 
                                text={element[`title_${profile?.language}`]} 
                                navigate={"process"} 
                                state={{ title: element[`title_${profile?.language}`], id: element.id}}/>) : <BlankPage lang={profile?.language} />
                        }
                    </Stack>
                </Container>
            </Box >
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={loading}
            />

        </>
    )

}