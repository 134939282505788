import React from "react";
import { Stack, Box, Dialog } from "@mui/material";
import "./Loading.css";

const Loading = () => {
  return (
    <Dialog fullScreen open={true}>
      <Box
        sx={{ display: "flex", justifyContent: "center", minHeight: "100vh" }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <span className="loader"></span>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default Loading;
