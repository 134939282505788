import React, { useState, useEffect } from 'react'
import {
    Box, Divider
} from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DownloadIcon from '@mui/icons-material/Download';
import { BlankPage } from '../../../../ui-component/BlankPage'
import { useLocation } from 'react-router-dom';
import 'react-medium-image-zoom/dist/styles.css'


export const AdvertiseDialog = (props) => {

    const [brochure, setBrochure] = useState([])
    const location = useLocation()
    useEffect(() => {
        if (location.state?.brochure !== null) {
            setBrochure(location.state?.brochure)
        }
    }, [location.state])

    return (
        <>
            <Box sx={{ marginTop: 8 }}>

                <List>
                    <ListItem disablePadding>
                        {
                            brochure?.length > 0 && brochure?.map(element => <>
                                <ListItemButton onClick={() => window.open(`${element.file_url}?openExternalBrowser=1`)} >
                                    <ListItemText primary={element.file_name?.length > 30 ? element.file_name.substring(0, 30) + "..." : element.file_name} />
                                    <DownloadIcon />
                                </ListItemButton>
                                < Divider />
                            </>)
                        }

                    </ListItem>
                </List>

            </Box>
            {
                brochure?.length === 0 && <BlankPage lang={location.state.lang} />
            }
        </>


    )

}
