
export const SearchDefect_TXT = []
SearchDefect_TXT["en"] = "Search"
SearchDefect_TXT["th"] = "ค้นหาการแจ้งซ่อม"

export const Status_TXT = []
Status_TXT["en"] = "Status"
Status_TXT["th"] = "สถานะ"

export const Date_Create_Defect_TXT = []
Date_Create_Defect_TXT["en"] = "Date Report"
Date_Create_Defect_TXT["th"] = "วันที่แจ้ง"

export const Date_Report_TXT = []
Date_Report_TXT["en"] = "Date Report"
Date_Report_TXT["th"] = "แจ้งซ่อมเมื่อ"

export const More_TXT = []
More_TXT["en"] = "See More"
More_TXT["th"] = "ดูเพิ่มเติม"

export const Project_TXT = []
Project_TXT["en"] = "Project"
Project_TXT["th"] = "โครงการ"

export const Waiting_TXT = []
Waiting_TXT["en"] = "Waiting"
Waiting_TXT["th"] = "รอดำเนินการ"

export const Detail_TXT = []
Detail_TXT["en"] = "Detail"
Detail_TXT["th"] = "รายละเอียด"

export const Picture_TXT = []
Picture_TXT["en"] = "Picture"
Picture_TXT["th"] = "รูปภาพแจ้งซ่อม"

export const ConfirmTitle_TXT = []
ConfirmTitle_TXT["en"] = "Are you sure ?"
ConfirmTitle_TXT["th"] = "คุณแน่ใจหรือไม่ ?"

export const ConfirmContent_TXT = []
ConfirmContent_TXT["en"] = "Confirm button will cancel the repair order."
ConfirmContent_TXT["th"] = `เมื่อคลิกปุ่ม "ยืนยัน" ระบบจะทำการยกเลิกการแจ้งซ่อมให้คุณ`

export const ConfirmAction_TXT = []
ConfirmAction_TXT["en"] = "Confirm"
ConfirmAction_TXT["th"] = `ยืนยัน`

export const CancelAction_TXT = []
CancelAction_TXT["en"] = "Cancel"
CancelAction_TXT["th"] = `ยกเลิก`

export const Reporter_TXT = []
Reporter_TXT["en"] = "Report By"
Reporter_TXT["th"] = `ผู้แจ้งซ่อม`

export const Subject_TXT = []
Subject_TXT["en"] = "Subject"
Subject_TXT["th"] = `หัวข้อเรื่อง`

export const SubjectPlace_TXT = []
SubjectPlace_TXT["en"] = "Please specify the title of this repair notice."
SubjectPlace_TXT["th"] = `กรุณาระบุชื่อเรื่องแจ้งซ่อมในครั้งนี้`

export const RepairType_TXT = []
RepairType_TXT["en"] = "Repair Report Type"
RepairType_TXT["th"] = `ประเภทการแจ้งซ่อม`

export const RepairTypePlace_TXT = []
RepairTypePlace_TXT["en"] = "Please specify the type of this repair notification."
RepairTypePlace_TXT["th"] = `กรุณาระบุประเภทการแจ้งซ่อมในครั้งนี้`

export const DetailPlace_TXT = []
DetailPlace_TXT["en"] = "Please provide details."
DetailPlace_TXT["th"] = `กรุณาระบุรายละเอียดของปัญหา`

export const Upload_TXT = []
Upload_TXT["en"] = "Upload Pictures (up to 5 photos)."
Upload_TXT["th"] = `อัพโหลดรูป (สูงสุด 5 รูป)`

export const UploadLimit_TXT = []
UploadLimit_TXT["en"] = "The full number of photos has been uploaded."
UploadLimit_TXT["th"] = `อัพโหลดรูปภาพเต็มตามจำนวนที่กำหนดแล้ว`

export const PleaseUpload_TXT = []
PleaseUpload_TXT["en"] = "Please Upload at least one image."
PleaseUpload_TXT["th"] = `โปรดอัพโหลดรูปอย่างน้อย 1 รูปภาพ`

export const Save_TXT = []
Save_TXT["en"] = "Save"
Save_TXT["th"] = `บันทึก`

export const Delete_TXT = []
Delete_TXT["en"] = "Delete"
Delete_TXT["th"] = `ลบ`

export const Defect_Type_TXT = []
Defect_Type_TXT["en"] = "Select repair type"
Defect_Type_TXT["th"] = `เลือกประเภทการแจ้งซ่อม`

export const See_Doc_Defect_TXT = []
See_Doc_Defect_TXT["en"] = "View document"
See_Doc_Defect_TXT["th"] = `ดูเอกสาร`

export const Review_Defect_TXT = []
Review_Defect_TXT["en"] = "Review"
Review_Defect_TXT["th"] = "ทำแบบประเมิน"

export const Capture_Defect_TXT = []
Capture_Defect_TXT["en"] = "Take a Photo"
Capture_Defect_TXT["th"] = "ถ่ายรูปแจ้งซ่อม"

export const CANNOT_REPORT_DEFECT_TXT = []
CANNOT_REPORT_DEFECT_TXT["en"] = "Unable to report repairs"
CANNOT_REPORT_DEFECT_TXT["th"] = "ไม่สามารถแจ้งซ่อมได้"

export const Warranty_Expired_TXT = []
Warranty_Expired_TXT["en"] = "Sorry, unable to report repairs. Because it is not covered by the warranty."
Warranty_Expired_TXT["th"] = "ขออภัยไม่สามารถแจ้งซ่อมได้ เนื่องจากไม่อยู่ในการรับประกัน"

export const UNIT_NOT_TRANSFER_TXT = []
UNIT_NOT_TRANSFER_TXT["en"] = "Because the customer has not transferred yet Therefore cannot report repairs to this room at this time."
UNIT_NOT_TRANSFER_TXT["th"] = "เนื่องจากลูกค้ายังไม่ได้โอน จึงยังไม่สามารถแจ้งซ่อมห้องนี้ได้ในขณะนี้"

export const ADD_PHOTO_TXT = []
ADD_PHOTO_TXT["en"] = "Add a photo"
ADD_PHOTO_TXT["th"] = "เพิ่มรูปภาพ"

export const UPLOAD_PHOTO_YET_TXT = []
UPLOAD_PHOTO_YET_TXT["en"] = "You haven't uploaded a picture yet."
UPLOAD_PHOTO_YET_TXT["th"] = "ท่านยังไม่ได้อัพโหลดรูป"

export const UPLOAD_MINIMUM_TXT = []
UPLOAD_MINIMUM_TXT["en"] = "Please upload at least 1 photo."
UPLOAD_MINIMUM_TXT["th"] = "โปรดอัพโหลดรูปภาพอย่างน้อย 1 รูป"

export const WRONG_SAVE_DEFECT_TXT = []
WRONG_SAVE_DEFECT_TXT["en"] = "An error occurred. We are unable to save your request at this time."
WRONG_SAVE_DEFECT_TXT["th"] = "เกิดข้อผิดพลาดเราไม่สามารถบันทึกคำร้องของคุณได้ในขณะนี้"

export const PASS_SAVE_DEFECT_TXT = []
PASS_SAVE_DEFECT_TXT["en"] = "The system has recorded your request into the repair notification system."
PASS_SAVE_DEFECT_TXT["th"] = "ระบบได้บันทึกคำร้องของคุณเข้าระบบแจ้งซ่อมแล้ว"

export const METHOD_CHOOSE_PICTURE_TXT = []
METHOD_CHOOSE_PICTURE_TXT["en"] = "Gallery"
METHOD_CHOOSE_PICTURE_TXT["th"] = "เลือกรูปภาพ"

export const METHOD_OPEN_CAMERA_TXT = []
METHOD_OPEN_CAMERA_TXT["en"] = "Camera"
METHOD_OPEN_CAMERA_TXT["th"] = "เปิดกล้อง"

export const CROP_TXT = []
CROP_TXT["en"] = "Crop Image"
CROP_TXT["th"] = "ครอบตัด"

export const Saving_Data_Defect_TXT = []
Saving_Data_Defect_TXT["en"] = "Saving data"
Saving_Data_Defect_TXT["th"] = "กำลังบันทึกข้อมูล"