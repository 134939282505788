import React, { useEffect, useState, useContext } from 'react'
import {
    Box, Button, Paper, Alert,
    Typography, InputAdornment, Container, Stack, TextField,
    Radio, Divider
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="฿"
        />
    );
});

// NumberFormatCustom.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
// };


export const AmountPayment = (props) => {
    const [amount, setAmount] = useState()
    const [detail, setDetail] = useState(null)
    const [alertRequired, setAlertRequired] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(ProfileContext)
    const [profile, setProfile] = useState(null)
    const [radioSelected, setRadioSelected] = useState(null)
    const [isFocused, setIsFocused] = useState(false);
    const formatCurrency = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const minimumAmount = parseInt(detail?.balance * 8 / 100)
    const handleOnFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    useEffect(() => {
        if (location.state) {
            setDetail(location.state)
        }
    }, [location.state])

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container maxWidth="sm" sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5}}>
                    <Paper sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", }} >
                        <Box>
                            <Stack direction={"row"} justifyContent="center">
                                <Box sx={{ width: "100%" }}>

                                    <Stack spacing={1} padding={3}>
                                        <Stack direction={"row"} justifyContent="center">

                                            <Typography fontWeight={"bold"} color="#D34E4E">{dict.Slip_TXT[profile?.language]}</Typography>
                                        </Stack>

                                        <Stack direction={"row"} justifyContent="space-between">
                                            <Typography variant="subtitle2" color="#000000"> {dict.Project_TXT[profile?.language]} </Typography>
                                            <Typography variant="subtitle2" color="#000000" fontWeight={"bold"}> {detail?.project_name} </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent="space-between">
                                            <Typography variant="subtitle2" color="#000000"> {dict.Unit_TXT[profile?.language]}</Typography>
                                            <Typography variant="subtitle2" color="#000000" fontWeight={"bold"}> {detail?.unit_number} </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent="space-between">
                                            <Typography variant="subtitle2" color="#000000"> {dict.PayName_TXT[profile?.language]}</Typography>
                                            <Typography variant="subtitle2" color="#000000" fontWeight={"bold"}> {detail?.customer_name} </Typography>
                                        </Stack>

                                    </Stack>
                                    <Divider />
                                    <Stack direction={"row"} justifyContent="space-between" padding={3}>
                                        <Typography variant="h6" color="#D34E4E" > {dict.Total_TXT[profile?.language]} </Typography>
                                        <Typography variant="h6" color="#D34E4E" fontWeight={"bold"}> {(Number(detail?.balance)).toLocaleString()} ฿</Typography>
                                    </Stack>
                                </Box>

                            </Stack>
                        </Box>
                    </Paper>

                    <Box sx={{ marginTop: 3 }}>
                        <Stack direction="row" spacing={1} mr={2} alignItems={"center"}>
                            <Radio
                                checked={radioSelected === 'fullAmount'}
                                onChange={(e) => setRadioSelected(e.target.value)}
                                value="fullAmount"
                                name="radio-fullAmount"
                            />
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                                <Typography>{dict.Full_Amount_TXT[profile?.language]}:</Typography>
                                <Typography fontWeight={"bold"}>{formatCurrency(detail?.balance)} {dict.Baht_TXT[profile?.language]}</Typography>
                            </Stack>

                        </Stack>
                        <Stack direction="row" spacing={1} mt={2} mr={2} alignItems={"center"}>
                            <Radio
                                checked={radioSelected === 'minimumAmount'}
                                onChange={(e) => setRadioSelected(e.target.value)}
                                value="minimumAmount"
                                name="radio-minimumAmount"
                            />
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                                <Typography>{dict.Minimum_Amount_TXT[profile?.language]}: </Typography>
                                <Typography fontWeight={"bold"}>{formatCurrency(minimumAmount)} {dict.Baht_TXT[profile?.language]}</Typography>
                            </Stack>

                        </Stack>
                        <Stack direction="row" spacing={1} mt={2}>
                            <Radio
                                checked={radioSelected === 'specificAmount'}
                                onChange={(e) => setRadioSelected(e.target.value)}
                                value="specificAmount"
                                name="radio-specificAmount"
                            />
                            <TextField
                                sx={{ ml: 3 }}
                                label={dict.Specify_Amount_TXT[profile?.language]}
                                value={amount}
                                onClick={() => setRadioSelected("specificAmount")}
                                onChange={(e) => setAmount(e.target.value)}
                                onFocus={handleOnFocus}
                                onBlur={handleBlur}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                endadornment={<InputAdornment position="start">฿</InputAdornment>}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: {
                                        borderRadius: "10px"
                                    }
                                }}
                                variant="outlined"
                            />
                        </Stack>
                    </Box>

                    {alertRequired === true && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Please_Specify_Amount_TXT[profile?.language]}</Alert></Box>}

                </Container>

                {
                    !isFocused && <Button variant={"contained"} sx={{ position: "fixed", bottom: 5, width: "100dvw", height: "8%" }} onClick={() => {

                        let amountConfirm = 0

                        switch (radioSelected) {
                            case "minimumAmount": amountConfirm = minimumAmount
                                break;
                            case "fullAmount": amountConfirm = detail?.balance
                                break;
                            default: amountConfirm = amount
                                break;
                        }

                        if (amountConfirm === 0 || amountConfirm === null || radioSelected === null) {
                            return setAlertRequired(true)
                        }

                        navigate('/billpayment/paid' + location.search, {
                            state: { ...detail, amount: amountConfirm }
                        })
                        // window.open(`/billpayment/paid${location.search}&amount=${amount}&line_id=${detail.line_id}` +
                        //     `&contract_id=${detail.contract_id}&load_type=${detail.load_type}`
                        //     + `&language=${detail.language}&term_id=${detail.term_id}&reference_id=${detail.reference_id}`
                        //     + `&reference_type=${detail.reference_type}&product_type=${detail.product_type}&payment_id=${detail.payment_id}`
                        //     + `&project_name=${detail.project_name}&method_type=${detail.methodType}&choice_selected=${detail.choiceSelected}`
                        // );
                        return setAlertRequired(false)
                    }
                    }>{dict.AcceptBtn_TXT[profile?.language]}</Button>
                }
                </Box>
            )
}
