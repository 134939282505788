
import { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { ProfileContext } from '../../contexts/ProfileContext';
import { BlankPage, DetailCP } from '../../ui-component';
import {
    ModalError,
    BackDropLoading
} from '../../ui-component';
import * as api from '../../api/Manual'
export const ManualDetail = () => {

    const [data, setData] = useState(null)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [loading, setLoading] = useState(false)
    const context = useContext(ProfileContext)
    const location = useLocation()


    useEffect(() => {
        if (profile !== null) {
            const loadDetail = async () => {
                setLoading(true)
                let res = await api.getManualDetail({
                    "line_id": profile?.line_id,
                    "product_type": houseInfo?.product_type,
                    "id": location.state?.id
                })
                if (res?.status === 200) {
                    setData(res.data?.data)
                    setLoading(false)
                    return
                }
                setLoading(false)
                setModalError({ errorResponse: res, open: true })
                return
            }
            loadDetail()
        }
    }, [profile, houseInfo, location.state?.id]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    return (
        <>
            { data ? <DetailCP data={data} /> : <BlankPage lang={profile?.language} /> }
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={loading}
            />

        </>
    )

}