
export const AcceptBtn_TXT = []
AcceptBtn_TXT["en"] = "Ok"
AcceptBtn_TXT["th"] = "ยืนยัน"

export const AcceptBtn2_TXT = []
AcceptBtn2_TXT["en"] = "Ok"
AcceptBtn2_TXT["th"] = "ตกลง"

export const CancelBtn_TXT = []
CancelBtn_TXT["en"] = "Cancel"
CancelBtn_TXT["th"] = "ยกเลิก"

export const TitleDialog_TXT = []
TitleDialog_TXT["en"] = "Leave editing ?"
TitleDialog_TXT["th"] = "ออกจากหน้านี้ ?"

export const Description_TXT = []
Description_TXT["en"] = "Changes you made so far will not be saved"
Description_TXT["th"] = "ระบบจะไม่บันทึกข้อมูลของคุณ โปรดตรวจสอบให้แน่ใจ"