export const Equipment_Delivery_Report_TXT = []
Equipment_Delivery_Report_TXT["en"] = "Equipment delivery report"
Equipment_Delivery_Report_TXT["th"] = "รายงานส่งมอบอุปกรณ์"

export const Equipment_Review_TXT = []
Equipment_Review_TXT["en"] = "Satisfaction rating"
Equipment_Review_TXT["th"] = "ประเมินความพึงพอใจ"

export const Equipment_Review_SubTitle_TXT = []
Equipment_Review_SubTitle_TXT["en"] = "after delivery of equipment"
Equipment_Review_SubTitle_TXT["th"] = "หลังส่งมอบอุปกรณ์"