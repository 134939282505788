import axios from "axios";
export const controllerTab1 = new AbortController();
export const controllerTab2 = new AbortController();
export const getBillPayment = async (data) => {

    try {

        let res = await axios({
            method: 'post',
            signal: controllerTab1.signal,
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/billpayment`,
            data: {
                "line_id": data.line_id,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "is_rem": data.is_rem,
                "is_pm": data.is_pm,
                "is_rental": data.is_rental
            }
        })
        return res

    } catch (error) {
        return error
    }


}

export const getBillPaymentHistory = async (data) => {
    try {

        let res = await axios({
            method: 'post',
            signal: controllerTab2.signal,
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/billpayment/history`,
            data: {
                "line_id": data.line_id,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "is_rem": data.is_rem,
                "is_pm": data.is_pm,
                "is_rental": data.is_rental
            }
        })
        return res

    } catch (error) {
        return error
    }
}