import React from 'react'
import { Card, CardContent, CardMedia, Stack } from '@mui/material';
import { Delete } from '@mui/icons-material/'
export const CardWithComment = (props) => {


    const { image, deleteIndex, id, cardWidth , cardHeight, imgWidth} = props

    return (
        <>

            <Card
                variant="outlined"
                sx={{ minWidth: cardWidth || 275 , width: "100%" }}
            >
                <CardContent>

                    <Stack direction={"row"} justifyContent={"flex-end"}> <Delete onClick={() => deleteIndex(id)} /> </Stack>
                    <CardMedia
                        component="img"
                        width={imgWidth || "100%"}
                        height={cardHeight || 150}
                        sx={{ objectFit: "contain" }}
                        src={image}
                    />

                </CardContent>
            
            </Card>
            
        </>
    )
}