
export const Inspect_Tab_Overview_TXT = []
Inspect_Tab_Overview_TXT["en"] = "Overview"
Inspect_Tab_Overview_TXT["th"] = "ภาพรวม"

export const Inspect_Tab_List_TXT = []
Inspect_Tab_List_TXT["en"] = "Defect List"
Inspect_Tab_List_TXT["th"] = "รายการตรวจ"

export const Inspect_Number_TXT = []
Inspect_Number_TXT["en"] = "Inspect Count"
Inspect_Number_TXT["th"] = "จำนวนครั้งที่ตรวจ"

export const Check_Self_TXT = []
Check_Self_TXT["en"] = "By Self"
Check_Self_TXT["th"] = "ตรวจเอง"

export const Hire_Check_TXT = []
Hire_Check_TXT["en"] = "By Inspector"
Hire_Check_TXT["th"] = "จ้างตรวจ"

export const Defect_Status_TXT = []
Defect_Status_TXT["en"] = "Defect Status"
Defect_Status_TXT["th"] = "สถานะ Defect"

export const Status_New_TXT = []
Status_New_TXT["en"] = "New"
Status_New_TXT["th"] = "เพิ่มใหม่"

export const Status_Processing_TXT = []
Status_Processing_TXT["en"] = "Processing"
Status_Processing_TXT["th"] = "กำลังดำเนินการ"

export const Status_Fixed_TXT = []
Status_Fixed_TXT["en"] = "Fixed"
Status_Fixed_TXT["th"] = "แก้แล้ว"

export const Status_Closed_TXT = []
Status_Closed_TXT["en"] = "Closed"
Status_Closed_TXT["th"] = "ปิดเคส"

export const Status_None_TXT = []
Status_None_TXT["en"] = "Unknown"
Status_None_TXT["th"] = "ไม่ได้ระบุ"

export const Status_Minor_TXT = []
Status_Minor_TXT["en"] = "Minor"
Status_Minor_TXT["th"] = "เล็กน้อย"

export const Status_Major_TXT = []
Status_Major_TXT["en"] = "Major"
Status_Major_TXT["th"] = "สำคัญ"

export const Status_Critical_TXT = []
Status_Critical_TXT["en"] = "Critical"
Status_Critical_TXT["th"] = "วิกฤต"

export const Defect_Date_TXT = []
Defect_Date_TXT["en"] = "Date"
Defect_Date_TXT["th"] = "วันที่"

export const Defect_Edit_Date_TXT = []
Defect_Edit_Date_TXT["en"] = "Edit date"
Defect_Edit_Date_TXT["th"] = "วันที่แก้ไข"

export const Defect_Authorities_TXT = []
Defect_Authorities_TXT["en"] = "Authorities"
Defect_Authorities_TXT["th"] = "เจ้าหน้าที่"

export const Defect_Call_Center_TXT = []
Defect_Call_Center_TXT["en"] = "Call Center"
Defect_Call_Center_TXT["th"] = "เบอร์โทรเจ้าหน้าที่"

export const Defect_Level_TXT = []
Defect_Level_TXT["en"] = "Defect Severity"
Defect_Level_TXT["th"] = "ระดับความรุนแรงของ Defect"

export const Defect_Menu_Status_TXT = []
Defect_Menu_Status_TXT["en"] = "Home Inspection Status"
Defect_Menu_Status_TXT["th"] = "สถานะตรวจบ้าน"
