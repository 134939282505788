import {
    Stack,
    Typography,
    Box,
} from '@mui/material'
import * as api from '../../api/Regulation'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext'
import { ZoomCarousel } from '../../ui-component';
import { useLocation } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BackDropLoading } from '../../ui-component';
import * as dict from '../../dictionary'
export const RegulationDetail = () => {

    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [detail, setDetail] = useState(null)
    const [openCarousel, setOpenCarousel] = useState(false)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)

    const context = useContext(ProfileContext)

    const location = useLocation()

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };


    useEffect(() => {
        const loadDetail = async (id) => {
            setLoadingBackDrop(true)
            let res = await api.getRegulationDetail({
                "line_id": profile?.line_id,
                "product_type": houseInfo?.product_type,
                "id": id
            })
    
            setDetail(res.data?.data)
            setLoadingBackDrop(false)
        }
        if (location.state?.idSelected !== null) {
            loadDetail(location.state?.idSelected)
        }
    }, [location.state, profile, houseInfo]);

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }

    }, [context]);

    return (
        <>
            <BackDropLoading
                loading={loadingBackDrop}
            />
            <Box sx={{ marginTop: 8 }}>
                <Carousel responsive={responsive}>
                    {
                        detail ? detail?.["gallery"].map(element => <div style={{ height: "200px" }} onClick={() => setOpenCarousel(true)}>
                            <img src={element["image1024"] } alt="©customer portal v2" style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                        </div>) : <div />
                    }
                </Carousel>
            </Box>
            <Box sx={{ backgroundColor: "white", padding: 3 }}>
                <Stack direction={"row"} alignItems={"flex-end"} justifyContent={"flex-end"} spacing={2}>
                    <Typography variant={"caption"}>{detail?.postdate}</Typography>
                    <Typography variant={"caption"}>{dict.Edit_On_TXT[profile?.language]} {detail?.lastupdate}</Typography>
                </Stack>
                <br />
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {detail?.title}
                </Typography>
                {
                    <div dangerouslySetInnerHTML={{ __html: (detail?.description) }} />
                }

            </Box>
            <ZoomCarousel
                open={openCarousel}
                onClose={() => setOpenCarousel(false)}
                gallery={detail?.["gallery"]}
            />

        </>
    )
}
