
import * as dict from "../../dictionary";
import { typeLoan } from './typeLoan';
import { useTheme } from '@mui/material/styles';
import {
    Typography, Grid, Container,Box,Chip,Divider, Stack,
} from '@mui/material'
import { HourglassBottom, PhoneInTalk } from "@mui/icons-material";
export const  LoanDetailComponent = ({ detail, profile }) => {
    const theme = useTheme()
    const formatCurrency = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return <Container>
        <Box sx={{ width: "100%" }} mt={10}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant={"h6"}>
                        {dict?.Loan_Result_TXT[profile?.language]}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Chip sx={{ width: "100%", color: typeLoan[detail?.status]?.color, fontWeight: "bold" }} label={detail?.status_name} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"caption"} color={"CaptionText"}>
                        {detail?.customer_name}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"body2"}>
                        {dict?.Loan_RoomPlan_No_TXT[profile?.language]}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color={"CaptionText"}>
                        {detail?.unit_no}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"body2"}>
                        {dict?.Loan_Contract_No_TXT[profile?.language]}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color={"CaptionText"}>
                        {detail?.booking_no}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2 }} textAlign="left"><Typography variant={"body2"}> {dict?.Loan_Bank_Info_TXT[profile?.language]} </Typography> </Divider>
            <Grid container mt={2} alignItems={"center"}>
                <Grid item xs={6}>
                    <Typography variant={"body2"}>{dict?.Loan_Bank_TXT[profile?.language]}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color="CaptionText">{detail?.bank_name}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant={"body2"}>{dict?.Loan_Contact_TXT[profile?.language]}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color="CaptionText">{detail?.bank_staff_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"body2"}>{dict?.Loan_Phone_TXT[profile?.language]}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color="CaptionText">{detail?.bank_staff_tel}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"body2"}>{dict?.Email_TXT[profile?.language]}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"caption"} color="CaptionText">{detail?.bank_staff_email}</Typography>
                </Grid>
            </Grid>
            {["SA_001", "SPP001", "STA005", "approve"].includes(detail?.status) && <>
                <Divider sx={{ marginTop: 2 }} textAlign="left"><Typography variant={"body2"}> {dict.Loan_Amount_TXT[profile?.language]} </Typography></Divider>
                <Grid container mt={2} direction={"row"} alignItems={"center"} >
                    <Grid item xs={8}>
                        <Typography variant={"caption"}>{dict?.Loan_Amount_Req_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.request_amount) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"}>{dict?.Loan_Approval_Total_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.total_loan) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">{dict?.Loan_Home_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.main_loan) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">{dict?.Loan_Life_Insurance_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.mrta) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText" >{dict?.Loan_Fire_Insurance_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.fire_insurance) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">{dict?.Loan_Multi_Credit_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.multi_purpose_loan) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">{dict?.Loan_Decoration_Cost_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency(detail?.decoration_loan) || "0.00"} {dict?.Baht_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">%{dict?.Loan_Limit_Cost_Percent_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency((detail?.main_loan * 100) / detail?.request_amount) || 0}%</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={"caption"} color="CaptionText">%{dict?.Loan_Approval_Cost_Percent_TXT[profile?.language]}</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant={"caption"} color="CaptionText">{formatCurrency((detail?.total_loan * 100) / detail?.request_amount) || 0}%</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ marginTop: 6 }} textAlign="right"><Typography variant={"body2"}> {dict?.Loan_Expiration_TXT[profile?.language]} </Typography> <Typography variant={"caption"} color={"CaptionText"}>{detail?.expiry_date}</Typography></Divider>
            </>
            }
        </Box>
        {
            detail?.status === "STA001" && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} mt={4} mb={2}>
                <Stack direction="column" alignItems={"center"}>
                    <HourglassBottom sx={{ fontSize: 150, color: theme.palette.primary.main }} />
                    <Typography variant='h6'>{dict?.Loan_Process_TXT[profile?.language]}</Typography>
                </Stack>
            </Box>
        }
        {
            detail?.status === "SC_001" && <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} mt={4} mb={2}>
                <Stack direction="column" alignItems={"center"} sx={{ textAlign: "center" }}>
                    <PhoneInTalk sx={{ fontSize: 150, color: theme.palette.primary.main }} />
                    <Typography variant='h6'>{dict?.Loan_Additional_TXT[profile?.language]}</Typography>
                </Stack>
            </Box>
        }
    </Container>

}