import { useState } from 'react'
import {
    Card, CardContent,
    Collapse, Grid, Typography
} from '@mui/material'
import { ExpandLess, ExpandMore, HomeOutlined } from '@mui/icons-material';

export const CardExpand = (props) => {

    const [isExpand, setIsExpand] = useState(false)

    return (
        <>

            <Card
                variant="outlined"
                sx={{ minWidth: 275, borderRadius: "10px" }}
                onClick={(event) => {
                    setIsExpand(!isExpand)
                }}
            >
                <CardContent>
                    <Grid container
                        alignItems={"center"}
                        direction="row"
                        justifyContent="space-between">
                        <HomeOutlined />
                        <Typography>
                            {props.titleCard}
                        </Typography>

                        <Grid item xs={2}>
                            {
                                isExpand === true ? <ExpandLess /> : <ExpandMore />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Collapse in={isExpand} timeout="auto" unmountOnExit>
                {
                    props.content
                }
            </Collapse>

        </>
    )

}