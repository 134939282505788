import axios from 'axios'

export const getProfile = async (data) => {
    try {

        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/profile`,
            data: {
                "line_id": data.line_id
            }
        })

        return result

    } catch (error) {
        return error
    }

}