import React, { useEffect, useState, useContext, useCallback } from "react";
import * as api from '../../api/Paid'
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Grid,
    Box,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import { ProfileContext } from "../../contexts/ProfileContext";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from "moment";
import * as dict from '../../dictionary'
import { BackDropLoading } from "../../ui-component";
import { ModalError } from "../../ui-component";
import { ScriptKbank } from "../../ui-component/ScriptKbank";
import { LoadingContext } from "../../contexts/LoadingContext";
import liff from '@line/liff';
import { checkProductType } from "../../utils/checkProductType";
export const Paid = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [order, setOrder] = useState(null)
    const [profile, setProfile] = useState(null)
    const [billInfo, setBillInfo] = useState(null)
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const context = useContext(ProfileContext)
    const loadingContext = useContext(LoadingContext)
    const [loading, setLoading] = useState(true)

    const createOrderForKbankQr = async (data) => {
        const res = await api.createOrderKbankQr(data)
        if (res?.status === 200) {
            let orderData = res?.data.data
            setMinutes(10)
            setSeconds(0)
            setOrder(orderData)
            setLoading(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }

    const createOrderForBillPaymentQr = async (data) => {
        const res = await api.createOrderMpayQr(data)
        if (res?.status === 200) {
            let orderData = res?.data.data
            let startTime = moment(orderData?.created_at);
            let endTime = moment(orderData?.qr_expire_at);

            let duration = moment.duration(endTime.diff(startTime))
            setMinutes(parseInt(duration.asMinutes()))
            setSeconds(parseInt(duration.asSeconds() % 60))
            setOrder(orderData)
            setLoading(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }

    const createOrderForMobileBanking = async (data) => {
        const res = await api.createOrderMobileBanking(data)
        if (res?.status === 200) {
            let orderData = res?.data.data
            window.open(orderData?.authorize_url)
            setLoading(false)
            return
        }

        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }

    const createOrderForCreditCard = async (data) => {
        const res = await api.createOrderCreditCard(data)
        if (res?.status === 200) {
            let orderData = res?.data.data
            window.open(orderData?.form_url)
            setLoading(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }

    const checkCompanyId = () => {
        let productType = checkProductType()
        if (productType === "rental") {
            return localStorage.getItem("cid_rental")
        }
        if (productType === "pmlite") {
            return localStorage.getItem("cid_pm")
        }
        return localStorage.getItem("cid")
    }

    const handleRefreshPage = useCallback(() => {
        window.location.reload();
        navigate(location.search, {
            replace: true, state: {
                methodType: "done"
            }
        })
    },[location.search, navigate])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])


    useEffect(() => {
        if (loadingContext?.state?.isLoading === false) {
            const getBill = async () => {

                if (location.state.methodType === "done") {
                    return setLoading(false)
                }

                const data = location?.state
                const billDetail = await api.billPaymentDetail({
                    line_id: data.line_id,
                    contract_id: data.contract_id,
                    load_type: data.load_type,
                    language: data.language,
                    term_id: data.term_id,
                    reference_id: data.reference_id,
                    reference_type: data.reference_type,
                    product_type: data.product_type,
                    payment_id: data.payment_id !== "undefined" ? data.payment_id : ""
                })
                setBillInfo(billDetail.data)
                let paramsBill = {
                    company_id: checkCompanyId(),
                    contract_id: billDetail.data.contract_id,
                    amount: data.amount,
                    project_id: billDetail.data.project_id,
                    project_name: billDetail.data.project_name,
                    project_name_eng: billDetail.data.project_name_eng,
                    unit_id: billDetail.data.unit_id,
                    unit_number: billDetail.data.unit_number,
                    customer_id: billDetail.data.customer_id,
                    customer_name: billDetail.data.customer_name,
                    term_id: checkProductType() === "rental" ? billDetail.data.contract_term_id : billDetail.data.term_id,
                    term_name: billDetail.data.term_name,
                    term_name_eng: billDetail.data.term_name_eng,
                    language: data.language
                };

                if (data.methodType === "qr") {

                    return createOrderForBillPaymentQr(paramsBill)

                }

                if (data.methodType === "qr-kbank") {
                    return createOrderForKbankQr(paramsBill)
                }

                if (data.methodType === "mobile-banking") {
                    paramsBill = {
                        ...paramsBill,
                        bank_name: data.choiceSelected
                    }

                    return createOrderForMobileBanking(paramsBill)
                }

                if (data.methodType === "credit") {
                    paramsBill = {
                        ...paramsBill,
                        credit_card_type: data.choiceSelected
                    }

                    return createOrderForCreditCard(paramsBill)
                }

            }
            getBill()
        }
    }, [location, loadingContext?.state?.isLoading]);

    useEffect(() => {

        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };

    })

    return <>
        {
            (order !== null && location?.state.methodType === "qr") &&
            <>
                <Box sx={{ padding: 2 }}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"h6"}>{billInfo?.term_name} : </Typography>
                        <Typography variant={"h6"}>{(parseInt(billInfo?.balance)).toLocaleString()} ฿</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"h6"}>{dict.Amount_Paid_TXT[profile?.language]} : </Typography>
                        <Typography variant={"h6"}>{(parseInt(location.state?.amount)).toLocaleString()} ฿</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"body1"}>{dict.Pay_Within_TXT[profile?.language]} : </Typography>
                        <Typography variant={"body1"}>{billInfo?.invoice_due_date ? moment(billInfo?.invoice_due_date).format("DD/MM/YYYY") : null}</Typography>
                    </Stack>
                    <Grid container sx={{ marginTop: 2 }}>

                        <Grid item xs={12}>
                            <Typography variant={"body1"}>{dict.Scan_To_Paid_TXT[profile?.language]}</Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Zoom>
                                <div style={{ height: "350px" }}>
                                    <div
                                        role="img"
                                        style={{
                                            background: `url(data:image/jpeg;base64,${order?.qr_img}) no-repeat`,
                                            width: "100%",
                                            height: "100%",
                                            backgroundSize: "contain",
                                            backgroundPosition: "center center"

                                        }} />
                                </div>

                            </Zoom>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ padding: 2 }}>
                    <Stack direction={"row"}>
                        <Box sx={{ wordBreak: "break-all" }}>
                            <Typography variant="caption"> {dict.Transaction_Number_TXT[profile?.language]} : {order?.txn_id}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"}>
                        <Box sx={{ wordWrap: "break-word" }}>
                            <Typography variant="caption"> {dict.Ref_Number_TXT[profile?.language]} : {order?.order_code}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"}>
                        <Typography variant="caption"> {dict.Remaining_TXT[profile?.language]} : {minutes} {dict.Minutes_TXT[profile?.language]} {seconds} {dict.Second_TXT[profile?.language]} </Typography>
                    </Stack>
                </Box>
            </>
        }

        {
            (order !== null && location?.state.methodType === "qr-kbank") &&
            <>
                <Box sx={{ padding: 2 }}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"h6"}>{billInfo?.term_name} : </Typography>
                        <Typography variant={"h6"}>{(parseInt(billInfo?.balance)).toLocaleString()} ฿</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"h6"}>{dict.Amount_Paid_TXT[profile?.language]} : </Typography>
                        <Typography variant={"h6"}>{(parseInt(location.state?.amount)).toLocaleString()} ฿</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant={"body1"}>{dict.Pay_Within_TXT[profile?.language]} : </Typography>
                        <Typography variant={"body1"}>{billInfo?.invoice_due_date ? moment(billInfo?.invoice_due_date).format("DD/MM/YYYY") : null}</Typography>
                    </Stack>
                    <Grid container sx={{ marginTop: 2 }}>

                        <Grid item xs={12}>
                            <Typography variant={"body1"}>{dict.Scan_To_Paid_TXT[profile?.language]}</Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Zoom>
                                <div style={{ height: "350px" }}>
                                    <div
                                        role="img"
                                        style={{
                                            background: `url(data:image/jpeg;base64,${order?.qr.image_with_base64}) no-repeat`,
                                            width: "100%",
                                            height: "100%",
                                            backgroundSize: "contain",
                                            backgroundPosition: "center center"

                                        }} />
                                </div>

                            </Zoom>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ padding: 2 }}>
                    <Stack direction={"row"}>
                        <Box sx={{ wordBreak: "break-all" }}>
                            <Typography variant="caption"> {dict.Transaction_Number_TXT[profile?.language]} : {order?.qr.id}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"}>
                        <Box sx={{ wordWrap: "break-word" }}>
                            <Typography variant="caption"> {dict.Ref_Number_TXT[profile?.language]} : {order?.order_code}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"}>
                        <Typography variant="caption"> {dict.Remaining_TXT[profile?.language]} : {minutes} {dict.Minutes_TXT[profile?.language]} {seconds} {dict.Second_TXT[profile?.language]} </Typography>
                    </Stack>
                </Box>
            </>
        }

        {
            ((location?.state.methodType !== "qr" && location?.state.methodType !== "qr-kbank" && loading === false)) &&
            <>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh", p: 5 }}>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{dict.AfterPaymentMessage_TXT[profile?.language]}</Typography>

                        <Button variant={"contained"} sx={{ mt: 5 }} onClick={() => liff.closeWindow()}>
                            {dict.AfterPaymentBtn_TXT[profile?.language]}
                        </Button>
                    </Box>

                </Box>

            </>
        }

        {
            (location?.state.methodType === "credit-kbank") &&
            <ScriptKbank
                line_id={location.state?.line_id}
                language={location.state?.language}
                product_type={location.state?.product_type}
                project_id={location.state?.project_id}
                company_id={checkCompanyId()}
                // orderId={order.id}
                customer_id={billInfo?.customer_id}
                contract_id={billInfo?.contract_id}
                unit_id={billInfo?.unit_id}
                term_id={billInfo?.term_id}
                term_name={billInfo?.term_name}
                term_name_eng={billInfo?.term_name_eng}
                project_name={location.state?.project_name}
                method_type={"card"}
                amount={location.state?.amount}
                setStopLoad={() => setLoading(false)}
                refreshPage={handleRefreshPage}
            // onClose={() => setOrder(null)}
            />
        }

        <BackDropLoading
            loading={loading}
        />

        <ModalError
            onClose={() => setModalError(false)}
            open={modalError}
            error={error}
        />
    </>
}