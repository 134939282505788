import React from 'react'
import { Backdrop , CircularProgress , Stack} from '@mui/material'
export const BackDropLoading = (props) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props?.loading}
        >
            <Stack alignItems={"center"} >
                <CircularProgress color="inherit" />
            </Stack>

        </Backdrop>
    )
}
