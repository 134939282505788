export const Transfer_Menu_Info_TXT = []
Transfer_Menu_Info_TXT["en"] = "Ownership Transfer Process Documents"
Transfer_Menu_Info_TXT["th"] = "เอกสารขั้นตอนการโอนกรรมสิทธิ์"

export const Transfer_Menu_Expense_TXT = []
Transfer_Menu_Expense_TXT["en"] = "Expenses On Transfer Day"
Transfer_Menu_Expense_TXT["th"] = "ค่าใช้จ่ายวันโอน"

export const Transfer_Menu_Letter_TXT = []
Transfer_Menu_Letter_TXT["en"] = "Transfer Appointment Letter"
Transfer_Menu_Letter_TXT["th"] = "จดหมายนัดโอน"

export const Transfer_Step_TXT = []
Transfer_Step_TXT["en"] = "Ownership Transfer Process"
Transfer_Step_TXT["th"] = "ขั้นตอนการโอนกรรมสิทธิ์"

export const Transfer_Prepare_TXT = []
Transfer_Prepare_TXT["en"] = "Preparation Before Transferring Ownership"
Transfer_Prepare_TXT["th"] = "การเตรียมตัวก่อนไปโอนกรรมสิทธิ์"

export const Transfer_Title_Detail_TXT = []
Transfer_Title_Detail_TXT["en"] = "Contract Details"
Transfer_Title_Detail_TXT["th"] = "รายละเอียดสัญญา"

export const Transfer_Title_Letter_TXT = []
Transfer_Title_Letter_TXT["en"] = "Letter of Appointment To Transfer Ownership"
Transfer_Title_Letter_TXT["th"] = "จดหมายนัดโอนกรรมสิทธิ์"

export const Transfer_ContractNo_TXT = []
Transfer_ContractNo_TXT["en"] = "Contract Number"
Transfer_ContractNo_TXT["th"] = "เลขที่สัญญา"

export const Transfer_PricePer_TXT = []
Transfer_PricePer_TXT["en"] = "Price Per sq m."
Transfer_PricePer_TXT["th"] = "ราคาต่อ ตรว."

export const Transfer_NetPrice_TXT = []
Transfer_NetPrice_TXT["en"] = "Net Selling Price On Transfer Date"
Transfer_NetPrice_TXT["th"] = "ราคาขายสุทธิ ณ วันโอน"

export const Transfer_Date_TXT = []
Transfer_Date_TXT["en"] = "Date Of Appointment To Transfer"
Transfer_Date_TXT["th"] = "วันที่นัดโอนกรรมสิทธิ์"

export const Transfer_Pdf_TH_TXT = []
Transfer_Pdf_TH_TXT["en"] = "Thai version"
Transfer_Pdf_TH_TXT["th"] = "ฉบับภาษาไทย"

export const Transfer_Pdf_EN_TXT = []
Transfer_Pdf_EN_TXT["en"] = "English version"
Transfer_Pdf_EN_TXT["th"] = "ฉบับภาษาอังกฤษ"

export const Transfer_Letter_Date_TXT = []
Transfer_Letter_Date_TXT["en"] = "Letter Issue Date"
Transfer_Letter_Date_TXT["th"] = "วันที่ออกจดหมาย"

export const Transfer_Letter_No_TXT = []
Transfer_Letter_No_TXT["en"] = "Letter Number"
Transfer_Letter_No_TXT["th"] = "เลขที่จดหมาย"