import {
    Stack,
    Container,
    Box,
} from '@mui/material'
import * as dict from '../../dictionary/Transfer';
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import { DialogPhone } from '../../ui-component/DialogPhone';
import * as api from "../../api/PhoneAllCP"
import {
    ModalError,
    BackDropLoading,
    CardMenu
} from '../../ui-component';
export const Transfer = () => {

    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [openDialogPhone, setOpenDialogPhone] = useState({ list: [], open: false })
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const context = useContext(ProfileContext)

    const handleOpenTelephone = async () => {
        if (openDialogPhone.list.length > 0) {
            return setOpenDialogPhone(prev => ({ ...prev, open : true }))
        }
        setLoading(true)
        let res = await api.getPhoneCP({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "type": "call_center"
        })
        if (res?.status === 200) {
            setOpenDialogPhone({ list: res.data?.data["call_center"] || [], open: true })
            setLoading(false)
            return
        }
        setLoading(false)
        setModalError({ errorResponse: res, open: true })
        return
    }

    useEffect(() => {
        if (profile !== null) {
            //do something
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    <Stack mt={1} spacing={2}>
                        <CardMenu iconImg={"transferIcon"} text={dict.Transfer_Menu_Info_TXT[profile?.language]} navigate={"info"} />
                        <CardMenu iconImg={"transferIcon"} text={dict.Transfer_Menu_Expense_TXT[profile?.language]} navigate={"expense"} />
                        <CardMenu iconImg={"transferIcon"} text={dict.Transfer_Menu_Letter_TXT[profile?.language]} navigate={"letter"} />
                        <CardMenu iconImg={"transferIcon"} text={"Call Center"} onClick={handleOpenTelephone} />

                    </Stack>
                </Container>
            </Box >
            <DialogPhone
                title={"Call Center"}
                open={openDialogPhone.open}
                handleClose={() => setOpenDialogPhone(prev => ({ ...prev, open: false }))}
                list={openDialogPhone.list}
            />
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={loading}
            />

        </>
    )

}