import axios from "axios"

export const getOther = async (data) => {
  try {
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/post/other`,
      data: {
        line_id: data.line_id,
        product_type: data.product_type,
      },
    });
    return result;
  } catch (error) {
    return error;
  }
};
