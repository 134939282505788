export const Name_On_Contract_TXT = []
Name_On_Contract_TXT["en"] = "Name"
Name_On_Contract_TXT["th"] = "ชื่อผู้รับตามหน้าสัญญา"

export const MobilePhone_Number_TXT = []
MobilePhone_Number_TXT["en"] = "Phone Number"
MobilePhone_Number_TXT["th"] = "เบอร์โทรศัพท์มือถือ"

export const Delivery_Address_TXT = []
Delivery_Address_TXT["en"] = "Delivery Address"
Delivery_Address_TXT["th"] = "ที่อยู่จัดส่งเอกสาร"

export const Email_TXT = []
Email_TXT["en"] = "Email"
Email_TXT["th"] = "อีเมล"

export const Change_Delivery_Address_TXT = []
Change_Delivery_Address_TXT["en"] = "To change the document delivery address please contact 1720"
Change_Delivery_Address_TXT["th"] = "หากต้องการเปลี่ยนที่อยู่จัดส่งเอกสาร กรุณาติดต่อ 1720"