import * as dict from "../../dictionary";
import {
    Typography, Container, Box, Stack, Card, styled
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
export const LoanDetail = ({ detail, profile }) => {
    const theme = useTheme()
    const formatCurrency = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >

        <CardNormal variant="outlined">
            <Stack direction={"column"} spacing={1} sx={{ pl: 3, pt: 3, pr: 3 }}>
                <Typography variant={"h6"} sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>{dict.Title_Info_Customer_TXT[profile?.language]}</Typography>
                <BoxFlex >
                    <TextLabelLeft>
                        <Typography variant={"body2"}>{dict.Label_Name_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.customer_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"}>{dict.Project_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.project_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Loan_RoomPlan_No_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.unit_no || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.HouseNumber_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.house_no || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"}>{dict.Loan_Contract_No_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.contract_no || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <Typography variant={"h6"} sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>{dict.Title_Info_Loan_TXT[profile?.language]}</Typography>

                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Loan_Type_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.loan_type_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Loan_Bank_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>

                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Label_ฺBank_Branch_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_branch_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>

                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Label_ฺBank_Type_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_loan_type || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>

                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Loan_Contact_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_staff_name || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>
                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Phone_Number_TXT[profile?.language]}</Typography>
                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_staff_tel || "-"}</Typography>
                    </TextLabelRight>
                </BoxFlex>

                <BoxFlex>
                    <TextLabelLeft>
                        <Typography variant={"body2"} >{dict.Email_TXT[profile?.language]}</Typography>

                    </TextLabelLeft>
                    <TextLabelRight>
                        <Typography variant={"body2"} fontWeight={"bold"}>{detail?.bank_staff_email || "-"}</Typography>

                    </TextLabelRight>
                </BoxFlex>
            </Stack>
            <Box sx={{ backgroundColor: theme.palette.grey[100], padding: 2, ml: 2, mr: 2, mt: 3, mb: 2, borderRadius: "10px" }}>
                <Stack direction={"row"} justifyContent={"space-between"} flexWrap="wrap">
                    <Typography variant={"body2"}>{dict.Label_Date_Approve_TXT[profile?.language]}</Typography>
                    <Typography variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>{detail?.approve_date ? detail?.approve_date.split(" ")[0] : "-"}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} flexWrap="wrap">
                    <Typography variant={"body2"} >{dict.Label_Credit_Limit_TXT[profile?.language]}</Typography>
                    <Typography variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>{detail?.total_amount ? formatCurrency(detail.total_amount) : "0.00"}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} flexWrap="wrap">
                    <Typography variant={"body2"} >{dict.Loan_Amount_Req_TXT[profile?.language]}</Typography>
                    <Typography variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>{detail?.request_amount ? formatCurrency(detail.request_amount) : "0.00"}</Typography>
                </Stack>
            </Box>
        </CardNormal>
    </Container>
}


const CardNormal = styled(Card)({
    borderRadius: "10px",
    boxShadow: "0px 0px 7px 0px #00000040"
})

const BoxFlex = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
})
const TextLabelLeft = styled(Box)({
    flexShrink: 0
})

const TextLabelRight = styled(Box)({
    flex: 1,
    textAlign: "right",
    overflowWrap: "anywhere"
})
