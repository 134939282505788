import React from 'react'
import { 
    Box, 
    Typography, 
    CardMedia,
    Grid
} from '@mui/material'
import failLogo from '../../assets/images/logo/cross.png'
export const ResultFailed = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh", p: 5 }}>
            <Grid container direction={"column"} justifyContent="center" alignItems="center" >
                <CardMedia
                    component="img"
                    sx={{ width: "50%" }}
                    src={failLogo}
                />
                <Typography>การชำระเงินไม่สำเร็จ</Typography>
                <Typography>Payment unsuccessful</Typography>

            </Grid>

        </Box>
    )
}
