import React from "react";

export const LanguageContext = React.createContext();

const initialState = {
    languageSelected : "TH"
};


const LanguageReducer = (state, action) => {

    switch (action.type) {
        case "SWITCH_LANGUAGE": return {
            ...state, languageSelected: action.payload
        }
        default: return
    }
}


export const LanguageProvider = props => {
    const [state, dispatch] = React.useReducer(LanguageReducer, initialState);
    const value = { state, dispatch };

    return (
        <LanguageContext.Provider value={value}>
            {props.children}
        </LanguageContext.Provider>
    );
}

export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE"
