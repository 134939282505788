import React, { useEffect, useState, useContext} from 'react'
import {
    Grid,
    Typography, Card, CardContent, Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { ProfileContext } from '../../../contexts/ProfileContext';
import * as dict from '../../../dictionary'
export const SelectFacility = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(ProfileContext)
    const [profile, setProfile] = useState(null)


    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
        <Box sx={{ marginTop: 8, height: "100vh" }}>
            {
                location.state?.detail?.map((element, index) => {
                    return <Card key={index} variant="outlined" sx={{ marginTop: 3 }} onClick={() => {
                        navigate('/facility/formBooking' + location.search, {
                            state: { id: element.facilitiesId }
                        })
                    }}>
                        <CardContent >
                            <Typography variant={"h6"} gutterBottom component="div" sx={{ textAlign: "left" }}>
                                {element.detail}
                            </Typography>
                            <img
                                src={element.images}
                                style={{
                                    width: "100%",
                                    height: "150px",
                                    objectFit: "cover"
                                }}
                            />
                            <Box sx={{ width: "100%" }} >
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={10} sx={{ textAlign: "left" }}>
                                        <Typography variant={"title2"} gutterBottom >
                                            {dict.Opening_TXT[profile?.language]} : {element.openDate}
                                        </Typography>
                                        <br />
                                        <Typography variant={"title1"} gutterBottom>
                                            {dict.Opening_Hours_TXT[profile?.language]} : {element.openTime}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={1} sx={{ textAlign: "right" }}>
                                        <ArrowForwardIos sx={{ fontSize: 20 }} />
                                    </Grid>

                                </Grid>
                                <Typography variant={"caption"}>
                                    {element.description}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card >
                })
            }
        </Box>
    )
}
