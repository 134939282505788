import React from "react";

export const HeaderContext = React.createContext();

const initialState = {
    customCloseBtn: {
        jsx: <></>,
        visible: false
    },
    paperHasCloseBtn: false,
    event:{
        closeOnClick : false
    },
    dialogConfirm: false,
};


const HeaderReducer = (state, action) => {

    switch (action.type) {
        case "FIX_CLOSE_BTN": return {
            ...state, customCloseBtn: action.payload
        }

        case "PAPER_HAS_CLOSE_BTN" : return { ...state, paperHasCloseBtn: action.payload ,  event: { closeOnClick: false } }
        case "DIALOG_CONFIRM_TRIGGER" : return { ...state, dialogConfirm: action.payload }
        case "EVENT_CLOSE_CLICK" : return { ...state, event: { closeOnClick: action.payload } }
        default: return
    }

}


export const HeaderProvider = props => {
    const [state, dispatch] = React.useReducer(HeaderReducer, initialState);
    const value = { state, dispatch };

    return (
        <HeaderContext.Provider value={value}>
            {props.children}
        </HeaderContext.Provider>
    );
}

export const FIX_CLOSE_BTN = "FIX_CLOSE_BTN"
export const PAPER_HAS_CLOSE_BTN = "PAPER_HAS_CLOSE_BTN"
export const DIALOG_CONFIRM_TRIGGER = "DIALOG_CONFIRM_TRIGGER"
export const EVENT_CLOSE_CLICK = "EVENT_CLOSE_CLICK"
