export const Announcement_TXT = []
Announcement_TXT['en'] = "Create Date"
Announcement_TXT['th'] = "วันที่ประกาศ"

export const Promotion_Life_TXT = []
Promotion_Life_TXT["en"] = "Promotion and lifestyle"
Promotion_Life_TXT["th"] = "โปรโมชั่นและไลฟ์สไตล์"

export const Public_Rela_TXT = []
Public_Rela_TXT["en"] = "Advertise"
Public_Rela_TXT["th"] = "ประชาสัมพันธ์"

export const Promotion_TXT = []
Promotion_TXT["en"] = "Promotion"
Promotion_TXT["th"] = "โปรโมชั่น"

export const Find_Project_TXT = []
Find_Project_TXT["en"] = "Find a project"
Find_Project_TXT["th"] = "ค้นหาโครงการ"

export const House_Condo_TXT = []
House_Condo_TXT["en"] = "Condo/House"
House_Condo_TXT["th"] = "คอนโด/บ้าน"

export const Province_TXT = []
Province_TXT["en"] = "Province"
Province_TXT["th"] = "จังหวัด"

export const Search_Project_TXT = []
Search_Project_TXT["en"] = "Search"
Search_Project_TXT["th"] = "ค้นหา"

export const Reset_Search_TXT = []
Reset_Search_TXT["en"] = "Reset search"
Reset_Search_TXT["th"] = "รีเซทการค้นหา"

export const Gallery_TXT = []
Gallery_TXT["en"] = "Gallery"
Gallery_TXT["th"] = "แกลอรี่"

export const Progress_TXT = []
Progress_TXT["en"] = "Progress"
Progress_TXT["th"] = "ความคืบหน้า"

export const Map_TXT = []
Map_TXT["en"] = "Map"
Map_TXT["th"] = "แผนที่"

export const Plan_TXT = []
Plan_TXT["en"] = "Plan"
Plan_TXT["th"] = "แปลน"

export const Advertis_TXT = []
Advertis_TXT["en"] = "Advertising"
Advertis_TXT["th"] = "สื่อโฆษณา"

export const More_Info_TXT = []
More_Info_TXT["en"] = "More"
More_Info_TXT["th"] = "เพิ่มเติม"

export const Edit_On_TXT = []
Edit_On_TXT["en"] = "Edited"
Edit_On_TXT["th"] = "แก้ไขเมื่อ"

export const Promotion_Detail_TXT = []
Promotion_Detail_TXT["en"] = "Promotion details"
Promotion_Detail_TXT["th"] = "รายละเอียดโปรโมชั่น"

export const Contact_Number_TXT = []
Contact_Number_TXT["en"] = "Contact number"
Contact_Number_TXT["th"] = "เบอร์โทรติดต่อ"


export const Graphic_Map_TXT = []
Graphic_Map_TXT["en"] = "Graphic map"
Graphic_Map_TXT["th"] = "แผนที่กราฟฟิก"

export const Area_TXT = []
Area_TXT["en"] = "area"
Area_TXT["th"] = "พื้นที่"

export const LivingRoom_TXT = []
LivingRoom_TXT["en"] = "living room"
LivingRoom_TXT["th"] = "ห้องนั่งเล่น"

export const Square_Meter_TXT = []
Square_Meter_TXT["en"] = "sq m"
Square_Meter_TXT["th"] = "ตร.ม"

export const Bedroom_TXT = []
Bedroom_TXT["en"] = "bedroom"
Bedroom_TXT["th"] = "ห้องนอน"

export const ParkingSpace_TXT = []
ParkingSpace_TXT["en"] = "parking space"
ParkingSpace_TXT["th"] = "ช่องจอดรถ"

export const Bathroom_TXT = []
Bathroom_TXT["en"] = "bathroom"
Bathroom_TXT["th"] = "ห้องนํ้า"

export const StartingPrice_TXT = []
StartingPrice_TXT["en"] = "starting price"
StartingPrice_TXT["th"] = "ราคาเริ่มต้น"

export const Location_TXT = []
Location_TXT["en"] = "location"
Location_TXT["th"] = "สถานที่"

export const Announcement_Tab_TXT = []
Announcement_Tab_TXT["en"] = "Announcement"
Announcement_Tab_TXT["th"] = "ประกาศจากโครงการ"

export const News_Tab_TXT = []
News_Tab_TXT["en"] = "News"
News_Tab_TXT["th"] = "ข่าวสารที่น่าสนใจ"

export const LifeStyle_TXT = []
LifeStyle_TXT["en"] ="LIFE STYLE"
LifeStyle_TXT["th"] ="ไลฟ์สไตล์"

