import axios from "axios";

export const getLoanCPDocument = async (data) => {
  try {
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/list`,
      data: {
        line_id: data?.line_id,
        product_type: data?.product_type,
        project_id: data?.project_id,
        type: data?.type,
      },
    });

    return result;
  } catch (error) {
    return error;
  }
};

export const getLoanCPStatus = async (data) => {
  try {
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/loan/loadall`,
      data: {
        line_id: data?.line_id,
        product_type: data?.product_type,
        project_id: data?.project_id,
        unit_id: data?.unit_id,
      },
    });

    return result;
  } catch (error) {
    return error;
  }
};

