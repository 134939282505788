import React , { useState, useEffect, useContext }from 'react'
import {
    Stack, Card, Container,
    Typography,
    Box, Grid, Tabs, Tab,
    CardMedia, Slide, Snackbar, CardActionArea, 
    CardContent, CircularProgress, Backdrop
} from '@mui/material'
import {  CheckCircle } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileContext } from '../../contexts/ProfileContext';
import * as api from '../../api/'
import * as dict from '../../dictionary'
const TransitionLeft = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const Facility = () => {
    const [tabSelected, setTabSelected] = useState("serviceTab")
    const [listFacility, setListFacility] = useState([])
    const [listBooking, setListBooking] = useState([])
    const [listHistory, setListHistory] = useState([])
    const [snackSuccess, setSnackSuccess] = useState(false)
    const [snackText, setSnackText] = useState(null)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [loadingList, setLoadingList] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(ProfileContext)
    const groupBy = (array, key) => {
        let group = array.reduce((r, a) => {
            r[a[key]] = [...r[a[key]] || [], a];
            return r;
        }, {});
        return group
    }
    const customListData = () => {
        return Object.keys(listFacility).map((key, i) => {
            return <Card variant="outlined" sx={{
                display: "flex",
                height: "200px",
                paddingTop: 2,
                paddingBottom: 2,
                alignItems: "flex-end",
                justifyContent: "center"
            }} key={i}>
                <Box sx={{
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url(${listFacility[key][0]["images"]})`,
                    height: "100%",
                    width: "85%",
                    backgroundSize: "cover",
                    borderRadius: "10px",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                }} onClick={() => {
                    sessionStorage.setItem('tabSelected', tabSelected)
                    navigate('/facility/selectFacility' + location.search, {
                        state: { detail: listFacility[key] }
                    })
                }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography variant={"h6"} color="white" gutterBottom component="div" sx={{ textAlign: "left" }}>
                                {key}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Box>
            </Card >
        })
    }
    const loadListFacility = async () => {
        setLoadingList(true)
        let res = await api.getAllByProject({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "developer_id": "",
            "type_id": "",
            "date": "",
            "open_time": "",
            "close_time": "",
            "page": "1"
        })
        let data = res.data?.body
        let dataGroup = groupBy(data, "facilitiesName")
        setListFacility(dataGroup)
        setLoadingList(false)
    }
    const loadListBooking = async () => {
        setLoadingList(true)
        let res = await api.getFacilityBooking({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "page": "1"
        })
        setListBooking(res.data?.body)
        setLoadingList(false)
    }
    const loadListHistory = async () => {
        setLoadingList(true)
        let res = await api.getFacilityBookingHistory({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "page": "1"
        })
        setListHistory(res.data?.body)
        setLoadingList(false)
    }
    useEffect(() => {
        if (profile !== null && houseInfo !== null) {
            loadListFacility()
        }
    }, [profile, houseInfo])
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    useEffect(() => {
        if (tabSelected === "serviceTab" && (profile !== null && houseInfo !== null)) {
            loadListFacility()
        }
        if (tabSelected === "bookingTab") {
            loadListBooking()
        }
        if (tabSelected === "historyTab") {
            loadListHistory()
        }
    }, [tabSelected, snackSuccess]);
    useEffect(() => {
        let tabSession = sessionStorage.getItem("tabSelected")
        setTabSelected(tabSession || tabSelected)
    }, []);

    return (
        <>
            <Box style={{ height: "100vh" }}>
                <Tabs
                    value={tabSelected}
                    onChange={(e, val) => setTabSelected(val)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ marginTop: 8 }}
                    centered
                    variant="fullWidth"
                >
                    <Tab value="serviceTab" label={`${dict.Service_TXT[profile?.language]}`} />
                    <Tab value="bookingTab" label={`${dict.Booking_TXT[profile?.language]}`} />
                    <Tab value="historyTab" label={`${dict.History_TXT[profile?.language]}`} />
                </Tabs>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingList}
                >
                    <Stack alignItems={"center"} >
                        <CircularProgress color="inherit" />
                    </Stack>
                </Backdrop>
                <Container sx={{ minHeight: "100%" }}>
                    <Box >
                        <Stack spacing={1}>
                            {
                                tabSelected === "serviceTab" && <Box maxWidth="xl">
                                    <Stack mt={2} spacing={1}>
                                        {customListData()}
                                    </Stack>
                                </Box>
                            }
                            {
                                tabSelected === "bookingTab" && listBooking?.map((element, index) => {
                                    return <Box mt={2} key={index}>
                                        <Stack spacing={1}>
                                            <Card variant="outlined" sx={{ borderRadius: "10px" }} onClick={() => {
                                                sessionStorage.setItem('tabSelected', tabSelected)
                                                navigate('/facility/booking' + location.search, {
                                                    state: { bookingId: element.bookingId }
                                                })
                                            }}>
                                                <CardContent>
                                                    <Grid container >
                                                        <Grid item xs={12}>

                                                            <CardMedia
                                                                component="img"
                                                                height="100%"
                                                                src={element.facilitiesIcon}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Stack direction="column"
                                                                justifyContent="center"
                                                                alignItems="flex-start"
                                                                spacing={1}
                                                                mt={2}
                                                                ml={2}
                                                            >
                                                                <Typography variant="h6" >
                                                                    {element.facilitiesName}
                                                                </Typography>
                                                                <Typography variant="subtitle2" >
                                                                    {element.bookingDate} | {dict.Time_TXT[profile?.language]} {element.bookingStart} - {element.bookingEnd}
                                                                </Typography>
                                                                <Typography variant="caption" >
                                                                    {element.bookingId}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Box>
                                })
                            }
                            {
                                tabSelected === "historyTab" && listHistory?.map((element, index) => {
                                    return <Box maxWidth="xl" key={index}>
                                        <Stack mt={2} spacing={1}>
                                            <Card variant="outlined" sx={{ borderRadius: "10px" }} onClick={() => {
                                                sessionStorage.setItem('tabSelected', tabSelected)
                                                navigate('/facility/history' + location.search, {
                                                    state: { bookingId: element.bookingId, listFacility: listFacility }
                                                })
                                            }}>
                                                <CardContent>
                                                    <Grid container >
                                                        <Grid item xs={12}>
                                                            <CardMedia
                                                                component="img"
                                                                height="100%"
                                                                src={element.facilitiesIcon}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Stack direction="column"
                                                                justifyContent="center"
                                                                alignItems="flex-start"
                                                                spacing={1}
                                                                mt={2}
                                                                ml={2}
                                                            >
                                                                <Typography variant="h6" >
                                                                    {element.facilitiesName}
                                                                </Typography>
                                                                <Typography variant="subtitle2" >
                                                                    {element.bookingDate} | {dict.Time_TXT[profile?.language]} {element.bookingStart} - {element.bookingEnd}
                                                                </Typography>
                                                                <Typography variant="caption" >
                                                                    {element.bookingId}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Box>
                                })
                            }
                            <Snackbar
                                open={snackSuccess}
                                onClose={() => setSnackSuccess(false)}
                                TransitionComponent={TransitionLeft}
                                message={<Box sx={{ display: "flex", alignItems: "center" }}><CheckCircle sx={{ color: "green" }} /> {snackText}</Box>}
                            />
                            <Box sx={{ height: "10px" }}></Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
