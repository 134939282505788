import axios from 'axios'

export const getAllByProject = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/all`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "developer_id": "",
                "type_id": "",
                "date": "",
                "open_time": "",
                "close_time": "",
                "page": data.page
            }
        })

        return result
    } catch (error) {
        return error
    }
}

export const getOptionFacility = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/option`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "facilities_id": data.facilities_id
            }
        })
        return result
    } catch (error) {

    }

}

export const getFacilityDetail = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/detail`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "facilities_id": data.facilities_id
            }
        })
        return result
    } catch (error) {
        return error
    }

}

export const confirmFacility = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/confirm`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "booking_id": data.booking_id
            }
        })
        return result
    } catch (error) {
        return error
    }

}

export const cancelFacility = async (data) => {

    try {
        let result = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/cancel`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "booking_id": data.booking_id
            }
        })
        return result
    } catch (error) {
        return error
    }

}

export const bookingFacility = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/booking`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "facilities_id": data.facilities_id,
                "date": data.date,
                "bookin_start": data.bookin_start,
                "booking_end": data.booking_end,
                "hour_amount": data.hour_amount,
                "house_number": data.house_number,
                "option_id": data.option_id,
                "project_id": data.project_id,
                "total_price": data.total_price,
                "unit_id": data.unit_id
            }
        })
        return result
    } catch (error) {
        return error
    }

}


export const listTypeFacility = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/type`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "developer_id": "",
                "project_id": data.project_id
            }
        })
        return result
    } catch (error) {
        return error
    }

}

export const getFacilityBooking = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/booking_all`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "page": data.page
            }
        })
        return result
    } catch (error) {
        return error
    }
}

export const getFacilityBookingHistory = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/booking_all/history`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "page": data.page
            }
        })
        return result
    } catch (error) {
        return error
    }
}

export const getFacilityBookingDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/facility/booking_detail/history`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "booking_id": data.booking_id
            }
        })
        return result
    } catch (error) {
        return error
    }
}