import React, { useState, useEffect , useContext } from 'react'
import {
    Box, Tabs, Tab
} from '@mui/material'
import GoogleMap from '../../../../ui-component/GoogleMap';
import { BlankPage } from '../../../../ui-component/BlankPage';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useLocation } from 'react-router-dom';
import { ProfileContext } from '../../../../contexts/ProfileContext';
import * as dict from '../../../../dictionary'
export const MapDialog = () => {

    const [isTab, setIsTab] = useState(0)
    const [mapDetail, setMapDetail] = useState(null)
    const [profile, setProfile] = useState(null)
    const location = useLocation()
    const context = useContext(ProfileContext)
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])
    const renderMapImg = () => {

        if (mapDetail?.map_image?.length > 0) {
            return <Box >
                <Zoom>
                    <div
                        role="img"
                        style={{
                            background: `url(${mapDetail?.map_image}) no-repeat`,
                            backgroundPosition: "center center",
                            backgroundSize: "contain",
                            width: "100%",
                            height: "500px",
                        }} />
                    {/* <img
                        src={mapDetail?.map_image} /> */}
                </Zoom>

            </Box>
        }

        return <BlankPage lang={profile?.language}/>

    }

    const renderGoogleMap = () => {
        if (mapDetail?.latitude?.length > 0 && mapDetail?.longitude?.length > 0) {
            return <GoogleMap value={mapDetail?.latitude + "," + mapDetail?.longitude} />
        }
        return <BlankPage lang={profile?.language}/>
    }


    useEffect(() => {

        if (location.state?.mapDetail !== null) {
            setMapDetail(location.state?.mapDetail)
        }

    }, [location.state?.mapDetail])

    return (
            <Box sx={{ marginTop: 8 }}>
                <Tabs variant="fullWidth" value={isTab} onChange={(event, newValue) => setIsTab(newValue)} centered>
                    <Tab label={dict.Graphic_Map_TXT[profile?.language]} />
                    <Tab label="Google Map" />
                </Tabs>

                {
                    isTab === 0 && renderMapImg()
                }
                {
                    isTab === 1 && renderGoogleMap()
                }

            </Box>
    )

}

