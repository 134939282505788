import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Typography,
    Box, 
} from '@mui/material'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { ZoomCarousel } from '../../../../ui-component';
import { ProfileContext } from '../../../../contexts/ProfileContext';
import { BackDropLoading } from '../../../../ui-component';
import * as api from '../../../../api/'
import * as dict from '../../../../dictionary'
import { checkProductType } from '../../../../utils/checkProductType';
export const PromotionDetail = () => {

    const [detail, setDetail] = useState(null)
    const [openCarousel, setOpenCarousel] = useState(false)
    const [productType, setProductType] = useState(null)
    const [profile, setProfile] = useState(null)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(ProfileContext)

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    const loadDetail = async (id) => {
        setLoadingBackDrop(true)
        let res = await api.getPromotionDetail({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "id": id
        })
        setDetail(res.data?.data)
        setLoadingBackDrop(false)
    }


    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])


    useEffect(() => {

        loadDetail(location.state.id)

    }, [productType]);

    return (
        <>
            <BackDropLoading
                loading={loadingBackDrop}
            />
            <Box sx={{ width: "100%", marginTop: 8 }}>
                <Carousel responsive={responsive}>
                    {
                        detail ? detail?.gallery?.map(element => <div style={{ height: "200px" }} onClick={() => setOpenCarousel(true)}>

                            <img src={element["image1024"]} style={{ objectFit: "contain", width: "100%", height: "100%" }} />

                        </div>) : <div />
                    }


                </Carousel>
            </Box>

            <Box sx={{ backgroundColor: "white", height: 250, padding: 3 }}>
                <Stack direction={"row"} alignItems={"flex-end"} spacing={2}>
                    <Typography variant={"caption"}>{dict.Announcement_TXT[profile?.language]}</Typography>

                    <Typography variant={"caption"}>{detail?.postdate}</Typography>
                    <Typography variant={"caption"}>{dict.Edit_On_TXT[profile?.language]} {detail?.lastupdate}</Typography>
                </Stack>
                <br />
                <Typography variant={"subtitle1"} sx={{ textDecoration: "underline" }}>{dict.Promotion_Detail_TXT[profile?.language]}</Typography>
                <div dangerouslySetInnerHTML={{ __html: (detail?.description) }} />
            </Box>

            <ZoomCarousel
                open={openCarousel}
                onClose={() => setOpenCarousel(false)}
                gallery={detail?.gallery}
            />
        </>


    )
}
