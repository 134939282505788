import React, { useState, useEffect , useContext } from 'react'
import {
    AppBar, Toolbar,
    Typography, Box,Dialog, Slide, Grid
} from '@mui/material'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { ProfileContext } from '../../../../contexts/ProfileContext';
import * as dict from '../../../../dictionary'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const ProgressDialog = (props) => {
    const [profile, setProfile] = useState(null)
    const context = useContext(ProfileContext)
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (

        <Dialog
            fullScreen
            open={props?.open}
            onClose={() => props?.onClose()}
            TransitionComponent={Transition}
        >
            <AppBar position="static" sx={{ boxShadow: 0, height: "9vh", borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}  >
                <Toolbar>
                    <Grid container item xs={1}>
                        <ArrowBackIos onClick={() => props?.onClose()} />
                    </Grid>
                    <Grid container item xs={10} direction="row"
                        justifyContent="center"
                    >
                        <Typography variant="h7" >
                            {dict.Progress_TXT[profile?.language]}
                        </Typography>
                    </Grid>

                </Toolbar>

            </AppBar>
            <Box>

            </Box>
        </Dialog>
    )

}
