import { red, blue, green } from '@mui/material/colors';

export const typeLoan = {
    "wait": { desc: "ยื่นขอสินเชื่อ", color: blue[500] },
    "approve": { desc: "อนุมัติสินเชื่อ", color: green[500] },
    "disApproval": { desc: "ไม่อนุมัติสินเชื่อ", color: red[500] },
    "L000": { desc: "ยื่นขอสินเชื่อ", color: blue[500] },
    "SA_001": { desc: "อนุมัติจริง", color: green[500] },
    "SC_001": { desc: "ยกเลิกการพิจารณา", color: red[500] },
    "SN_001": { desc: "เอกสารเข้าระบบแล้ว", color: blue[500] },
    "SPP001": { desc: "ผ่านการพิจารณาเบื้องต้น", color: green[500] },
    "SR_001": { desc: "ไม่อนุมัติ", color: red[500] },
    "STA001": { desc: "อยู่ระหว่างการพิจารณา", color: blue[500] },
    "STA002": { desc: "ยกเลิกการพิจารณา", color: green[500] },
    "STA003": { desc: "ไม่อนุมัติสินเชื่อ", color: red[500] },
    "STA004": { desc: "ผ่านการพิจารณาเบื้องต้น", color: green[500] },
    "STA005": { desc: "อนุมัติสินเชื่อ", color: green[500] },
    "STA006": { desc: "ติดต่อเจ้าหน้าที่", color: blue[500] },
    "STA007": { desc: "ลูกค้าไม่ยื่นกู้", color: red[500] },
    "SU_001": { desc: "อยู่ระหว่างการดำเนินการ", color: blue[500] },
}
