export const Slip_TXT = []
Slip_TXT["en"] = "Payment Slip"
Slip_TXT["th"] = "สลิปจ่ายเงิน"

export const Unit_TXT = []
Unit_TXT["en"] = "Unit"
Unit_TXT["th"] = "ยูนิต"

export const PayName_TXT = []
PayName_TXT["en"] = "Payer's Name"
PayName_TXT["th"] = "ชื่อผู้ชำระเงิน"

export const Total_TXT = []
Total_TXT["en"] = "Total"
Total_TXT["th"] = "รวมทั้งหมด"

export const Specify_Amount_TXT = []
Specify_Amount_TXT["en"] = "Specify The Amount"
Specify_Amount_TXT["th"] = "ระบุจำนวนเงิน"

export const Please_Specify_Amount_TXT = []
Please_Specify_Amount_TXT["en"] = "Please specify the amount"
Please_Specify_Amount_TXT["th"] = "โปรดระบุจำนวนเงิน"

export const Full_Amount_TXT = []
Full_Amount_TXT["en"] = "Full Amount"
Full_Amount_TXT["th"] = "เต็มจำนวน"

export const Minimum_Amount_TXT = []
Minimum_Amount_TXT["en"] = "Minimum Amount"
Minimum_Amount_TXT["th"] = "ขั้นตํ่า"