import React, { useEffect, useState, useContext } from 'react'
import * as api from '../api'
import { ProfileContext } from '../../src/contexts/ProfileContext'
import { useForm, Controller } from "react-hook-form";
import {
    FormControl, Box, Stack, CircularProgress,
    TextField, Typography, Button, Alert, Grid, Backdrop, Container
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import CollectionsIcon from '@mui/icons-material/Collections';
import AddIcon from '@mui/icons-material/Add';
import { CardWithComment, CardResponse } from '../ui-component/'
import PhoneInput from 'react-phone-number-input/input'
import { useNavigate } from 'react-router-dom';
import * as dict from '../dictionary'
export const Suggestion = () => {
    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [imgSaveArray, setImgSaveArray] = useState([])
    const [countSelected, setCountSelected] = useState(0)
    const [alertResponds, setAlertResponds] = useState(false)
    const [typeAlert, setTypeAlert] = useState(null)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const { control, handleSubmit, getValues } = useForm();
    const context = useContext(ProfileContext)
    const navigate = useNavigate()
    const onError = (errors) => console.log(errors)
    
    const handleOnSumbit = async () => {
        setLoadingBackDrop(true)

        let img = imgSaveArray
        let form = getValues()
        let tempImg = []

        await Promise.all(img.map(async item => {
            let res = await api.uploadImg(item.binary, {subject:"suggestion",product:"lineoa",company_id: houseInfo?.company_id})
            let data = res.data
            tempImg.push({
                "id": data.id,
                "name": data.name,
                "file_size": data.file_size,
                "content_type": data.content_type,
                "url": data.url,
                "description_en": "",
                "description_th": "",
                "sort_id": 0
            })
        }))


        let res = await api.suggestionCreate({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "title": form.title,
            "description": form.description,
            "mobile_no": '0' + form.mobile_no.slice(3),
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "house_number": houseInfo?.house_number,
            "file_gallery": tempImg
        })

        if (res?.data.status === 200) {
            setTypeAlert("success")
            setLoadingBackDrop(false)
            return setAlertResponds(true)
        }
        setLoadingBackDrop(false)
        setTypeAlert("error")
        return setAlertResponds(true)

    }
    const Input = styled('input')({
        display: 'none',
    });

    const uploadFileOnLocal = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImgSaveArray([...imgSaveArray, {
                id: nanoid(),
                imgUrl: reader.result,
                binary: file,
                selected: false,
                value: ""
            }])
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])

    return (
        <Container >

            <Box
                onSubmit={handleSubmit(handleOnSumbit, onError)}
                component="form"
                id="hook-form"
                sx={{ padding: 0.5, marginTop: 10 }}>
                <Stack direction="row" mt={1}>
                    <Typography variant="subtitle1" sx={{ color: "#FF5733" }}>
                        {dict.SuggestionForTeam_TXT[profile?.language]}
                    </Typography>
                </Stack>
                <Stack direction="column" spacing={3} mt={2}>
                    <Controller
                        control={control}
                        name="title"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl fullWidth>

                                <TextField 
                                    onChange={onChange}
                                    rows={8} 
                                    fullWidth 
                                    label={`${dict.Suggestion_Title_TXT[profile?.language]}*`} 
                                    id="fullWidth" 
                                    InputProps={{
                                        style: {
                                            borderRadius: "5px",
                                            border: "1px"
                                        }
                                    }}
                                    />
                                {

                                    error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Pls_Name_Suggestion_TXT[profile?.language]}</Alert></Box>
                                }

                            </FormControl>)}
                    />
                    <Controller
                        control={control}
                        name="description"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl fullWidth>

                                <TextField 
                                onChange={onChange} 
                                multiline
                                rows={8} 
                                fullWidth 
                                label={`${dict.FeedBack_TXT[profile?.language]}*`} 
                                id="fullWidth"
                                InputProps={{
                                    style: {
                                        borderRadius: "5px",
                                        border: "1px"
                                    }
                                }}
                                />
                                {

                                    error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Provide_FeedBack_TXT[profile?.language]}</Alert></Box>
                                }
                            </FormControl>)}
                    />
                    <Stack direction="row" mt={2}>
                        <Typography variant="subtitle1" >
                            {dict.Limit_Photo_TXT[profile?.language]}
                        </Typography>
                    </Stack>


                    <Grid container spacing={1}>

                        {
                            imgSaveArray.length > 0 && imgSaveArray.map((element, key) => <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name={`listImage-${key}`}
                                    defaultValue=""
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <FormControl fullWidth >

                                            <CardWithComment
                                                id={key}
                                                key={key}
                                                selected={element.selected}
                                                cardWidth={"100%"}
                                                cardHeight={"100%"}
                                                disabledComment={true}
                                                onSelected={() => {
                                                    let temp = [...imgSaveArray]
                                                    temp[key].selected = !temp[key].selected
                                                    setImgSaveArray(temp)

                                                    let count
                                                    if (temp[key].selected === true) {
                                                        count = countSelected + 1
                                                        return setCountSelected(count)
                                                    }
                                                    count = countSelected - 1
                                                    return setCountSelected(count)
                                                }}
                                                deleteIndex={() => setImgSaveArray(imgSaveArray.filter((r, index) => r.id !== element.id))}
                                                callBackInput={(val) => {
                                                    let temp = [...imgSaveArray]
                                                    temp[key].value = val
                                                    setImgSaveArray(temp)
                                                }}
                                                image={element.imgUrl}
                                                value={element.value}
                                            />
                                        </FormControl>
                                    )}
                                /> </Grid>
                            )
                        }
                        {imgSaveArray.length < 3 && <Grid item xs={4}> <label htmlFor="contained-button-file">
                            <Input accept="image/*" id="contained-button-file" multiple type="file"
                                onChange={(e) =>
                                    uploadFileOnLocal(e.target.files[0])
                                } />
                            <Box
                                id={"uploadPanel"}
                                sx={{

                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100px",
                                    height: "100px",
                                    border: '1px dashed grey'
                                }}
                                onClick={() => { }}

                            >
                                <AddIcon sx={{ fontSize: 30 }} />
                                <CollectionsIcon sx={{ fontSize: 50 }} />


                            </Box></label>
                        </Grid>
                        }     </Grid>


                    <Controller
                        control={control}
                        name="mobile_no"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl >
                                <Stack direction="row" >
                                    <Typography variant="subtitle1">{dict.Tel_Callback_TXT[profile?.language]}*</Typography>
                                </Stack>

                                <PhoneInput
                                    style={{ height: 30, paddingLeft: 4, marginTop: 15, marginBottom: 15 }}
                                    country={"TH"}
                                    withCountryCallingCode={false}
                                    value={value}
                                    onChange={onChange}
                                />
                                {

                                    error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Input_MobileNumber_TXT[profile?.language]}</Alert></Box>
                                }
                            </FormControl>)}
                    />
                    <Button variant={"contained"} type={"submit"}>{dict.AcceptBtn_TXT[profile?.language]}</Button>
                </Stack>
                <br></br>
                <CardResponse
                    type={typeAlert}
                    titleDescription={dict.Recommend_Save_TXT[profile?.language]}
                    open={alertResponds}
                    onClose={() => {
                        setAlertResponds(false)
                        navigate(-1)
                    }}
                    onOk={() => {
                        setAlertResponds(false)
                        navigate(-1)

                    }}
                />
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackDrop}
            >
                <Stack alignItems={"center"} >
                    <Typography>{dict.Saving_Data_TXT[profile?.language]}</Typography>
                    <CircularProgress color="inherit" />
                </Stack>

            </Backdrop>

        </Container>
    )
}
