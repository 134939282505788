import { useState } from "react";
import { Container, Box, Typography, Button, Stack , Snackbar, Alert } from "@mui/material"
import maintainLogo from '../../assets/images/logo/warning.png'
import * as api from '../../api/Profile'
import liff from '@line/liff';

export const NoProfile = () => {

    const [isErrorLogout, setIsErrorLogout] = useState(false)
    let profile_lineOA = JSON.parse(localStorage.getItem("profile_lineOA"))

    const logoutService = async () => {
        let res = await api.logout({
            "line_id": profile_lineOA?.profile.userId
        })

        if (res.data?.status !== 200) {
            setIsErrorLogout(true)
            return
        }

        // liff.logout()
        localStorage.clear()
        liff.closeWindow()
        return
    }

    const closeWindow = () => {
        liff.closeWindow()
    }


    return (
        <>
            <Container>
                <Box sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    /* bring your own prefixes */
                    transform: "translate(-50%, -50%)",
                    textAlign: "center"
                }}>

                    <img
                        src={maintainLogo}
                        width={"130px"}
                        height={"130px"}
                        alt='maintain page'
                    />
                    <Typography noWrap>OOPS, Something Went Wrong.</Typography>
                    <Stack direction={"row"} spacing={3} mt={2} justifyContent={"center"}>
                        <Button variant="contained" onClick={closeWindow} color={"info"} sx={{ minWidth: "120px"}}><Typography variant='body2' >Close</Typography></Button>
                        <Button variant="contained" onClick={logoutService} color={"error"} sx={{ minWidth: "120px"}}><Typography variant='body2'>Logout</Typography></Button>
                    </Stack>
                </Box>
                <Snackbar open={isErrorLogout} autoHideDuration={6000} onClose={() => setIsErrorLogout(false)}>
                    <Alert severity="error" ><Typography variant="body2" >An error occurred and unable to log out.</Typography></Alert>
                </Snackbar>
            </Container>
        </>
    )

}