
export const HeaderMyHome_TXT = []
HeaderMyHome_TXT["th"] = "บ้านของฉัน"
HeaderMyHome_TXT["en"] = "My Home"

export const HeaderBillPayment_TXT = []
HeaderBillPayment_TXT["th"] = "ค่าใช้จ่าย"
HeaderBillPayment_TXT["en"] = "Bill Payment"

export const HeaderParcel_TXT = []
HeaderParcel_TXT["th"] = "พัสดุ"
HeaderParcel_TXT["en"] = "Parcel"

export const HeaderParcelDetail_TXT = []
HeaderParcelDetail_TXT["th"] = "รายละเอียดพัสดุ"
HeaderParcelDetail_TXT["en"] = "Parcel Detail"

export const HeaderPhoneBook_TXT = []
HeaderPhoneBook_TXT["th"] = "รายชื่อติดต่อ"
HeaderPhoneBook_TXT["en"] = "Phone Book"

export const HeaderFacility_TXT = []
HeaderFacility_TXT["th"] = "ส่วนกลาง"
HeaderFacility_TXT["en"] = "Facility"

export const HeaderLoan_TXT = []
HeaderLoan_TXT["th"] = "ข้อมูลสินเชื่อ"
HeaderLoan_TXT["en"] = "Loan"

export const HeaderDefect_TXT = []
HeaderDefect_TXT["th"] = "แจ้งซ่อม"
HeaderDefect_TXT["en"] = "Defect"

export const HeaderDefectFormAdd_TXT = []
HeaderDefectFormAdd_TXT["th"] = "แบบฟอร์มแจ้งซ่อม"
HeaderDefectFormAdd_TXT["en"] = "Defect Reporting Form"

export const HeaderProfile_TXT = []
HeaderProfile_TXT["th"] = "ข้อมูลส่วนตัว"
HeaderProfile_TXT["en"] = "Profile"


export const HeaderRegister_TXT = []
HeaderRegister_TXT["th"] = "ลงทะเบียน"
HeaderRegister_TXT["en"] = "Registration"

export const HeaderContactJuristic_TXT = []
HeaderContactJuristic_TXT["th"] = "ติดต่อนิติ"
HeaderContactJuristic_TXT["en"] = "Contact Juristic"

export const HeaderSuggestion_TXT = []
HeaderSuggestion_TXT["th"] = "ข้อเสนอแนะ"
HeaderSuggestion_TXT["en"] = "Suggestion"

export const HeaderAmountPayment_TXT = []
HeaderAmountPayment_TXT["th"] = "ระบุจำนวนเงิน"
HeaderAmountPayment_TXT["en"] = "Fill Amount"

export const HeaderGallery_TXT = []
HeaderGallery_TXT["th"] = "อัลบั้มรูป"
HeaderGallery_TXT["en"] = "Gallery"

export const HeaderPlan_TXT = []
HeaderPlan_TXT["th"] = "แบบแปลน"
HeaderPlan_TXT["en"] = "Plan"

export const HeaderMap_TXT = []
HeaderMap_TXT["th"] = "แผนที่"
HeaderMap_TXT["en"] = "Map"

export const HeaderAdvertise_TXT = []
HeaderAdvertise_TXT["th"] = "สื่อโฆษณา"
HeaderAdvertise_TXT["en"] = "Advertise"

export const HeaderInfo_TXT = []
HeaderInfo_TXT["th"] = "รายละเอียดเพิ่มเติม"
HeaderInfo_TXT["en"] = "Information"

export const HeaderProfileProject_TXT = []
HeaderProfileProject_TXT["th"] = "โครงการที่เป็นเจ้าของ"
HeaderProfileProject_TXT["en"] = "Project List"

export const HeaderProfileDetail_TXT = []
HeaderProfileDetail_TXT["th"] = "ข้อมูลส่วนตัว"
HeaderProfileDetail_TXT["en"] = "Profile"


export const HeaderPolicy_TXT = []
HeaderPolicy_TXT["th"] = "นโยบายความเป็นส่วนตัว"
HeaderPolicy_TXT["en"] = "Policy"

export const HeaderTerms_TXT = []
HeaderTerms_TXT["th"] = "เงื่อนไข"
HeaderTerms_TXT["en"] = "Terms"

export const HeaderAddressDelivery_TXT = []
HeaderAddressDelivery_TXT["th"] = "ที่อยู่จัดส่งเอกสาร"
HeaderAddressDelivery_TXT["en"] = "Address To Send Document"

export const HeaderContactNiti_TXT = []
HeaderContactNiti_TXT["th"] = "ข้อมูลนิติบุคคล"
HeaderContactNiti_TXT["en"] = "Contact Niti"

export const HeaderRegulation_TXT = []
HeaderRegulation_TXT["th"] = "ระเบียบการอยู่อาศัย"
HeaderRegulation_TXT["en"] = "Regulations"

export const Bank_Select_Header_TXT = []
Bank_Select_Header_TXT["en"] = "Bank Selecting"
Bank_Select_Header_TXT["th"] = "เลือกธนาคาร"

export const CreditCard_Select_Header_TXT = []
CreditCard_Select_Header_TXT["en"] = "Credit Card Selecting"
CreditCard_Select_Header_TXT["th"] = "เลือกประเภทบัตรเครดิต"

export const HeaderPaid_TXT = []
HeaderPaid_TXT["en"] = "Pay Bills"
HeaderPaid_TXT["th"] = "ชำระเงิน"

export const HeaderDefectDoc_TXT = []
HeaderDefectDoc_TXT["en"] = "Defect Report Before Transfer"
HeaderDefectDoc_TXT["th"] = "รายงาน defect ก่อนโอน"

export const HeaderEquipment_TXT = []
HeaderEquipment_TXT["en"] = "Deliver The Equipment"
HeaderEquipment_TXT["th"] = "ส่งมอบอุปกรณ์"

export const HeaderInspect_TXT = []
HeaderInspect_TXT["en"] = "Home Inspection"
HeaderInspect_TXT["th"] = "ตรวจบ้าน"

export const HeaderEtc_TXT = []
HeaderEtc_TXT["en"] = "Other Services"
HeaderEtc_TXT["th"] = "บริการอื่นๆ"

export const HeaderManual_TXT = []
HeaderManual_TXT["en"] = "Manual / Useful information"
HeaderManual_TXT["th"] = "คู่มือ / สาระน่ารู้"

export const HeaderTransfer_TXT = []
HeaderTransfer_TXT["en"] = "Transfer Ownership"
HeaderTransfer_TXT["th"] = "โอนกรรมสิทธิ์"

export const HeaderTransferInfo_TXT = []
HeaderTransferInfo_TXT["en"] = "Ownership Transfer Process Documents"
HeaderTransferInfo_TXT["th"] = "เอกสารขั้นตอนการโอนกรรมสิทธิ์"

export const HeaderExpenseOnDay_TXT = []
HeaderExpenseOnDay_TXT["en"] = "Expenses On Transfer Day"
HeaderExpenseOnDay_TXT["th"] = "ค่าใช้จ่ายวันโอน"

export const HeaderLetter_TXT = []
HeaderLetter_TXT["en"] = "Transfer Appointment Letter"
HeaderLetter_TXT["th"] = "จดหมายนัดโอน"
