import axios from 'axios'


export const selectLanguage = async (data) => {
    try {

        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/selectlanguage`,
            data: {
                "company_id": data.company_id,
                "company_id_pm": data.company_id_pm,
                "company_id_rental": data.company_id_rental,
                "language": data.language,
                "line_id": data.line_id,
                "name": data.name
            }
        })

        return result

    } catch (error) {
        return error
    }

}