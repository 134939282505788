export const Label_Name_TXT = []
Label_Name_TXT["en"] = "Full Name"
Label_Name_TXT["th"] = "ชื่อ-นามสกุล"

export const Label_ฺBank_Branch_TXT = []
Label_ฺBank_Branch_TXT["en"] = "Bank Branch"
Label_ฺBank_Branch_TXT["th"] = "สาขาธนาคาร"

export const Label_ฺBank_Type_TXT = []
Label_ฺBank_Type_TXT["en"] = "Bank Type"
Label_ฺBank_Type_TXT["th"] = "ประเภทธนาคาร"

export const Label_Date_Approve_TXT = []
Label_Date_Approve_TXT["en"] = "Approval Date"
Label_Date_Approve_TXT["th"] = "วันที่อนุมัติ"

export const Label_Credit_Limit_TXT = []
Label_Credit_Limit_TXT["en"] = "Approved Credit Limit"
Label_Credit_Limit_TXT["th"] = "วงเงินที่อนุมัติ"

export const Title_Info_Customer_TXT = []
Title_Info_Customer_TXT["en"] = "Customer Information"
Title_Info_Customer_TXT["th"] = "ข้อมูลลูกค้า"

export const Title_Info_Loan_TXT = []
Title_Info_Loan_TXT["en"] = "Loan Application Information"
Title_Info_Loan_TXT["th"] = "ข้อมูลขอสินเชื่อ"