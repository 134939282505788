import {
    Stack, Card, Container, Typography,
    Box, Tabs, Tab, CardContent, Fab,
    Button, Chip, Slide, Input, CircularProgress, Backdrop
} from '@mui/material'
import React from 'react';
import { Add, HolidayVillage, Search, Close } from '@mui/icons-material';
import { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../api'
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';
import { BlankPage, ModalError } from '../../ui-component';
import { StatusTag } from './StatusTag';
import moment from 'moment';
import { ChipRoomInfo } from '../../ui-component/ChipRoomInfo';
export const Defect = (props) => {

    const [tabSelected, setTabSelected] = useState("waiting")
    const [defectList, setDefectList] = useState([])
    const [triggerSearch, setTriggerSearch] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null);
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const [keyword, setKeyword] = useState("");
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()

    const loadDataList = async () => {
        setLoadingList(true)
        let res = await api.getAllDefectList({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "keyword": keyword,
            "version": props?.version
        })

        if (res.status === 200) {
            setDefectList(res.data?.data)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        return
    }

    const loadDataHistory = async () => {
        setLoadingList(true)
        let res = await api.getDefectHistory({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "keyword": keyword,
            "version": props?.version
        })

        if (res.status === 200) {
            setDefectList(res.data.data)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        return
    }

    const loadDetail = async (defect_id) => {

        let res = await api.getDefectDetail({
            "line_id": profile?.line_id,
            "defect_id": defect_id,
            "project_id": houseInfo?.project_id,
            "house_number": houseInfo?.house_number,
            "product_type": houseInfo?.product_type,
            "version": props?.version
        })

        return res?.data
    }


    const handleClickDetail = async (defect_id) => {

        let data =  await loadDetail(defect_id)

        navigate('/defect/defectDetail' + location.search, {
            state: {
                ...data?.data,
                version: props?.version
            }
        })
        return

    }

    const handleSelectedTab = () => {
        window.scrollTo(0, 0);
        setDefectList([])
        if (tabSelected === "history") {
            return loadDataHistory()
        }

        return handleChangeHouseInfo()
    }

    const handleChangeHouseInfo = () => {
        if (houseInfo == null) {
            return
        }

        return loadDataList()
    }


    useEffect(() => {
        handleSelectedTab()

    }, [tabSelected, houseInfo])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])

    useEffect(() => {
        let tabSession = sessionStorage.getItem("tabSelected")
        setTabSelected(tabSession || tabSelected)
    }, []);

    useEffect(() => {
        const apiCallTimeoutId = setTimeout(() => {
            if (houseInfo !== null) {
                handleSelectedTab()
            }
        }, 1000);
        return () => clearTimeout(apiCallTimeoutId);
    }, [keyword]);

    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js


    return (
        <>
            <Box style={{ minHeight: "100dvh" }} >
                <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 }}>

                    <Tabs
                        value={tabSelected}
                        onChange={(e, val) => {
                            sessionStorage.setItem('tabSelected', val)
                            setTabSelected(val)
                        }}
                        textColor="secondary"
                        indicatorColor="secondary"
                        centered
                        variant="fullWidth"
                        sx={{ backgroundColor: "white" }}
                    >
                        <Tab value="waiting" label={dict.Waiting_TXT[profile?.language]} />
                        <Tab value="history" label={dict.History_TXT[profile?.language]} />

                    </Tabs>
                </Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loadingList}
                >
                    <Stack alignItems={"center"} >
                        <CircularProgress color="inherit" />
                    </Stack>

                </Backdrop>

                <Container sx={{ minHeight: "100dvh", paddingBottom: 4 }} >
                    <Box sx={{ width: "100%", overflow: "hidden" }} >
                        <Stack direction="row" mt={8}   >
                            <Slide direction="right" in={!triggerSearch} mountOnEnter unmountOnExit>
                                <Stack sx={{ width: "100%" }} direction={"row"} alignItems="center" justifyContent={"space-between"}>
                                    <ChipRoomInfo
                                        houseInfo={houseInfo}
                                        icon={<HolidayVillage />}
                                        lang={profile?.language}
                                    />
                                    <Button size="small">
                                        <Search sx={{ fontSize: 35 }} onClick={() => setTriggerSearch(true)} />
                                    </Button>
                                </Stack>
                            </Slide >
                        </Stack>
                        <Slide direction="left" timeout={{ enter: 500, exit: 500 }} in={triggerSearch} mountOnEnter unmountOnExit>
                            <Box sx={{ width: "100%" }}>
                                <Input placeholder={dict.SearchDefect_TXT[profile?.language]} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                <Button size="small">
                                    <Close onClick={() => {
                                        setTriggerSearch(false)
                                        setKeyword("")
                                    }} />
                                </Button>
                            </Box>
                        </Slide >
                    </Box>
                    <Box mt={2}>
                        <Stack spacing={1}>
                            {
                                defectList?.length > 0 ? defectList?.map((element, i) => <Card key={i} sx={{ minWidth: 275, borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040" }}>
                                    <CardContent >
                                        <Stack direction="column"
                                            spacing={1}
                                        >
                                            <Typography fontSize={20} fontWeight={700} >
                                                {element.subject}
                                            </Typography>

                                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                                <Typography variant="caption" >
                                                    {dict.Status_TXT[profile?.language]}
                                                </Typography>
                                                <StatusTag text={element.status} />
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                                                <Typography variant="caption" >
                                                    {dict.Date_Create_Defect_TXT[profile?.language]}
                                                </Typography>
                                                <Typography fontSize={12} fontWeight={700} >
                                                    {moment(element.issue_date).format("DD/MM/YYYY")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row"
                                            justifyContent="center"
                                            mt={2}
                                        >
                                            <Button size="large"
                                                onClick={() => handleClickDetail(element.defect_id)}
                                                sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", width: "215.44px" }}>
                                                {dict.More_TXT[profile?.language]}
                                            </Button>
                                        </Stack>
                                    </CardContent>
                                </Card>) : <BlankPage lang={profile?.language}/>
                            }
                        </Stack>
                    </Box>
                    {
                        tabSelected === "waiting" && <Box
                            style={{
                                margin: 0,
                                top: 'auto',
                                right: 20,
                                bottom: 20,
                                left: 'auto',
                                position: 'fixed',
                            }}>
                            <Fab
                                variant="primary"
                                size='large'
                                color="secondary"
                                onClick={() => {
                                    navigate('/defect/formAdd' + location.search, { state: { version: props?.version } })
                                }}
                            >
                                <Add />

                            </Fab>
                        </Box>
                    }
                </Container>

            </Box>
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </>
    )
}