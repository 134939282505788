import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Typography, 
    Box
} from '@mui/material'
import { ProfileContext } from '../../../contexts/ProfileContext'
import * as api from '../../../api/Project'
import Carousel from "react-multi-carousel";
import { useLocation } from 'react-router-dom';
import { ZoomCarousel } from '../../../ui-component';
import { BackDropLoading } from '../../../ui-component';
import "react-multi-carousel/lib/styles.css";
import "../../../assets/css/style.css"
import * as dict from '../../../dictionary'
import { checkProductType } from '../../../utils/checkProductType';
export const CampaignDetail = (props) => {

    const [profile, setProfile] = useState(null)
    const [campaignDetail, setCampaignDetail] = useState(null)
    const [productType, setProductType] = useState(null)
    const [openCarousel, setOpenCarousel] = useState(false)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)

    const location = useLocation()
    const context = useContext(ProfileContext)
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    const loadCampaignDetail = async (id) => {
        setLoadingBackDrop(true)
        let res = await api.getPostDetail({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "id": id
        })

        setCampaignDetail(res?.data?.data)
        setLoadingBackDrop(false)

    }


    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    useEffect(() => {
        if (profile !== null && props?.isVisible === true) {

        }
    }, [profile, props?.isVisible]);

    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    useEffect(() => {
        if (location.state?.idSelected !== null) {
            loadCampaignDetail(location.state?.idSelected)
        }
    }, [location.state, productType]);
    return (
        <>
            <BackDropLoading
                loading={loadingBackDrop}
            />
            <Box sx={{ marginTop: 8 }}>
                <Carousel responsive={responsive}>
                    {
                        campaignDetail ? campaignDetail?.["gallery"].map(element => <div style={{ height: "200px" }} onClick={() => setOpenCarousel(true)}>
                            <img src={element["image1024"]} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                        </div>) : <div />
                    }
                </Carousel>
            </Box>
            <Box sx={{ backgroundColor: "white", padding: 3 }}>
                <Stack direction={"row"} alignItems={"flex-end"} spacing={2}>
                    <Typography variant={"caption"}>{dict.Announcement_TXT[profile?.language]}</Typography>

                    <Typography variant={"caption"}>{campaignDetail?.postdate}</Typography>
                    <Typography variant={"caption"}>{dict.Edit_On_TXT[profile?.language]} {campaignDetail?.lastupdate}</Typography>
                </Stack>
                <br />
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {campaignDetail?.title}
                </Typography>
                {
                    <div dangerouslySetInnerHTML={{ __html: (campaignDetail?.description) }} />
                }

            </Box>
            <ZoomCarousel
                open={openCarousel}
                onClose={() => setOpenCarousel(false)}
                gallery={campaignDetail?.["gallery"]}
            />
        </>
    )
}
