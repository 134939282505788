import axios from "axios"

export const getAboutPrivacy = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/about/privacy`,
            data: {
                "line_id": data.line_id,
            }
        })
        return res
    } catch (error) {
        return error
    }

}

export const getAboutTerm = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/about/term`,
            data: {
                "line_id": data.line_id,
            }
        })
        return res
    } catch (error) {
        return error
    }

}