import { Stack, Container, Box } from "@mui/material";
import * as api from "../../../api";
import { useState, useEffect, Fragment, useContext } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { ModalError, BackDropLoading , BlankPage, CardMenu} from "../../../ui-component";
export const LoanCPDocument = () => {
  const [loanCPDocument, setLoanCPDocument] = useState([]);
  const [profile, setProfile] = useState(null)
  const [houseInfo, setHouseInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalError, setModalError] = useState({ errorResponse: null, open: false });
  const context = useContext(ProfileContext)

  useEffect(() => {
    if (profile !== null) {

      const loadList = async () => {
        setLoading(true);
        let res = await api.getLoanCPDocument({
          line_id: profile?.line_id,
          product_type: houseInfo?.product_type,
          project_id: houseInfo?.project_id,
          type: "loanInfo",
        });

        if (res.status === 200) {
          setLoanCPDocument(res.data?.data?.post);
          setLoading(false);
          return;
        }
        setLoading(false)
        setModalError({ errorResponse: res, open: true })
        return;
      };
      loadList()
    }
  }, [profile, houseInfo]);

  useEffect(() => {
    if (context.state?.profile !== null) {
      setProfile(context.state?.profile?.customer[0])
    }

    if (context?.state.houseInfo) {
      setHouseInfo(context?.state?.houseInfo)
    }
  }, [context])

  return (
    <>
      <Box sx={{ height: "calc(100vh - 60px)" }}>
        <Container
          sx={{
            minHeight: "100%",
            paddingTop: 2,
            paddingBottom: 5,
          }}
        >
          <Stack mt={1} spacing={2}>
            {loanCPDocument?.length > 0 ? loanCPDocument?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <CardMenu
                    text={item?.[`title_${profile?.language}`]}
                    navigate={"detail"}
                    state={{ title: item?.[`title_${profile?.language}`], id: item?.id }}
                    iconImg={"pdfIcon"}
                  />
                </Fragment>
              );
            }) :  <BlankPage lang={profile?.language} />}
          </Stack>
        </Container>
      </Box>
      <ModalError
        onClose={() => setModalError({ errorResponse: null, open: false })}
        open={modalError.open}
        error={modalError.errorResponse}
      />
      <BackDropLoading
        loading={loading}
      />
    </>
  );
};
