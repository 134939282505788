import {
    Stack,
    Container,
    AppBar,
    Toolbar,
    Grid,
    Typography,
    Box,
} from '@mui/material'
import { useEffect, useState, useContext , useCallback} from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import * as dict from "../../dictionary"
import * as api from "../../api/Etc"
import { DialogPhone } from '../../ui-component/DialogPhone';
import {
    ModalError,
    BackDropLoading,
    CardMenu
} from '../../ui-component';
import { Language } from '../Language';
import MasterPage from '../../ui-component/MasterPage';
export const Etc = () => {
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [openDialogPhone, setOpenDialogPhone] = useState({ type: "", open: false })
    const [listPhone, setListPhone] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [isVisibleLanguage, setIsVisibleLanguage] = useState(false);
    const [userLanguage, setUserLanguage] = useState("th");
    const context = useContext(ProfileContext)
    let profile_lineOA = JSON.parse(localStorage.getItem("profile_lineOA"))
    // const [profileLinaOa] = useState(JSON.parse(profile_lineOA))
    
    const handleChooseLanguage = async (lang) => {
        setUserLanguage(lang);
        await loadPhoneNumber(profile_lineOA?.profile.userId)
    };

    const loadPhoneNumber = useCallback(async (userId) => {
        setLoading(true)
      
        let res = await api.getOther({
            "line_id": profile?.line_id || userId,
            "product_type": houseInfo?.product_type,
        })
        if (res?.status === 200) {
            setListPhone(res.data?.data)
            setLoading(false)
            return
        }
        setLoading(false)
        setModalError({ errorResponse: res, open: true })
        return
    },[houseInfo, profile])

    useEffect(() => {
        if (profile) {
            loadPhoneNumber()
        }

    }, [profile, loadPhoneNumber]);

    useEffect(() => {
        
        if (context.state?.profile !== null) {
            if(context.state?.profile?.customer.length > 0){
                setProfile(context.state?.profile?.customer[0])
            }else if(isVisibleLanguage === false) {
                setIsVisibleLanguage(true)
            }
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])

    return (
        <MasterPage>
            <div style={{ marginBottom: 60 }}>
                <AppBar sx={{
                    position: 'fixed',
                    boxShadow: 'none',
                    height: 60,
                }}>
                    <Toolbar>
                        <Grid container item direction="row"
                            justifyContent="center"
                        >
                            <Typography>
                                {dict.HeaderEtc_TXT[profile?.language || userLanguage]}
                            </Typography>

                        </Grid>

                    </Toolbar>


                </AppBar>
            </div>
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    {
                        profile_lineOA?.profile && <Stack mt={1} spacing={2}>
                            <CardMenu iconImg={"phoneIcon"} text={dict.Etc_Emergency_TXT[profile?.language || userLanguage]} onClick={() => setOpenDialogPhone({ type: "emergency", title: dict.Etc_Emergency_TXT[profile?.language || userLanguage], open: true })} />
                            <CardMenu iconImg={"phoneIcon"} text={"Call Center"} onClick={() => setOpenDialogPhone({ type: "call_center", title: "Call Center", open: true })} />
                            <CardMenu iconImg={"mailIcon"} text={dict.Etc_Website_TXT[profile?.language || userLanguage]} onClick={() => window.open(listPhone?.website.match(/^https?:/) ? listPhone?.website : '//' + listPhone?.website)} />
                        </Stack>
                    }

                </Container>
            </Box >
            <DialogPhone
                title={openDialogPhone.title}
                open={openDialogPhone.open}
                handleClose={() => setOpenDialogPhone(prev => ({ ...prev, open: false }))}
                list={listPhone[openDialogPhone.type]}
            />
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={!profile_lineOA || loading}
            />
            <Language
                isVisible={isVisibleLanguage}
                response={(lang) => handleChooseLanguage(lang)}
                onClose={() => setIsVisibleLanguage(false)}
                defaultLanguage={userLanguage}
            />
        </MasterPage>
    )

}