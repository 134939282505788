import * as dict from "../dictionary"
import { checkProductType } from "../utils/checkProductType"
import { Chip } from "@mui/material"
export const ChipRoomInfo = ({houseInfo, houseInfoParse, icon , lang , sx}) => {

    const getProjectName = () => {
        if(lang === "en"){
            return houseInfo?.project_name_en || houseInfoParse?.project_name_en
        }
        return houseInfo?.project_name || houseInfoParse?.project_name
    }

    const getRoomNo = () => {
        //if(checkProductType() === "rental"){
        return houseInfo?.unit_number || houseInfoParse?.unit_number
        //}
        //return houseInfo?.house_number || houseInfoParse?.house_number
    }

    const getRoomNoTitle = () => {
        if(checkProductType() === "rental"){
            return dict.UnitNo_TXT[lang]
        }
        return dict.Room_TXT[lang]
    }

    return <>
        <Chip sx={{ ...sx , maxWidth: "70dvw"}} label={
            `${dict.Project_TXT[lang]} : ${getProjectName() || '-'} | ${getRoomNoTitle()} : ${getRoomNo() || '-'}`
        } color="primary" icon={icon} />
    </>
}