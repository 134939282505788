import axios from "axios";

export const getRegulationList = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/regulations/list`,
            data: {
                "line_id": data.line_id,    
                "product_type": data.product_type,
                "project_id": data.project_id          
            }
        })
        return res

    } catch (error) {
        return error
    }
}

export const getRegulationDetail = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/regulations/detail`,
            data: {
                "line_id": data.line_id,    
                "product_type": data.product_type,
                "id": data.id          
            }
        })
        return res

    } catch (error) {
        return error

    }
}