import React, { useEffect, useState, useContext } from 'react'
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import {
    Stack, Card,
    Typography, Grid, CardMedia, Container,
    Box, Dialog
} from '@mui/material'
import QRCode from 'react-qr-code'
import * as dict from '../../dictionary/Parcel'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ProfileContext } from '../../contexts/ProfileContext';
import {  useLocation } from "react-router-dom";

export const ParcelDetail = () => {
    const [viewImg, setViewImg] = useState(false)
    const [viewQrcode, setViewQrcode] = useState(false)
    const [detail, setDetail] = useState(null)
    const [profile, setProfile] = useState(null)
    const { state } = useLocation()
    const context = useContext(ProfileContext)


    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])

    useEffect(() => {
        setDetail(state)
    }, [state])

    return (
        <div style={{ height: "100vh", backgroundColor: "#F2F3F4" }}>
            <Container maxWidth="xl" sx={{ marginTop: 8 }}>

                <Card
                    variant="outlined"
                    sx={{ borderRadius: "10px" }}

                >
                    <Zoom>
                        <CardMedia
                            component="img"
                            sx={{
                                height: 300,
                                objectFit: "contain"
                            }}
                            src={detail?.postboxImage}
                        />
                    </Zoom>
                    <Box sx={{ padding: 1 }}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            <Typography variant="body1" >
                                {detail?.postboxID}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"center"}>
                            {
                                detail?.postboxStatus === "waiting" &&
                                <Typography variant="body1" >
                                    {dict.SendTo_TXT[profile?.language]} : {detail?.ownerName}
                                </Typography>
                            }
                        </Stack>
                        <Stack direction={"row"} justifyContent={"center"}>
                            <Typography variant="caption" >
                                {dict.Type_TXT[profile?.language]}: {detail?.postboxTypeText}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"center"}>
                            <Typography variant="caption" >
                                {dict.DateReceived_TXT[profile?.language]} {detail?.createDate}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
                <Grid
                    container mt={5}
                    rowSpacing={1}
                >
                    {detail?.postboxStatus === 'received' && <>
                        <Grid item xs={6} >
                            <Typography variant="body1" >
                                {dict.OwnerDateReceived_TXT[profile?.language]} :
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" >
                                {detail?.receivedDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="body1" >
                                {dict.SendTo_TXT[profile?.language]} :
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" >
                                {detail?.ownerName}
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="body1" >
                                {dict.Sender_TXT[profile?.language]} :
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="body1" >
                                {detail?.staffName}
                            </Typography>

                        </Grid>
                    </>}
                </Grid>

                {detail?.postboxStatus === "waiting" &&
                    <Box>
                        <Stack direction="column"
                            justifyContent="center"
                            alignItems={"center"}
                            spacing={3}
                        >
                            <Typography variant="body1" >
                                {dict.Tip_TXT[profile?.language]}
                            </Typography>
                            <Stack direction="row" justifyContent="center" alignItems={"center"}>
                                <QRCode value={detail?.postboxID} size={150} onClick={() => setViewQrcode(true)} />
                            </Stack>
                        </Stack>
                    </Box>

                }

                {
                    detail?.postboxStatus === 'received' &&
                    <>
                        <Stack direction={"column"} mt={2} sx={{ alignItems: "center" }}>
                            <CheckCircleOutlineOutlined sx={{ fontSize: 80, color: "#039487" }} />
                            <Typography variant="h6">
                                {dict.Confirm_TXT[profile?.language]}
                            </Typography>
                        </Stack>

                    </>

                }
                <Dialog
                    open={viewImg}
                    onClose={() => setViewImg(false)}

                >
                    <CardMedia
                        component="img"

                        src={detail?.postboxImage}

                    />
                </Dialog>
                <Dialog
                    open={viewQrcode}
                    onClose={() => setViewQrcode(false)}
                >
                    <Box >
                        <QRCode value={detail?.postboxQR} />
                    </Box>

                </Dialog>
            </Container>
        </div>
    )
}
