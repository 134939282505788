export const Step1_TXT = [];
Step1_TXT["en"] = "The system is checking the information";
Step1_TXT["th"] = "ระบบกำลังตรวจสอบข้อมูล";

export const Step2_TXT = [];
Step2_TXT["en"] = "registration is complete";
Step2_TXT["th"] = "การลงทะเบียนเสร็จสิ้น";

export const Step3_TXT = [];
Step3_TXT["en"] =
  "registration is complete The system is taking you back to the Line Messenger page.";
Step3_TXT["th"] =
  "การลงทะเบียนเสร็จสิ้น ระบบกำลังพาท่านกลับไปหน้า Line Messenger";

export const Step4_TXT = [];
Step4_TXT["en"] = "An error occurred while registering.";
Step4_TXT["th"] = "เกิดข้อผิดพลาดขณะลงทะเบียน";

export const Otp_no_email_input_TXT = [];
Otp_no_email_input_TXT["en"] =
  "Please enter your email address before sending the verification code.";
  Otp_no_email_input_TXT["th"] = "กรุณากรอกอีเมลก่อนส่งรหัสยืนยัน";

export const Otp_no_phone_input_TXT = [];
Otp_no_phone_input_TXT["en"] =
  "Please enter your phone number before sending the verification code.";
  Otp_no_phone_input_TXT["th"] = "กรุณากรอกเบอร์โทรศัพท์ก่อนส่งรหัสยืนยัน";

export const Otp_no_input_TXT = [];
Otp_no_input_TXT["en"] =
  "You haven't entered OTP, please press send verification code button.";
Otp_no_input_TXT["th"] = "คุณยังไม่ได้กรอกรหัส OTP กรุณากดปุ่มส่งรหัสยืนยัน";

export const Otp_no_verify_TXT = [];
Otp_no_verify_TXT["en"] =
  "The OTP code you entered is invalid, unable to register.";
Otp_no_verify_TXT["th"] = "รหัส OTP ที่คุณกรอกไม่ถูกต้องไม่สามารถลงทะเบียนได้";

export const Otp_expired_TXT = [];
Otp_expired_TXT["en"] =
  "Your OTP code has expired. Please resend the verification code again.";
Otp_expired_TXT["th"] =
  "รหัส OTP ของคุณหมดอายุแล้ว กรุณาส่งรหัสยืนยันใหม่อีกครั้ง";

export const is_card_invalid_TXT = [];
is_card_invalid_TXT["en"] = "Can't find your ID number Please check again.";
is_card_invalid_TXT["th"] = "ไม่พบเลขบัตรประชาชนของคุณ กรุณาตรวจสอบอีกครั้ง";

export const Registration_TXT = [];
Registration_TXT["en"] = "Registration";
Registration_TXT["th"] = "ลงทะเบียน";

export const OTP_Type_Success_TXT = [];
OTP_Type_Success_TXT["en"] =
  "The system has sent an OTP code to your mobile phone.";
OTP_Type_Success_TXT["th"] = "ระบบได้ส่งรหัส OTP ไปยังโทรศัพท์มือถือของคุณแล้ว";

export const OTP_Type_Error_TXT = [];
OTP_Type_Error_TXT["en"] = "Error sending OTP code.";
OTP_Type_Error_TXT["th"] = "เกิดข้อผิดพลาดในการส่งรหัส OTP";

export const Agreed_Policy_TXT = [];
Agreed_Policy_TXT["en"] = "Agreement";
Agreed_Policy_TXT["th"] = "ยินยอมข้อตกลง";

export const Please_Input_Citizen_TXT = [];
Please_Input_Citizen_TXT["en"] = "Please enter your ID card number.";
Please_Input_Citizen_TXT["th"] = "กรุณาระบุเลขบัตรประชาชน";

export const Citizen_Or_Passport_TXT = [];
Citizen_Or_Passport_TXT["en"] = "Citizen ID*";
Citizen_Or_Passport_TXT["th"] = "เลขประจำตัวประชาชน*";

export const Input_MobileNumber_TXT = [];
Input_MobileNumber_TXT["en"] = "Please enter your phone number.";
Input_MobileNumber_TXT["th"] = "กรุณากรอกเบอร์โทรศัพท์";

export const Phone_Number_TXT = [];
Phone_Number_TXT["en"] = "Phone Number";
Phone_Number_TXT["th"] = "หมายเลขโทรศัพท์";

export const Send_OTP_TXT = [];
Send_OTP_TXT["en"] = "Send OTP";
Send_OTP_TXT["th"] = "ส่งรหัสยืนยัน";

export const Fill_OTP_Txt = [];
Fill_OTP_Txt["en"] = "Enter the verification code.";
Fill_OTP_Txt["th"] = "ระบุรหัสยืนยัน";

export const Fill_OTP_Helper_Txt = [];
Fill_OTP_Helper_Txt["en"] =
  "Please provide the verification code sent to your phone number.";
Fill_OTP_Helper_Txt["th"] =
  "โปรดระบุรหัสยืนยันที่ส่งไปยังหมายเลขโทรศัพท์ของท่าน";

export const GO_TO_AGREE_TXT = [];
GO_TO_AGREE_TXT["en"] = "go to agreement page";
GO_TO_AGREE_TXT["th"] = "ไปหน้ายินยอมข้อตกลง";

export const Email_OTP_TXT = [];
Email_OTP_TXT["en"] = "Email Address";
Email_OTP_TXT["th"] = "อีเมล";

export const Input_Email_TXT = [];
Input_Email_TXT["en"] = "Please enter your email address.";
Input_Email_TXT["th"] = "กรุณากรอกอีเมล";

export const Contact_Type_TXT = [];
Contact_Type_TXT["en"] = "Customer Type";
Contact_Type_TXT["th"] = "ประเภทลูกค้า";

export const Contact_Type_Thai_TXT = [];
Contact_Type_Thai_TXT["en"] = "Thai";
Contact_Type_Thai_TXT["th"] = "ไทย";

export const Contact_Type_Foreigner_TXT = [];
Contact_Type_Foreigner_TXT["en"] = "Foreigner";
Contact_Type_Foreigner_TXT["th"] = "ต่างประเทศ";

export const Please_Input_Passport_TXT = [];
Please_Input_Passport_TXT["en"] = "Please enter your passport number.";
Please_Input_Passport_TXT["th"] = "กรุณาระบุเลขพาสปอร์ต";

export const Passport_TXT = [];
Passport_TXT["en"] = "Passport Number*";
Passport_TXT["th"] = "เลขพาสปอร์ต*";

export const is_passport_invalid_TXT = [];
is_passport_invalid_TXT["en"] =
  "Can't find your passport number, Please check again.";
is_passport_invalid_TXT["th"] = "ไม่พบเลขพาสปอร์ตของคุณ กรุณาตรวจสอบอีกครั้ง";

export const Email_Fill_OTP_Helper_Txt = [];
Email_Fill_OTP_Helper_Txt["en"] =
  "Please provide the verification code sent to your email.";
Email_Fill_OTP_Helper_Txt["th"] = "โปรดระบุรหัสยืนยันที่ส่งไปยังอีเมลของท่าน";
