export const Contact_TXT = []
Contact_TXT["en"] = "Contact"
Contact_TXT["th"] = "ติดต่อ"


export const Tel_TXT = []
Tel_TXT["en"] = "Tel"
Tel_TXT["th"] = "โทร"

export const LineOA_TXT = []
LineOA_TXT["en"] = "Line Official Account or Line@ of a juristic person"
LineOA_TXT["th"] = "Line Official Account หรือ Line@ ของนิติบุคคล"

export const Primary_Email_Juristic_TXT = []
Primary_Email_Juristic_TXT["en"] = "Email of juristic person"
Primary_Email_Juristic_TXT["th"] = "อีเมลหลัก ของนิติบุคคล"