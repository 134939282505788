import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  CardMedia
} from '@mui/material';
import logoNext from '../../assets/images/logo/next.png'
import logoBay from '../../assets/images/logo/banks/bay.png'
import logoBbl from '../../assets/images/logo/banks/bbl.png'
import logoKbank from '../../assets/images/logo/banks/kbank.png'
import logoKtb from '../../assets/images/logo/banks/ktb.png'
import logoScb from '../../assets/images/logo/banks/scb.png'

import { useNavigate, useLocation } from 'react-router-dom';

export const BankSelecting = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const handleSelect = (choiceSelected) => {
    navigate('/billpayment/amountPayment' + location.search, {
      state: { ...location.state , choiceSelected}
    })
  }

  return (
    <>
      <List sx={{

      }}>
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        } sx={{ height: 70 }}
          onClick={() => handleSelect("KBANK")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoKbank}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1}} primary="K Plus" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("SCB")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoScb}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1}} primary="SCB Easy" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("KTB")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoKtb}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1}} primary="Krungthai NEXT" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("BBL")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoBbl}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1}} primary="Bualuang mBanking" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("BAY")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoBay}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1}} primary="KMA" />
        </ListItem>
        <Divider light />
      </List>
    </>
  )
}
