import React, { useState, useEffect, useContext } from 'react'
import {
    Stack, Card, Typography, Box, Dialog, DialogTitle, Button, Chip, DialogContent, DialogActions
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileContext } from '../../contexts/ProfileContext'
import { CardResponse } from '../../ui-component';
import * as api from '../../api/'
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType';
export const Booking = () => {
    const [profile, setProfile] = useState(null)
    const [productType, setProductType] = useState(null)
    const [detail, setDetail] = useState(null)
    const [isDialogCancel, setIsDialogCancel] = useState(false)
    const [alertDialog, setAlertDialog] = useState({
        typeResponse: "success",
        titleResponse: "",
        trigger: false
    })
    const context = useContext(ProfileContext)
    const navigate = useNavigate()
    const location = useLocation()
    const loadDetail = async () => {
        let res = await api.getFacilityBookingDetail({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "booking_id": location.state?.bookingId
        })
        setDetail(res.data?.body)
    }
    const handleResponse = (type) => {
        if (type === "ยกเลิก") {
            return setAlertDialog({
                typeResponse: "success",
                titleResponse: `${dict.System_Save_Cancel_TXT[profile?.language]}`,
                trigger: true
            })
        }
        return setAlertDialog({
            typeResponse: "success",
            titleResponse: `${dict.System_Save_Access_TXT[profile?.language]}`,
            trigger: true
        })
    }
    const handleDialogSuccessClose = () => {
        setAlertDialog({
            typeResponse: "success",
            titleResponse: "",
            trigger: false
        })
        navigate(-1)
    }
    const handleDialogCancel = (type) => {
        if (type === "ตกลง") {
            handleCancel()
        }
        return setIsDialogCancel(false)
    }
    const handleCancel = async () => {
        let res = await api.cancelFacility({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "booking_id": detail?.bookingId
        })
        let resultCode = res.data?.header?.resultcode
        if (resultCode === 200) {
            handleResponse("ยกเลิก")
            return
        }
    }
    const handleConfirm = async () => {
        let res = await api.confirmFacility({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "booking_id": detail?.bookingId
        })
        let resultCode = res.data?.header?.resultcode
        if (resultCode === 200) {
            handleResponse("ยืนยันการเข้าใช้งาน")
            return
        }
    }
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])
    useEffect(() => {
        setProductType(checkProductType())
    }, [])
    useEffect(() => {
        if(productType !== null){
             loadDetail()
        }
    }, [productType]);
    return (
        <>
            <Card variant="outlined" sx={{
                width: "100%",
                display: "flex",
                height: "200px",
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: 8,
                alignItems: "flex-end",
                justifyContent: "center"
            }}>
                <Box sx={{
                    backgroundImage: `url(${detail?.facilitiesImage})`,
                    height: "100%",
                    width: "85%",
                    backgroundSize: "cover",
                    borderRadius: "10px",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                }}>

                </Box>
            </Card>
            <Box sx={{ padding: 5 }} >
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                    <Chip label={detail?.statusText} color={"primary"} variant="filled" />
                </Stack>
                <Typography variant={"h5"}>
                    {detail?.facilitiesName}
                </Typography>
                <Typography variant={"body1"}>
                    {detail?.projectName}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"body2"}>
                        {dict.Date_TXT[profile?.language]}
                    </Typography>
                    <Typography variant={"body2"}>
                        {detail?.bookingDate}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"body2"}>
                        {dict.Period_TXT[profile?.language]}
                    </Typography>
                    <Typography variant={"body2"}>
                        {detail?.bookingStart} - {detail?.bookingEnd}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant='body2'>
                        {dict.Booking_Code_TXT[profile?.language]}
                    </Typography>
                    <Typography variant='body2'>
                        {detail?.bookingId}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} mt={3}>
                    <Button variant={"contained"} color="primary" onClick={() => handleConfirm()}>
                        <Typography variant={"h6"}>{dict.Confirm_Access_TXT[profile?.language]}</Typography>
                    </Button>
                </Stack>
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} mt={3}>
                    <Button variant={"text"} onClick={() => setIsDialogCancel(true)}>
                        <Typography variant={"body1"} color="#585858" >{dict.Cancel_Booking_TXT[profile?.language]}</Typography>
                    </Button>
                </Stack>
            </Box>
            <Dialog open={isDialogCancel} onClose={() => setIsDialogCancel(false)}>
                <DialogTitle>
                    {dict.Are_you_sure_TXT[profile?.language]}
                </DialogTitle>
                <DialogContent>
                    {dict.Cancel_Booking_Alert_TXT[profile?.language]}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogCancel("ยกเลิก")}>{dict.CancelBtn_TXT[profile?.language]}</Button>
                    <Button onClick={() => handleDialogCancel("ตกลง")} autoFocus>
                        {dict.AcceptBtn_TXT[profile?.language]}
                    </Button>
                </DialogActions>
            </Dialog>
            <CardResponse
                type={alertDialog.typeResponse}
                titleDescription={alertDialog.titleResponse}
                open={alertDialog.trigger}
                onClose={() => {
                    handleDialogSuccessClose()
                }}
                onOk={() => handleDialogSuccessClose()}
            />
        </>
    )
}
