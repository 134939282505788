import axios from "axios";

export const getUnitDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/units/detail`,
            data: {
                "unit_id": data.unit_id,
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }
}


export const getUnitDetailSellingPrice = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/units/price`,
            data: {
                "unit_id": data.unit_id,
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getUnitDoc = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/units/doc_handling`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "contract_id": data.contract_id,
                "process": data.process,
                "doc_name": data.doc_name
            }
        })
        return result

    } catch (error) {
        return error
    }
}