export const Date_Status_TXT = []
Date_Status_TXT["en"] = "Status Date"
Date_Status_TXT["th"] = "วันที่สถานะ"

export const Loan_Status_TXT = []
Loan_Status_TXT["en"] = "Status"
Loan_Status_TXT["th"] = "สถานะ"

export const Loan_Status_Customer_TXT = []
Loan_Status_Customer_TXT["en"] = "Customer Status"
Loan_Status_Customer_TXT["th"] = "สถานะลูกค้า"

export const Loan_Result_TXT = []
Loan_Result_TXT["en"] = "Loan Result"
Loan_Result_TXT["th"] = "ผลการขอสินเชื่อ"

export const Loan_RoomPlan_No_TXT = []
Loan_RoomPlan_No_TXT["en"] = "No./Area"
Loan_RoomPlan_No_TXT["th"] = "เลขที่แปลง/ห้อง"

export const Loan_Contract_No_TXT = []
Loan_Contract_No_TXT["en"] = "Contract No."
Loan_Contract_No_TXT["th"] = "เลขที่สัญญา"

export const Loan_Type_TXT = []
Loan_Type_TXT["en"] = "Loan Type"
Loan_Type_TXT["th"] = "ประเภทการขอสินเชื่อ"

export const Loan_Bank_TXT = []
Loan_Bank_TXT["en"] = "Bank Name"
Loan_Bank_TXT["th"] = "ชื่อธนาคาร"

export const Loan_Bank_Info_TXT = []
Loan_Bank_Info_TXT["en"] = "Bank Info"
Loan_Bank_Info_TXT["th"] = "ข้อมูลธนาคาร"


export const Loan_Contact_TXT = []
Loan_Contact_TXT["en"] = "Staff Name"
Loan_Contact_TXT["th"] = "ชื่อเจ้าหน้าที่ติดต่อ"

export const Loan_Phone_TXT = []
Loan_Phone_TXT["en"] = "Phone Number"
Loan_Phone_TXT["th"] = "เบอร์โทรศัพท์"

export const Loan_Amount_TXT = []
Loan_Amount_TXT["en"] = "Loan Amount"
Loan_Amount_TXT["th"] = "วงเงินกู้"

export const Loan_Amount_Req_TXT = []
Loan_Amount_Req_TXT["en"] = "Amount Requested For Loan"
Loan_Amount_Req_TXT["th"] = "วงเงินที่ขอกู้"

export const Loan_Approval_Total_TXT = []
Loan_Approval_Total_TXT["en"] = "Total Approved Loan Amount"
Loan_Approval_Total_TXT["th"] = "วงเงินกู้ที่อนุมัติรวมทั้งหมด"

export const Loan_Home_TXT = []
Loan_Home_TXT["en"] = "Home Loan Amount"
Loan_Home_TXT["th"] = "วงเงินกู้ค่าบ้าน"

export const Loan_Life_Insurance_TXT = []
Loan_Life_Insurance_TXT["en"] = "Life Insurance Premiums"
Loan_Life_Insurance_TXT["th"] = "เบี้ยประกันชีวิต"

export const Loan_Fire_Insurance_TXT = []
Loan_Fire_Insurance_TXT["en"] = "Fire Insurance Premium"
Loan_Fire_Insurance_TXT["th"] = "เบี้ยประกันอัคคีภัย"

export const Loan_Multi_Credit_TXT = []
Loan_Multi_Credit_TXT["en"] = "Multi-purpose Credit Limit"
Loan_Multi_Credit_TXT["th"] = "วงเงินอเนกประสงค์"

export const Loan_Decoration_Cost_TXT = []
Loan_Decoration_Cost_TXT["en"] = "Decoration Cost Limit"
Loan_Decoration_Cost_TXT["th"] = "วงเงินค่าตกแต่ง"

export const Loan_Limit_Cost_Percent_TXT = []
Loan_Limit_Cost_Percent_TXT["en"] = "Home payment approval limit/loan request limit"
Loan_Limit_Cost_Percent_TXT["th"] = "วงเงินอนุมัติค่าบ้าน/วงเงินที่ขอกู้"

export const Loan_Approval_Cost_Percent_TXT = []
Loan_Approval_Cost_Percent_TXT["en"] = "Total approved loan limit/loan requested limit"
Loan_Approval_Cost_Percent_TXT["th"] = "วงเงินกู้ที่อนุมัติรวมทั้งหมด/วงเงินที่ขอกู้"

export const Loan_Expiration_TXT = []
Loan_Expiration_TXT["en"] = "Expiration Date"
Loan_Expiration_TXT["th"] = "วันที่หมดอายุ"

export const Loan_Process_TXT = []
Loan_Process_TXT["en"] = "In the process of applying for a loan"
Loan_Process_TXT["th"] = "อยู่ระหว่างการขอสินเชื่อ"

export const Loan_Additional_TXT = []
Loan_Additional_TXT["en"] = "In case of additional inquiries, please contact the bank directly."
Loan_Additional_TXT["th"] = "ในกรณีที่ต้องการสอบถามเพิ่มเติมกรุณาติดต่อธนาคารโดยตรง"

export const Loan_Doc_Consider_TXT = []
Loan_Doc_Consider_TXT["en"] = "Documents For Consideration"
Loan_Doc_Consider_TXT["th"] = "เอกสารประกอบการพิจารณา"

export const Loan_Status_Menu_TXT = []
Loan_Status_Menu_TXT["en"] = "Loan Status"
Loan_Status_Menu_TXT["th"] = "สถานะสินเชื่อ"

export const Loan_Calculation_TXT = []
Loan_Calculation_TXT["en"] = "Loan Calculation"
Loan_Calculation_TXT["th"] = "ตารางคำนวณสินเชื่อ"

export const Amount_To_Borrower_TXT = []
Amount_To_Borrower_TXT["en"] = "Amount to Borrow / Baht"
Amount_To_Borrower_TXT["th"] = "วงเงินที่ต้องการกู้ / บาท"

export const Interest_Rate_TXT = []
Interest_Rate_TXT["en"] = "Interest Rate"
Interest_Rate_TXT["th"] = "อัตราดอกเบี้ย"

export const Period_Time_TXT = []
Period_Time_TXT["en"] = "Period"
Period_Time_TXT["th"] = "ระยะเวลา"

export const Year_TXT = []
Year_TXT["en"] = "Year"
Year_TXT["th"] = "ปี"

export const Monthly_Payment_Amount_TXT = []
Monthly_Payment_Amount_TXT["en"] = "Monthly Payment Amount"
Monthly_Payment_Amount_TXT["th"] = "ยอดชำระต่อเดือน"

export const Maximun_Loan_Amount_TXT = []
Maximun_Loan_Amount_TXT["en"] = "Maximum Loan Amount"
Maximun_Loan_Amount_TXT["th"] = "วงเงินกู้สูงสุด"

export const Loan_Customer_Choose_TXT = []
Loan_Customer_Choose_TXT["en"] = "Customer Choose"
Loan_Customer_Choose_TXT["th"] = "ลูกค้าเลือก"

export const SearchLoan_TXT = []
SearchLoan_TXT["en"] = "Search"
SearchLoan_TXT["th"] = "ค้นหารายการสินเชื่อ"