import {
    Stack, 
    Container,
    Button,
    Box,
    styled,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useContext } from 'react'
import { CarouselCustom } from './CarouselCustom';
import { ProfileContext } from '../contexts/ProfileContext';
import * as dict from '../dictionary/DetailCP'
export const DetailCP = ({ data }) => {

    const theme = useTheme()
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const context = useContext(ProfileContext)


    useEffect(() => {
        if (profile !== null) {
            //do something
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])
    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>

                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    <Stack mt={1} spacing={2}>

                        <CarouselCustom images={data?.file_gallery} />

                    </Stack>
                    <br />
                    <Typography variant='h5' fontWeight={"bold"} color={theme.palette.primary.main}>
                        {data[`title_${profile?.language}`]}
                    </Typography>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: data[`html_${profile?.language}`] }} style={{ maxHeight: "500px", overflow: "scroll" }} />
                    <Stack direction={"column"} spacing={1} sx={{ p: 2 }} mt={1} >
                        <ButtonDownload variant="outlined" onClick={() => window.open(data?.url_link)}>
                            <Typography variant='body2'>{dict.Detail_CP_More_TXT[profile?.language]}</Typography>
                        </ButtonDownload>
                    </Stack>
                </Container>

            </Box >


        </>
    )

}

const ButtonDownload = styled(Button)(({ theme }) => ({
    width: "100%",
    height: "36px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
    backgroundColor: theme.palette.primary.main, 
    color: theme.palette.primary.contrastText,
    "&:hover":{
        backgroundColor: theme.palette.primary.main
    }
}))