import axios from "axios";

export const getAllByUser = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/parcel`,
            data: {
                "line_id":  data.line_id,
                "language": data.language,
                "project":  data.project,
                "house_number":  data.house_number,
                "status":  data.status,
                "page":  "1" || data.page,
                "product_type":  data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }

}

export const getAllHistoryByUser = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/parcel/history`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "project": data.project,
                "house_number": data.house_number,
                "status": data.status,
                "page": "1" || data.page,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }

}