import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Typography,
    Box, 
    Tabs, 
    Tab, 
    Paper, 
    Grid
} from '@mui/material'
import { ProfileContext } from '../../../contexts/ProfileContext'
import * as api from '../../../api/Project'
import CollectionsIcon from '@mui/icons-material/Collections';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import MapIcon from '@mui/icons-material/Map';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import InfoIcon from '@mui/icons-material/Info';
import { ProgressDialog } from './ProjectDialog/ProgressDialog';
import { BlankPageDetail } from '../../../ui-component/BlankPageDetail';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackDropLoading } from '../../../ui-component';
import * as dict from '../../../dictionary'
import { checkProductType } from '../../../utils/checkProductType';
export const ProjectDetail = () => {

    const [profile, setProfile] = useState(null)
    const [projectDetail, setProjectDetail] = useState(null)
    const [productType, setProductType] = useState(null)
    const [isProgress, setIsProgress] = useState(false)
    const [isTabDetail, setIsTabDetail] = useState(0)
    const [facility, setFacility] = useState(null)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const loadProjectDetail = async (id) => {
        setLoadingBackDrop(true)
        let res = await api.getProjectDetail({
            "project_id": id,
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": location.state?.productType
        })

        setProjectDetail(res?.data?.data)
        setLoadingBackDrop(false)
    }

    const renderTabDetail = () => {

        if (projectDetail?.project.description?.length > 0) {
            return <div dangerouslySetInnerHTML={{ __html: (projectDetail?.project.description) }} />

        }
        return <BlankPageDetail lang={profile?.language}/>

    }

    const renderTabFacility = () => {
        if (facility?.length > 0) {
            return <Box sx={{ padding: 1 }} >
                <Grid container spacing={2}>
                    {
                        facility
                    }
                </Grid>
            </Box>
        }

        return <BlankPageDetail lang={profile?.language}/>
    }



    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])



    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    useEffect(() => {
        if (location.state?.idSelected !== null && productType !== null) {
            loadProjectDetail(location.state?.idSelected)
        }
    }, [location.state, productType]);

    useEffect(() => {

        if (projectDetail !== null) {

            let element = projectDetail?.facility?.map((element, index) => {
                return <Grid item xs={6}><Typography key={index} variant={"body1"}>{element.name}</Typography><ul>{
                    element.data.map((children, number) =>
                        <li><Typography key={number} variant={"subtitle2"}>{children.name}</Typography>  </li>
                    )}</ul></Grid>
            })

            setFacility(element)
        }

    }, [projectDetail])
    return (
        <>
            <BackDropLoading
                loading={loadingBackDrop}
            />

            <Box sx={{ height: "200px", marginTop: 8 }}>
                <img src={projectDetail?.["image"]["image1024"]} style={{
                    "width": "100%",
                    "height": "100%",
                    "objectFit": "contain"
                }} />

            </Box>


            <Box sx={{ backgroundColor: "#F2F2F2" }} >
                <Grid container direction="row"
                    alignItems="center" spacing={1} sx={{ padding: 1 }}>
                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ padding: 1 }} onClick={() => {
                            navigate(`/projectInfo/gallery${location.search}`, {
                                state: {
                                    imgGallery: projectDetail?.gallary,
                                    lang:profile?.language
                                }
                            })
                        }}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1} >
                                <CollectionsIcon />
                                <Typography variant="body2">{dict.Gallery_TXT[profile?.language]}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    {
                        projectDetail?.progress_link !== "" && <Grid item xs={4}>
                            <Paper elevation={0} sx={{ padding: 1 }} onClick={() => window.open(projectDetail?.progress_link)}>
                                <Stack direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1} >
                                    <HourglassTopIcon />
                                    <Typography variant="body2">{dict.Progress_TXT[profile?.language]}</Typography>

                                </Stack>
                            </Paper>
                        </Grid>
                    }

                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ padding: 1 }} onClick={() => {
                            // setIsMap(true)
                            // setMapDetail(projectDetail?.map)
                            navigate(`/projectInfo/mapDialog${location.search}`, {
                                state: {
                                    mapDetail: projectDetail?.map,
                                    lang:profile?.language
                                }
                            })
                        }}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1} >
                                <MapIcon />
                                <Typography variant="body2">{dict.Map_TXT[profile?.language]}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ padding: 1 }} onClick={() => {
                            // setIsPlan(true)
                            // setModelList(projectDetail?.model_list)
                            navigate(`/projectInfo/plan${location.search}`, {
                                state: {
                                    modelList: projectDetail?.model_list,
                                    lang:profile?.language
                                }
                            })
                        }}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1} >
                                <PieChartOutlineIcon />
                                <Typography variant="body2">{dict.Plan_TXT[profile?.language]}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ padding: 1 }} onClick={() => {

                            // setIsAdvertise(true)
                            // setBrochure(projectDetail?.brochure)
                            navigate(`/projectInfo/advertise${location.search}`, {
                                state: {
                                    brochure: projectDetail?.brochure,
                                    lang:profile?.language
                                }
                            })
                        }}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1} >
                                <AutoStoriesIcon />
                                <Typography variant="body2">{dict.Advertis_TXT[profile?.language]}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ padding: 1 }} onClick={() => {
                            // setIsInfo(true)
                            // setProjectOther(projectDetail?.project_other)
                            navigate(`/projectInfo/info${location.search}`, {
                                state: {
                                    projectOther: projectDetail?.project_other
                                }
                            })
                        }}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1} >
                                <InfoIcon />
                                <Typography variant="body2">{dict.More_Info_TXT[profile?.language]}</Typography>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>


            </Box>
            <Box sx={{ marginTop: 2, backgroundColor: "white", padding: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        value={isTabDetail}
                        onChange={(event, newValue) => setIsTabDetail(newValue)}>
                        <Tab label={dict.Detail_TXT[profile?.language]} />
                        <Tab label={dict.Facility_TXT[profile?.language]} />
                    </Tabs>
                </Box>

            </Box>

            <Box sx={{ padding: 2 }}>
                {
                    isTabDetail === 0 && renderTabDetail()
                }

                {
                    isTabDetail === 1 && renderTabFacility()
                }

            </Box>
        
            <ProgressDialog
                open={isProgress}
                onClose={() => setIsProgress(false)}
            />
        </>
    )
}
