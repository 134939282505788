import React, { useState, useRef, useEffect, memo } from "react";
const API_KEY = "AIzaSyDBZOfe7rhbqATuzTUQ45KrabgHGEwU2BE";
const PLACE_ID = "ChIJr2GSeHq94jARqMQCx3I3uJ8";
export default memo(({ value, onChange }) => {
  const googleMapRef = useRef();
  const [location, setLocation] = useState("");
  let map;
  useEffect(() => {
    if (document.getElementById("gmap")) {
      initMap();
      return;
    }
    const googleMapScript = document.createElement("script");
    googleMapScript.id = "gmap";
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    googleMapScript.async = true;
    googleMapScript.addEventListener("load", () => {
      initMap();
    });
    window.document.body.appendChild(googleMapScript);
    return () => {
      console.log("exit");
    };
  }, []);
  function initMap(callback) {
    map = new window.google.maps.Map(googleMapRef.current, {
      center: { lat: 13.683647908878163, lng: 100.5473968993886 }, // 13.683647908878163, 100.5473968993886
      zoom: 13,
    });
    // const input = document.getElementById("pac-input");
    // const autocomplete = new window.google.maps.places.Autocomplete(input);
    // autocomplete.bindTo("bounds", map);
    // // Specify just the place data fields that you need.
    // autocomplete.setFields([
    //   "place_id",
    //   "geometry",
    //   "name",
    //   "formatted_address",
    // ]);
    // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
    const marker = new window.google.maps.Marker({ map: map });
    // autocomplete.addListener("place_changed", () => {
    //   // infowindow.close();
    //   const place = autocomplete.getPlace();
    //   if (!place.geometry || !place.geometry.location) {
    //     return;
    //   }
    //   if (place.geometry.viewport) {
    //     map.fitBounds(place.geometry.viewport);
    //   } else {
    //     map.setCenter(place.geometry.location);
    //     map.setZoom(17);
    //   }
    //   // Set the position of the marker using the place ID and location.
    //   marker.setPlace({
    //     placeId: place.place_id,
    //     location: place.geometry.location,
    //   });
    //   marker.setVisible(true);
    //   let result = {
    //     place_id: place.place_id,
    //     name: place.name,
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng(),
    //     location: "",
    //   };
    //   result.location = result.lat + "," + result.lng;
    //   result.value = result.location + "," + result.place_id;
    //   if (onChange) {
    //     onChange(result);
    //   }
    //   setLocation(result.location);
    // });
    // console.log("value", { value });
    if (value !== "") {
      const splited = value.split(",");
      const place_id = splited.length === 3 ? splited[2] : "";
      if (place_id !== "") {
        findPlaceById(place_id, map, marker);
      } else {
        //
        map.setCenter({
          lat: parseFloat(splited[0]),
          lng: parseFloat(splited[1]),
        });
        map.setZoom(17);
        marker.setPosition({
          lat: parseFloat(splited[0]),
          lng: parseFloat(splited[1]),
        });
        marker.setVisible(true);
        setLocation(splited[0] + "," + splited[1]);
      }
    }
  }
  function findPlaceById(placeId, map, marker) {
    const request = {
      placeId: placeId,
      fields: ["name", "formatted_address", "place_id", "geometry"],
    };
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails(request, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry &&
        place.geometry.location
      ) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
        marker.setPlace({
          placeId: place.place_id,
          location: place.geometry.location,
        });
        marker.setVisible(true);
      }
    });
  }
  return (
    <div>
      <div style={{ display: "none" }}>
        <input
          id="pac-input"
          type="text"
          placeholder="Enter a location"
          style={{
            backgroundColor: "#fff",
            borderRadius: "2px",
            border: "1px solid transparent",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            boxSizing: "border-box",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "300",
            height: "29px",
            marginLeft: "17px",
            marginTop: "10px",
            outline: "none",
            padding: "0 11px 0 13px",
            textOverflow: "ellipsis",
            width: "250px",
          }}
        />
      </div>
      <div id="map" ref={googleMapRef} style={{ height: "100vh" }}></div>
      <div>
        <span>Location: {location}</span>
      </div>
    </div>
  );
});