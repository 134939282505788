import { useState , useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DetailCP, BlankPage } from "../../../ui-component";
import * as api from '../../../api/DetailDoc'
import { ProfileContext } from "../../../contexts/ProfileContext";
import { ModalError, BackDropLoading } from "../../../ui-component";

export const LoanCPDocumentDetail = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [modalError, setModalError] = useState({ errorResponse: null, open: false });
  const [profile, setProfile] = useState(null)
  const [houseInfo, setHouseInfo] = useState(null)
  const location = useLocation()
  const context = useContext(ProfileContext)

  useEffect(() => {
    if (profile !== null) {

      const loadDetail = async () => {
        setLoading(true)
        let res = await api.getDetailDoc({
          "line_id": profile?.line_id,
          "product_type": houseInfo?.product_type,
          "id": location.state?.id
        })
        if (res?.status === 200) {
          setData(res.data?.data)
          setLoading(false)
          return
        }
        setLoading(false)
        setModalError({ errorResponse: res, open: true })
        return
      }
      loadDetail()
    }
  }, [profile, houseInfo]);

  useEffect(() => {
    if (context.state?.profile !== null) {
      setProfile(context.state?.profile?.customer[0])
    }

    if (context?.state.houseInfo) {
      setHouseInfo(context?.state?.houseInfo)
    }
  }, [context])
  return (
    <>
      {data ? <DetailCP data={data} /> : <BlankPage lang={profile?.language} />}
      <ModalError
        onClose={() => setModalError({ errorResponse: null, open: false })}
        open={modalError.open}
        error={modalError.errorResponse}
      />
      <BackDropLoading
        loading={loading}
      />
    </>
  );
};
