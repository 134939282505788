import axios from "axios";

export const getAddressSendDoc = async (data) => {

    try {

        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/address/send_doc`,
            data: {
                "line_id": data.line_id,    
                "product_type": data.product_type,
                "unit_id":data.unit_id,
                "mobile": data.mobile,
                "mobile_2": data.mobile_2,
                "phone_international": data.phone_international,
                "email":data.email
            }
        })
        return res

    } catch (error) {
        return error
    }


}