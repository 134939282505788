import React, { useState, useEffect , useContext } from 'react'
import {
    Stack,
    Typography, 
    Box,
    Divider
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import lineIcon from '../../../../assets/images/logo/line.png'
import telephoneIcon from '../../../../assets/images/logo/telephone.png'
import 'react-medium-image-zoom/dist/styles.css'
import { useLocation } from 'react-router-dom';
import { ProfileContext } from '../../../../contexts/ProfileContext';
import * as dict from '../../../../dictionary'
export const InfoDialog = (props) => {
    const theme = useTheme();
    const [infoDetail, setInfoDetail] = useState(null)
    const [profile, setProfile] = useState(null)
    const location = useLocation()
    const context = useContext(ProfileContext)

    useEffect(() => {

        if (location.state?.projectOther !== null) {
            setInfoDetail(location.state?.projectOther)
        }

    }, [location.state]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
        <>
            <Box sx={{ textAlign: "flex-start", marginTop: 8 , padding: 2}}>
                <Typography variant={"h6"} sx={{ color: theme.palette.primary.main , fontWeight:"bold"}}>{infoDetail?.project_name}</Typography>
                <Typography varaint={"body2"}>{infoDetail?.address}</Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
                {
                    infoDetail?.list?.length > 0 && infoDetail.list.map(element => <>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" sx={{ marginTop: 2 }} >
                            <Stack direction={"column"}>
                                <Typography variant={"h6"} color={theme.palette.primary.main}>{element.title}</Typography>
                                <Typography variant='caption'>{dict.Contact_Number_TXT[profile?.language]}</Typography>
                                <Typography variant='caption'>{element.tel}</Typography>

                            </Stack>

                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                {
                                    element.line !== "" && <a href={element.line}>
                                        <img
                                            src={lineIcon}
                                            width={"55px"}
                                            height={"55px"}
                                        />
                                    </a>
                                }


                                <a href={`tel:${element.tel}`}>
                                    <img
                                        src={telephoneIcon}
                                        width={"50px"}
                                        height={"50px"}
                                    />
                                </a>

                            </Stack>



                        </Stack>

                        <Divider />
                    </>
                    )
                }
            </Box>
        </>


    )

}
