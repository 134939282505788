
export const MyHome_TXT = []
MyHome_TXT["en"] = "My Home"
MyHome_TXT["th"] = "เมนูบ้านของฉัน"

export const LabelSelectCondo_TXT = []
LabelSelectCondo_TXT["en"] = "Enter your project name and room number."
LabelSelectCondo_TXT["th"] = "ระบุชื่อโครงการและเลขห้องของคุณ"

export const PlaceHolderProject_TXT = []
PlaceHolderProject_TXT["en"] = "Project"
PlaceHolderProject_TXT["th"] = "โครงการ"

export const HouseNumber_TXT = []
HouseNumber_TXT["en"] = "House Number"
HouseNumber_TXT["th"] = "บ้านเลขที่"

export const UnitNo_TXT = []
UnitNo_TXT["en"] = "Unit Number"
UnitNo_TXT["th"] = "เลขที่ยูนิต"

export const BillPayment_TXT = []
BillPayment_TXT["en"] = "Bill Payment"
BillPayment_TXT["th"] = "ค่าใช้จ่าย"

export const Parcel_TXT = []
Parcel_TXT["en"] = "Parcel"
Parcel_TXT["th"] = "พัสดุ"

export const PhoneBook_TXT = []
PhoneBook_TXT["en"] = "Phone Book"
PhoneBook_TXT["th"] = "รายชื่อติดต่อ"

export const Facility_TXT = []
Facility_TXT["en"] = "Facility"
Facility_TXT["th"] = "ส่วนกลาง"

export const Loan_TXT = []
Loan_TXT["en"] = "Loan"
Loan_TXT["th"] = "สินเชื่อ"

export const Defect_TXT = []
Defect_TXT["en"] = "Defect"
Defect_TXT["th"] = "แจ้งซ่อม"

export const Transfer_Owner_TXT = []
Transfer_Owner_TXT["en"] = "transferred"
Transfer_Owner_TXT["th"] = "โอนกรรมสิทธิ์แล้ว"

export const Fill_Name_HouseNumber_TXT = []
Fill_Name_HouseNumber_TXT["en"] = "Please specify your project name and house number."
Fill_Name_HouseNumber_TXT["th"] = "กรุณาระบุชื่อโครงการและบ้านเลขที่ของท่าน"