import axios from "axios";


export const getConsentAllByUser = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/getconsent`,
            data: {
                "line_id": data.line_id,
                "citizen_id": data.citizen_id,
                "contact_type": data.contact_type
            }
        })
        return res
    } catch (error) {
        return error
    }

}