import axios from "axios";


export const logout = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/logout`,
            data: {
                "line_id": data.line_id,
            }
        })
        return result

    } catch (error) {
        return error
    }

}

export const changeLanguage = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/changeLanguage`,
            data: {
                "line_id": data.line_id,
                "language": data.language
            }
        })
        return result

    } catch (error) {
        return error
    }

}

export const notificationUpdate = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/notification/update`,
            data: {
                "line_id": data.line_id,
                "is_noti": data.is_noti
            }
        })
        return result

    } catch (error) {
        return error
    }

}