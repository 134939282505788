export const Term_Condition_TXT = []
Term_Condition_TXT["en"] = "Terms and Conditions"
Term_Condition_TXT["th"] = "ข้อกำหนดและเงื่อนไข"

export const Register_Confirm_TXT = []
Register_Confirm_TXT["en"] = "Confirmation of registration"
Register_Confirm_TXT["th"] = "ยืนยันการลงทะเบียน"

export const Next_TXT = []
Next_TXT["en"] = "Next"
Next_TXT["th"] = "ต่อไป"

export const Page_TXT = []
Page_TXT["en"] = "Page"
Page_TXT["th"] = "หน้า"

export const System_Saved_TXT = []
System_Saved_TXT["en"] = "The system has successfully saved your registration."
System_Saved_TXT["th"] = "ระบบได้บันทึกการลงทะเบียนของคุณเรียบร้อยแล้ว"

export const System_Error_TXT = []
System_Error_TXT["en"] = "Something went wrong, the system could not save your registration."
System_Error_TXT["th"] = "เกิดข้อผิดพลาดระบบไม่สามารถบันทึกการลงทะเบียนของคุณได้"

export const Understand_And_Agree_TXT = []
Understand_And_Agree_TXT["en"] = "I understand and accept the terms."
Understand_And_Agree_TXT["th"] = "ฉันเข้าใจและยอมรับข้อตกลง"