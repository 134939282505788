import { Fragment, useState, useEffect, useContext } from "react";
import {
  Stack,
  Container,
  Box,
  Button,
  Slide,
  Input
} from "@mui/material";
import CardStatus from "../../../ui-component/CardLoanCP/CardStatus";
import { ChipRoomInfo } from "../../../ui-component/ChipRoomInfo";
import { HolidayVillage, Search, Close } from '@mui/icons-material';
import * as dict from '../../../dictionary'
import { ProfileContext } from "../../../contexts/ProfileContext";
import * as api from '../../../api/LoanCP'
import {
  ModalError,
  BackDropLoading,
  BlankPage
} from '../../../ui-component';
export const LoanCPStatus = () => {
  const [loanCPStatus, setLoanCPStatus] = useState([]);
  const [tempList, setTempList] = useState()
  const [modalError, setModalError] = useState({ errorResponse: null, open: false });
  const [loading, setLoading] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [keyword, setKeyword] = useState("");
  const [profile, setProfile] = useState(null)
  const [houseInfo, setHouseInfo] = useState(null)
  const context = useContext(ProfileContext)


  useEffect(() => {

    let keywordReg = new RegExp(keyword, "g")
    let filter = tempList?.filter(ele => ele.bank_name.match(keywordReg)
      || ele.status_name.match(keywordReg) 
      || ele.status_date.match(keywordReg)
    )
    setLoanCPStatus(filter)

  }, [keyword])

  useEffect(() => {
    if (profile !== null) {

      const loadList = async () => {
        setLoading(true);
        let res = await api.getLoanCPStatus({
          line_id: profile?.line_id,
          product_type: houseInfo?.product_type,
          project_id: houseInfo?.project_id,
          unit_id: houseInfo?.unit_id,
        });

        if (res.status === 200) {
          setLoanCPStatus(res.data?.data);
          setTempList(res.data?.data)
          setLoading(false);
          return;
        }
        setLoading(false)
        setModalError({ errorResponse: res, open: true })
        return;
      };
      loadList()
    }
  }, [profile, houseInfo]);

  useEffect(() => {
    if (context.state?.profile !== null) {
      setProfile(context.state?.profile?.customer[0])
    }

    if (context?.state.houseInfo) {
      setHouseInfo(context?.state?.houseInfo)
    }
  }, [context])

  return (
    <>
      <Box sx={{ height: "calc(100vh - 60px)" }}>
        <Container
          sx={{
            minHeight: "100%",
            paddingBottom: 5,
          }}
        >
          <Stack spacing={2} direction={"column"}>
            <Box sx={{ width: "100%", overflow: "hidden" }} >
              <Stack direction="row" mt={4}   >
                <Slide direction="right" in={!triggerSearch} mountOnEnter unmountOnExit>
                  <Stack sx={{ width: "100%" }} direction={"row"} alignItems="center" justifyContent={"space-between"}>
                    <ChipRoomInfo
                      houseInfo={houseInfo}
                      icon={<HolidayVillage />}
                      lang={profile?.language}
                    />
                    <Button size="small">
                      <Search sx={{ fontSize: 35 }} onClick={() => setTriggerSearch(true)} />
                    </Button>
                  </Stack>
                </Slide >
              </Stack>
              <Slide direction="left" timeout={{ enter: 500, exit: 500 }} in={triggerSearch} mountOnEnter unmountOnExit>
                <Box sx={{ width: "100%" }}>
                  <Input placeholder={dict.SearchLoan_TXT[profile?.language]} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                  <Button size="small">
                    <Close onClick={() => {
                      setTriggerSearch(false)
                      setKeyword("")
                    }} />
                  </Button>
                </Box>
              </Slide >
            </Box>
            {loanCPStatus?.length > 0 ? loanCPStatus?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <CardStatus {...item} lang={profile?.language} navigate={"detail"} state={{ title:item.bank_name , detail: item}}  />
                </Fragment>
              );
            }) : <BlankPage lang={profile?.language}/>}
          </Stack>
        </Container>
      </Box>
      <ModalError
        onClose={() => setModalError({ errorResponse: null, open: false })}
        open={modalError.open}
        error={modalError.errorResponse}
      />
      <BackDropLoading
        loading={loading}
      />
    </>
  );
};
