import React, { useState, useEffect, useContext } from 'react'
import { Typography, Stack, Container, Paper, Box } from '@mui/material'
import { HolidayVillage, ArrowForwardIos } from '@mui/icons-material'
import { ProfileContext } from '../contexts/ProfileContext'
import { ReactComponent as PdfLogo } from '../assets/images/logo/pdfLogo.svg'
import { useTheme, styled } from '@mui/material/styles';
import { BackDropLoading } from '../ui-component'
import moment from 'moment';
import * as dict from '../dictionary'
import * as api from '../api'
import { ChipRoomInfo } from '../ui-component/ChipRoomInfo'
export const DefectDoc = () => {
    const theme = useTheme()
    const [data, setData] = useState([])
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [houseInfo, setHouseInfo] = useState(null)
    let houseInfoSelected = localStorage.getItem("houseInfoSelected")
    let houseInfoParse = JSON.parse(houseInfoSelected)
    const context = useContext(ProfileContext)

    const loadData = async () => {
        setLoading(true)
        let res = await api.getDocumentBeforeTransfer({
            line_id: profile?.line_id,
            product_type: houseInfo?.product_type,
            project_id: houseInfo?.project_id,
            house_number: houseInfo?.house_number
        })

        setData(res.data?.data)
        setLoading(false)
    }

    const checkDate = (date) => {
        if (date === null || date === "") {
            return "-"
        }
        return moment(date).format("DD/MM/YYYY")
    }


    useEffect(() => {

        if (profile !== null) {
            loadData()
        }

    }, [profile, houseInfo]);

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])


    return (
        <Container maxWidth="xl" >
            <Stack direction={"row"} alignItems="center" justifyContent={"flex-start"}>
                <ChipRoomInfo
                    houseInfo={houseInfo}
                    houseInfoParse={houseInfoParse}
                    icon={<HolidayVillage />}
                    lang={profile?.language}
                    sx={{
                        mt: 3
                    }}
                />
            </Stack>
            <Stack direction="column" sx={{ mb: 2, mt: 3 }}>
                {
                    data?.map((element, i) =>
                        <PaperCustom key={i} sx={{ p: 2, mt: 1 }} onClick={() => window.open(element?.document_pdf_url)}>
                            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>

                                <Stack direction={"row"} spacing={2}>

                                    <BackLogo >
                                        <PdfLogo style={{ width: 19, height: 20, fill: theme.palette.secondary.main }} />
                                    </BackLogo>
                                    <Box>
                                        <Typography fontSize={"14px"} fontWeight={700}>{element?.document_code}</Typography>
                                        <Typography fontSize={"10px"} fontWeight={400} color={"#828282"}> {dict.DefectDoc_Date_TXT[profile?.language]} {moment(element?.document_created_date).format("DD/MM/YYYY")}</Typography>
                                        <Typography fontSize={"10px"} fontWeight={400} color={"#828282"}> {dict.DefectDoc_DateEdit_TXT[profile?.language]} {checkDate(element?.document_changed_date)}</Typography>

                                    </Box>

                                </Stack>
                                <ArrowForwardIos sx={{ color: theme.palette.primary.main }} />
                            </Stack>
                        </PaperCustom>
                    )
                }

            </Stack>
            <BackDropLoading
                loading={loading}
            />
        </Container>
    )
}

const BackLogo = styled(Box)(({ theme }) => ({
    borderRadius: 100,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const PaperCustom = styled(Paper)({
    boxShadow: "0px 0px 7px 0px #00000040",
    borderRadius: "10px",
    "&:active": {
        backgroundColor: "#1D1C1A"
    }
})