import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import {
    Box,
    Stack
} from '@mui/material'
import { Container } from '@mui/system'
import { ProfileContext } from '../../contexts/ProfileContext'
import { BlankPage } from '../../ui-component'
import * as api from '../../api/Unit'
import { useLocation } from 'react-router-dom';
import { BackDropLoading } from '../../ui-component'
import { ModalError } from '../../ui-component'
import * as dict from '../../dictionary'

export const PriceInfo = () => {
    const context = useContext(ProfileContext)
    const [info, setInfo] = useState(null)
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation()

    useEffect(() => {
        const loadDetail = async () => {
            setLoading(true)
            let res = await api.getUnitDetail({
                "unit_id": location.state?.priceInfoDetail?.unit_id,
                "line_id": profile?.line_id,
                "language": profile?.language,
                "product_type": location.state?.priceInfoDetail?.product_type
            })

            if (res?.status === 200) {
                setInfo(res.data)
                setLoading(false)
                return
            }
            setError(res)
            setModalError(true)
            return
        }
        if (profile !== null) {
            loadDetail()
        }
    }, [profile, location.state]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context]);

    return (
        <Box sx={{ marginTop: 10 }} >

            {
                info !== null &&

                <Container>

                    <Stack spacing={1} direction={"column"} >

                        <Typography variant={"h6"}>{dict.Price_info_TXT[profile?.language]}</Typography>

                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body1"}>{dict.NetSelling_TXT[profile?.language]}</Typography>
                            <Typography variant={"body1"} fontWeight={"bold"}> {info?.selling_price?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.HousePrice_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.total_selling_price?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Price_Per_WA_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.price_per_area?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Booking_Price_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.booking_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Contract_Money_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.contract_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Deposit_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.down_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Deposit_per_month_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.down_per_month?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Number_Deposit_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.down_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Money_Transfer_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.transfer_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                    </Stack>

                    <Stack spacing={1} direction={"column"} mt={5}>

                        <Typography variant={"h6"}>{dict.Discount_TXT[profile?.language]}</Typography>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Discount_Pro_Day_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.discount_amount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                            <Typography variant={"body2"}>{dict.Discount_Transfer_TXT[profile?.language]}</Typography>
                            <Typography variant={"body2"} fontWeight={"bold"}>{info?.transfer_discount?.toLocaleString() || 0} บาท</Typography>
                        </Stack>
                    </Stack>

                </Container>
            }
            {
                (info === null && loading === false) && <BlankPage />
            }

            <BackDropLoading
                loading={loading}
            />
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </Box>
    )
}
