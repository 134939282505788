import React from 'react'
import { Chip , Typography} from '@mui/material'
export const StatusTag = ({ text }) => {

    const getColor = () => {
        switch (text) {
            case "เปิดใบงาน" :
            case "open": return "#2F80ED"
            case "รอดำเนินการ":
            case "inprogress": return "#F2994A"
            case "ปิดใบงาน":
            case "complete" : return "#219653"
            case "ปิดใบงาน (มีเงื่อนไข)" : 
            case "complete-t": return "#BB6BD9"
            case "ยกเลิก":
            case "cancel": return "#EB5757"
            default: return "#2F80ED"
        }
    }


    return (
        <Chip label={<Typography fontSize={12} fontWeight={700} color={getColor()}>{text}</Typography>} />
    )
}
