import axios from "axios";

export const getPhoneCP = async (data) => {
    try {
      let result = await axios({
        method: "post",
        url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/phonebook`,
        data: {
          project_id: data?.project_id,
          line_id: data?.line_id,
          product_type: data?.product_type,
          type: data?.type,
        },
      });
  
      return result;
    } catch (error) {
      return error;
    }
  };