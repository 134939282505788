import React, { useState, useEffect, useContext } from 'react'
import {
    Stack, Card,
    Typography, Box, Tabs, Tab

} from '@mui/material'
import { ProfileContext } from '../../contexts/ProfileContext';
import { BlankPage } from '../../ui-component/BlankPage';
import * as api from '../../api/Project'
import { useLocation, useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component'
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType';
export const CampaignTab = (props) => {
    const [isTab, setIsTab] = useState("reports")
    const [profile, setProfile] = useState(null)
    const [campaignList, setCampaignList] = useState([])
    const [productType, setProductType] = useState(null)
    const [countTotal, setCountTotal] = useState(0)
    const [page, setPage] = useState(0)
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const typeMeaning = {
        "ประกาศจากโครงการ": "announcement",
        "โฆษณา": "banner",
        "ไลฟ์สไตล์": "lifestyle",
        "ข่าวสาร": "news",
        "โปรโมชัน": "promotion"
    }

    const loadMore = async () => {
        let tempPage = page + 1
        setPage(tempPage)
        handleChangeTab(tempPage)
    }

    const loadListCampaign = async (typeSelected, page) => {
        let res = await api.getPromotionList({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "type": typeMeaning[typeSelected],
            "page": page,
            "limit": "10"
        })
        setCountTotal(res.data?.count)
        if (page === 0) {
            return setCampaignList(res.data?.data)
        }

        if (res.data?.data.length > 0) {
            setCampaignList([...campaignList, ...res.data?.data])
        }

    }

    const handleChangeTab = (page) => {
        window.scrollTo(0,0); 
        if (productType === null) return

        if (isTab === "reports") {
            loadListCampaign("ประกาศจากโครงการ", page)
        }

        if (isTab === "news") {
            loadListCampaign("ข่าวสาร", page)
        }

    }

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    useEffect(() => {
        if (profile !== null && props?.isVisible === true) {
            handleChangeTab(0)
        }
    }, [profile, props?.isVisible]);

    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    useEffect(() => {
        let tabSession = sessionStorage.getItem("tabCampaignSelected")
        setIsTab(tabSession || isTab)
    }, []);

    useEffect(() => {
        handleChangeTab(0)
    }, [isTab])



    return (
        <>
            {props?.isVisible === true && <><Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 , mt:8}}>
                <Tabs
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                    value={isTab}
                    sx={{ backgroundColor: "white" }}
                    onChange={(event, newValue) => {
                        setIsTab(newValue)
                        sessionStorage.setItem('tabCampaignSelected', newValue)
                    }} centered>
                    <Tab label={dict.Announcement_Tab_TXT[profile?.language]} value={"reports"} />
                    <Tab label={dict.News_Tab_TXT[profile?.language]} value={"news"} />
                </Tabs></Box>
                <Box sx={{ minHeight: "100vh", backgroundColor: "#F2F2F2" }}>
                    <InfiniteScroll
                        dataLength={countTotal} //This is important field to render the next data
                        next={loadMore}
                        hasMore={campaignList?.length < countTotal}
                        loader={<h4>Loading...</h4>}
                    >
                        <Box sx={{ marginTop: 15 }} />
                        {
                            campaignList?.length > 0 && campaignList.map((element,index) => <Card variant="outlined" key={index} sx={{
                                display: "flex",
                                height: "100%",
                                marginTop: 2,
                                justifyContent: "center"
                            }} onClick={() => {
                                // setIdSelected(element.id)
                                // setIsDetailVisible(true)
                                navigate(`/projectInfo/campaignDetail${location.search}`, {
                                    state: {
                                        idSelected: element.id,
                                        title: element.title
                                    }
                                })
                            }}>
                                <Stack direction={"column"} alignItems={"center"} sx={{ width: "100%", padding: 2 }}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        <Stack direction={"column"}>
                                            <Typography variant={"caption"}>{dict.Announcement_TXT[profile?.language]} {element.postdate}</Typography>
                                        </Stack>


                                    </Box>

                                    <Box
                                        sx={{
                                            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url('${element["images"]["image1024"]}')`,
                                            height: "200px",
                                            width: "85%",
                                            borderRadius: "10px",
                                            marginTop: 2,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                        }}
                                    >

                                        <Box sx={{ height: "70px", width: "100%" }}>
                                            <Stack direction="row" spacing={0.2}>
                                                <Typography variant={"body1"} ml={2} sx={{ color: "white", textShadow: "1px 0.5px #FFFFFF", textAlign: "justify" }}>
                                                    {element.title}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Stack>

                            </Card>)
                        }
                        <Box sx={{ marginBottom: 10 }}></Box>
                    </InfiniteScroll>
                    {
                        campaignList?.length === 0 && <BlankPage lang={profile?.language}/>
                    }
                </Box>
            </>
            }


        </>
    )
}
