import React , { useEffect , useState , useContext} from 'react'
import * as api from '../api/ContactJuristic'
import { ProfileContext } from '../../src/contexts/ProfileContext'
import liff from '@line/liff';
import { Dialog, DialogTitle, DialogContent, DialogActions , Button} from '@mui/material'
import * as dict from '../dictionary'
export const ContactJuristic = () => {

    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [isDialog, setIsDialog] = useState(false)
    const context = useContext(ProfileContext)

    const sendProfileToServer = async () => {
        let res = await api.messageOfficial({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "mobile_no": profile?.mobile,
            "project_id": houseInfo?.project_id,
            "unit_number": houseInfo?.unit_number,
            "house_number": houseInfo?.house_number,
            "first_name": profile?.firstname,
            "first_name_en": profile?.firstname_en,
            "last_name": profile?.lastname,
            "last_name_en": profile?.lastname_en
        })

        if(res.data?.message === "success"){
            return setIsDialog(true)
        }

        return 

    }

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])

    useEffect(() => {
        
        if(profile !== null && houseInfo !== null){
            sendProfileToServer()
        }

    }, [profile, houseInfo]);

  return (
    <>
    <Dialog
        open={isDialog}
    >
        <DialogTitle> {dict.Alert_Screen_Close_TXT[profile?.language]} </DialogTitle>
        <DialogContent>
            {dict.Helper_Screen_Close_TXT[profile?.language]}
        </DialogContent>
        <DialogActions>
        <Button onClick={() => liff.closeWindow()}>
            {dict.OK_TXT[profile?.language]}
          </Button>
        </DialogActions>
    </Dialog>
    </>
  )
}
