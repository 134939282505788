import axios from "axios";


export const register = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/register`,
            data: {
                "line_id": data.line_id,
                "citizen_id": data.citizen_id,
                "customer_type": data.customer_type,
                "is_accept": true,
                // "product_type": data.product_type,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "email": data.email,
                "gender": data.gender,
                "mobile_no": data.mobile_no,
                "birthday": data.birthday,
                "contact_type": data.contact_type
            }
        })
        return res
    } catch (error) {
        return error
    }
}

export const requestOTP = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/sms/request-otp`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "citizen_id":data.citizen_id,
                "mobile_no":data.mobile_no
            }
        })
        return res
    } catch (error) {
        return error

    }
}

export const verifyOTP = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/sms/verify-otp`,
            data: {
                "token": data.token,
                "otp_code": data.otp_code
            }
        })
        return res
    } catch (error) {
        return error

    }
}

export const emailRequestOTP = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/email/request-otp`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "citizen_id":data.citizen_id,
                "email":data.email
            }
        })
        return res
    } catch (error) {
        return error

    }
}

export const emailVerifyOTP = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/email/verify-otp`,
            data: {
                "token": data.token,
                "otp_code": data.otp_code,
                "ref": data.ref
            }
        })
        return res
    } catch (error) {
        return error

    }
}