import {
    Stack,
    Container,
    Box,
    Tabs,
    Tab,
    Card,
    styled,
    Typography,
    Divider,
    Button,
    Grid
} from '@mui/material'
import { FileDownload } from '@mui/icons-material';
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import {
    ModalError,
    BackDropLoading,
    BlankPage
} from '../../ui-component';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import * as dict from '../../dictionary'
import * as api from '../../api/InspectCP'
import { checkDate } from '../../utils/checkDate';
export const InspectCPStatus = () => {
    const navigate = useNavigate()
    const [tabSelected, setTabSelected] = useState("overview")
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [loading, setLoading] = useState(false)
    const [overview, setOverview] = useState(null)
    const [document, setDocument] = useState([])
    const context = useContext(ProfileContext)
    const theme = useTheme()


    const loadInspect = async () => {
        setLoading(true)
        let res = await api.getInspectStatus({
            "line_id": profile?.line_id,
            "project_id": houseInfo?.project_id,
            "product_type": houseInfo?.product_type,
            "house_number": houseInfo?.house_number
        })
        if (res?.status === 200) {
            setOverview(res.data?.data.overview)
            setDocument(res.data?.data.documents)
            setLoading(false)
            return
        }
        setModalError({ errorResponse: res, open: true })
        setLoading(false)
        return
    }

    useEffect(() => {
        if (profile !== null) {
            //do something
            loadInspect()
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])


    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>
                <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 }} >
                    <Tabs
                        value={tabSelected}
                        onChange={(e, val) => setTabSelected(val)}
                        textColor="secondary"
                        indicatorColor="secondary"
                        centered
                        variant="fullWidth"
                        sx={{ backgroundColor: "white" }}

                    >
                        <Tab value="overview" label={dict.Inspect_Tab_Overview_TXT[profile?.language]} />
                        <Tab value="inspectList" label={dict.Inspect_Tab_List_TXT[profile?.language]} />

                    </Tabs>
                </Box>
                <Container sx={{ minHeight: "100%", paddingTop: 2, paddingBottom: 5 }} >
                    <Stack mt={7} >
                        {
                            tabSelected === "overview" && <Stack spacing={2}>
                                <CardNormal
                                    variant="outlined"
                                >
                                    <Box sx={{ pt: 3, pb: 3, pl: 4, pr: 4 }}>
                                        <Box>
                                            <Grid container spacing={5} rowSpacing={1} alignItems={"center"}>
                                                <Grid item>
                                                    <Typography variant={"h6"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                        {dict.Inspect_Number_TXT[profile?.language]} :
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ wordBreak: "break-all" }}>
                                                    <Typography variant={"h4"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                        {overview?.no_of_documents || "0"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems={"center"} spacing={2} rowSpacing={1}>
                                                <Grid item>
                                                    <Typography variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                        {dict.Check_Self_TXT[profile?.language]} : {overview?.no_of_documents_by_myself || "0"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                        {dict.Hire_Check_TXT[profile?.language]} : {overview?.no_of_documents_by_outsource || "0"}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Box>
                                </CardNormal>

                                <CardNormal
                                    variant="outlined"
                                >
                                    <Box sx={{ pt: 1, pb: 3 }}>
                                        <Typography ml={2} variant={"body1"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                            {dict.Defect_Status_TXT[profile?.language]}
                                        </Typography>
                                        <Grid container alignItems={"center"} justifyContent={"space-around"} mt={3} >
                                            <Grid item>
                                                <Stack direction={"column"} alignItems={"center"}>
                                                    <Box sx={{ wordBreak: "break-all" }}>
                                                        <Typography variant={"body2"} fontWeight={"bold"} color={overview?.color_style.defect_status_new}>
                                                            {overview?.no_of_defects_new || "0"}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                        {dict.Status_New_TXT[profile?.language]}
                                                    </Typography>
                                                    <Line sx={{ backgroundColor: overview?.color_style.defect_status_new}} />

                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction={"column"} alignItems={"center"}>
                                                    <Box sx={{ wordBreak: "break-all" }}>
                                                        <Typography variant={"body2"} fontWeight={"bold"} color={overview?.color_style.defect_status_pending}>
                                                            {overview?.no_of_defects_pending || "0"}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                        {dict.Status_Processing_TXT[profile?.language]}
                                                    </Typography>
                                                    <Line sx={{ backgroundColor: overview?.color_style.defect_status_pending }} />

                                                </Stack>
                                            </Grid>
                                            <Grid item>

                                                <Stack direction={"column"} alignItems={"center"}>
                                                    <Box sx={{ wordBreak: "break-all" }}>
                                                        <Typography variant={"body2"} fontWeight={"bold"} color={overview?.color_style.defect_status_fixed}>
                                                            {overview?.no_of_defects_fixed || "0"}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                        {dict.Status_Fixed_TXT[profile?.language]}
                                                    </Typography>
                                                    <Line sx={{ backgroundColor: overview?.color_style.defect_status_fixed }} />

                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction={"column"} alignItems={"center"}>
                                                    <Box sx={{ wordBreak: "break-all" }}>
                                                        <Typography variant={"body2"} fontWeight={"bold"} color={overview?.color_style.defect_status_closed}>
                                                            {overview?.no_of_defects_closed || "0"}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                        {dict.Status_Closed_TXT[profile?.language]}
                                                    </Typography>
                                                    <Line sx={{ backgroundColor: overview?.color_style.defect_status_closed }} />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardNormal>
                            </Stack>
                        }

                        {
                            tabSelected === "inspectList" && <Stack spacing={2}>
                                {document?.length > 0 ? document?.map((element, i) =>
                                    <CardNormal
                                        variant="outlined"
                                        key={i}
                                    >
                                        <Box sx={{ pt: 1, pb: 3 }}>
                                            <Typography ml={2} variant={"h6"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                {element.document_code}
                                            </Typography>
                                            <Box sx={{ pt: 1, pl: 4, pr: 4, pb: 1 }}>
                                                <Stack spacing={1}>
                                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                                        <Typography variant='caption'>{dict.Defect_Date_TXT[profile?.language]}</Typography>
                                                        <Typography variant='caption' fontWeight={"bold"}>{checkDate(element.document_created_date)}</Typography>
                                                    </Stack>
                                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                                        <Typography variant='caption'>{dict.Defect_Edit_Date_TXT[profile?.language]}</Typography>
                                                        <Typography variant='caption' fontWeight={"bold"}>{checkDate(element.document_changed_date)}</Typography>
                                                    </Stack>
                                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                                        <Typography variant='caption'>{dict.Defect_Authorities_TXT[profile?.language]}</Typography>
                                                        <Typography variant='caption' fontWeight={"bold"}>{element.document_changed_by || element.document_created_by}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <Typography ml={2} variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                {dict.Defect_Status_TXT[profile?.language]}
                                            </Typography>
                                            <Grid container alignItems={"center"} justifyContent={"space-around"} mt={3}>

                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_status_new}>
                                                                {element.no_of_defects_new || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_New_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_status_new }} />

                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_status_pending}>
                                                                {element.no_of_defects_pending || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Processing_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_status_pending }} />

                                                    </Stack>
                                                </Grid>

                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_status_fixed}>
                                                                {element.no_of_defects_fixed || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Fixed_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_status_fixed }} />

                                                    </Stack>
                                                </Grid>

                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_status_closed}>
                                                                {element.no_of_defects_closed || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Closed_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_status_closed }} />
                                                    </Stack>
                                                </Grid>

                                            </Grid>
                                            <br />
                                            <Typography ml={2} variant={"body2"} fontWeight={"bold"} color={theme.palette.primary.main}>
                                                {dict.Defect_Level_TXT[profile?.language]}
                                            </Typography>
                                            <Grid container alignItems={"center"} justifyContent={"space-around"} mt={3}>
                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_category_none}>
                                                                {element.no_of_category_none || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_None_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_category_none }} />

                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_category_minor}>
                                                                {element.no_of_category_minor || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Minor_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_category_minor }} />

                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_category_major}>
                                                                {element.no_of_category_major || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Major_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_category_major }} />

                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Stack direction={"column"} alignItems={"center"}>
                                                        <Box sx={{ wordBreak: "break-all" }}>
                                                            <Typography variant={"body2"} fontWeight={"bold"} color={overview.color_style.defect_category_critical}>
                                                                {element.no_of_category_critical || "0"}
                                                            </Typography>
                                                        </Box>
                                                        <Typography variant={"caption"} color={theme.palette.grey[500]}>
                                                            {dict.Status_Critical_TXT[profile?.language]}
                                                        </Typography>
                                                        <Line sx={{ backgroundColor: overview.color_style.defect_category_critical }} />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Divider />

                                            <Stack direction={"row"} spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} >
                                                <Button variant="outlined" startIcon={<FileDownload />} sx={{ width: "100%", borderRadius: "5px" }} onClick={() => window.open(element.document_pdf_url_en)}>English</Button>
                                                <Button variant="outlined" startIcon={<FileDownload />} sx={{ width: "100%", borderRadius: "5px" }} onClick={() => window.open(element.document_pdf_url_th)}>ภาษาไทย</Button>
                                            </Stack>
                                        </Box>
                                    </CardNormal>
                                ) : <BlankPage lang={profile?.language}/>}
                            </Stack>

                        }

                    </Stack>
                </Container>
            </Box >
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
            <BackDropLoading
                loading={loading}
            />
        </>
    )

}

const CardNormal = styled(Card)(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0px 0px 7px 0px #00000040"
}))

const Line = styled(Box)(({ theme }) => ({
    width: "50px",
    height: "5px",
}))