import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Typography,
    Paper, 
    BottomNavigation, 
    BottomNavigationAction,
    Box,
    AppBar,
} from '@mui/material'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectTab } from './ProjectTab';
import { CampaignTab } from './CampaignTab';
import { PromotionTab } from './PromotionTab';
import { ProfileContext } from '../../contexts/ProfileContext';

import MasterPage from '../../ui-component/MasterPage';
import * as dict from '../../dictionary'
export const ProjectPage = () => {
    const [isSearch, setIsSearch] = useState(false);
    const [isSelectedTab, setIsSelectedTab] = useState("projectInfo")
    const [searchPlaceholder, setSearchPlaceHolder] = useState("")
    const [clearFilterTrigger, setClearFilterTrigger] = useState(false)
    const [profile, setProfile] = useState(null)

    const context = useContext(ProfileContext)


    useEffect(() => {
        let navigateSession = sessionStorage.getItem("navigateSelected")
        setIsSelectedTab(navigateSession || isSelectedTab)
    }, []);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
        <MasterPage>
            <Box  >
                <AppBar sx={{ position: "fixed", boxShadow: 0, borderBottomLeftRadius: 1, borderBottomRightRadius: 1, }}  >

                    {
                        isSelectedTab === "projectInfo" && <>
                            <Stack ml={2} justifyContent={"space-between"} direction={"row"} alignItems={"center"} >
                                <Stack direction={"row"} alignItems={"center"}>
                                    <MapsHomeWorkIcon />

                                    <Typography variant={"h5"} sx={{ margin: 2 }}>
                                        {dict.Project_TXT[profile?.language]}
                                    </Typography>
                                </Stack>
                                <Stack mr={2} direction={"row"} alignItems={"center"} onClick={() => setIsSearch(true)}>
                                    <SearchIcon />
                                    <Typography variant={"caption"}>{searchPlaceholder.length > 10 ? searchPlaceholder.substring(0, 10) + "..." : searchPlaceholder}</Typography>
                                </Stack>

                            </Stack>
                        </>
                    }

                    {
                        isSelectedTab === "promotion" && <>
                            <Stack ml={2} justifyContent={"flex-start"} direction={"row"} alignItems={"center"}>
                                <Typography variant={"h5"} sx={{ margin: 2 }}>
                                    {dict.Promotion_Life_TXT[profile?.language]}
                                </Typography>
                            </Stack>
                        </>
                    }

                    {
                        isSelectedTab === "campaign" && <>
                            <Stack ml={2} justifyContent={"flex-start"} direction={"row"} alignItems={"center"}>
                                <Typography variant={"h5"} sx={{ margin: 2 }}>
                                    {dict.Public_Rela_TXT[profile?.language]}
                                </Typography>
                            </Stack>
                        </>
                    }

                </AppBar>
                <ProjectTab
                    isVisible={isSelectedTab === "projectInfo"}
                    isSearch={isSearch}
                    searchClose={() => setIsSearch(false)}
                    searchFinish={(searchInfo) => {
                        setSearchPlaceHolder(searchInfo)
                        setIsSearch(false)
                    }}
                    isClearFilter={clearFilterTrigger}
                    resetClearBoalean={() => setClearFilterTrigger(false)}
                />
                <CampaignTab
                    isVisible={isSelectedTab === "campaign"}
                />

                <PromotionTab
                    isVisible={isSelectedTab === "promotion"}
                />
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                    <BottomNavigation
                        showLabels
                        value={isSelectedTab}
                        onChange={(event, newValue) => {
                            window.scrollTo(0, 0);
                            setIsSelectedTab(newValue);
                            sessionStorage.setItem('navigateSelected', newValue)
                            sessionStorage.removeItem('tabPromotionSelected')
                            sessionStorage.removeItem('tabCampaignSelected')
                        }}
                    >
                        <BottomNavigationAction label={`${dict.Project_TXT[profile?.language]}`} value="projectInfo" icon={<MapsHomeWorkIcon />} />
                        <BottomNavigationAction label={`${dict.Promotion_TXT[profile?.language]}`} value="promotion" icon={<LocalOfferIcon />} />
                        <BottomNavigationAction label={`${dict.Public_Rela_TXT[profile?.language]}`} value="campaign" icon={<CampaignIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
        </MasterPage >
    )
}
// export default ProjectPage