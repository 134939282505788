import React from 'react'
import emptyIcon from '../assets/images/logo/box.png'
import {
    Typography, 
    Box 
} from '@mui/material'

export const BlankPage = ({ lang }) => {
    return (
        <div>

            <Box sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                /* bring your own prefixes */
                transform: "translate(-50%, -50%)", 
                opacity: 0.3,
                textAlign:"center"
            }}>

                    <img
                        src={emptyIcon}
                        width={"100px"}
                        height={"100px"}
                        alt='blank page'
                    />
                    <Typography variant='h6'>{lang === "th" ? "ยังไม่มีข้อมูล" : "No Information"}</Typography>
            </Box>

        </div>
    )
}
