import React, { useState, useEffect, useRef, useCallback } from "react"
import axios from "axios";
import * as api from '../../src/api/Paid'
import './ScriptKbank.css'
export const ScriptKbank = (props) => {
    const ref = useRef(null);
    const [createdScript, setCreatedScript] = useState(false)
    const { company_id,project_id,project_name,method_type,amount,refreshPage } = props
    const createScript = useCallback(async () => {

        let qr_public_key = "";
        let qr_merchant_id = "";
        let comp_id = company_id;

        const config = await axios.post(
            `${process.env.REACT_APP_LINEOA_V2_HOST_API}/kbankpay/account_qr`,
            {
                project_id: project_id,
                company_id: comp_id
            }
        );


        if (Object.keys(config.data.data).length > 0) {
            qr_public_key = config.data.data.qr_public_key;
            qr_merchant_id = config.data.data.qr_merchant_id;
            comp_id = config.data.data.company_id;
        } else {
            const config_company = await axios.post(
                `${process.env.REACT_APP_LINEOA_V2_HOST_API}/kbankpay/getcompanybyid`,
                {
                    company_id: localStorage.getItem("cid"),
                }
            );
            qr_public_key = config_company.data.public_key;
            qr_merchant_id = config_company.data.merchant_id;
            comp_id = config_company.data.company_id;
        }

        let BANK_SRC = process.env.REACT_APP_KBANK_API;

        if (process.env.REACT_APP_SERVER_TYPE === "prod" && comp_id !== 3) {
            BANK_SRC =
                "https://kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js";
        }

        const script = document.createElement("script");

        // if (props.orderId !== "") {
        script.type = "text/javascript";
        script.src = BANK_SRC;
        script.id = `kbank-qr-sc`;
        script.dataset.apikey = qr_public_key;
        script.dataset.name = project_name.length > 14 ? project_name.substring(0, 10) + "..." : project_name;
        script.dataset.paymentMethods = method_type; // redirect, card
        script.dataset.amount = parseFloat(amount.toString()).toFixed(2);
        script.dataset.currency = "THB";
        script.dataset.mid = qr_merchant_id;
        script.dataset.lang = "TH";
        script.dataset.writeLog = "true";
        script.dataset.showButton = "false";
        script.dataset.orderId = "";

        await ref.current.appendChild(script);
        setCreatedScript(true)
        if (window.KPayment == null) {
            script.onload = async () => {
                await window.KPayment.create();
                window.KPayment.onClose(function (e) {
                    // props.setStopLoad()
                    refreshPage()
                });
            };
        }
        // }
        return script;
    }, [
        company_id,
        project_id,
        project_name,
        method_type,
        amount,
        refreshPage
    ])


    const reciveCallback = async () => {
        let token = ref.current.childNodes[1].defaultValue
        let dcc_currency = ref.current.childNodes[2].defaultValue
        const res = await api.createKbankCreditCard({
            companyToken: "",
            contractId: props?.contract_id,
            unitId: props?.unit_id,
            amount: props.amount,
            termName: props.term_name,
            termNameEng: props.term_name_eng,
            termId: props.term_id,
            companyId: props.company_id,
            projectId: props.project_id,
            customerId: props.customer_id,
            token: token,
            dccCurrency: dcc_currency
        })

        if (res.data.data) {
            let urlDecode = decodeURIComponent(res.data.data.url);
            window.open(urlDecode);
        }
        props.refreshPage()
    }

    useEffect(() => {
        createScript()
    }, [
        createScript
    ])

    useEffect(() => {
        if (createdScript === true) {
            setTimeout(() => {
                // window.KPayment.setAmount(parseFloat(IsDetail.Amount).toFixed(2));
                window.KPayment.setAmount(parseFloat(props?.amount).toFixed(2));
                window.KPayment.show();
                // document.getElementsByClassName("header-right[_ngcontent-c2]").style.display = "none"
            }, 1500);
        }
    }, [createdScript, props?.amount]);

    return <>
        <form ref={ref} action="#" onSubmit={(e) => {
            e.preventDefault()
            reciveCallback(e)
        }}></form>
    </>

}