
import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Avatar,
    Card,
    Container,
    Typography,
    Snackbar,
    Box,
    CardContent,
    Button,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material'
import {
    ArrowForwardIos,
    AccountBoxOutlined,
    HomeOutlined,
    LanguageOutlined,
    PrivacyTipOutlined,
    InfoOutlined,
    NotificationsOutlined,
    LogoutOutlined
} from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { ProfileContext } from '../../contexts/ProfileContext';
import * as dict from '../../dictionary'
import { Language } from '../Language'
import liff from '@line/liff';
import * as api from '../../api/Profile'
import { LoadingContext, SET_LOADING } from '../../contexts/LoadingContext';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import MasterPage from '../../ui-component/MasterPage';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Profile = () => {
    const [profile, setProfile] = useState(null)
    const [isVisibleLanguage, setIsVisibleLanguage] = useState(false)
    const [isLogoutPopUp, setIsLogoutPopUp] = useState(false);
    const [isErrorLogout, setIsErrorLogout] = useState(false)
    const [isErrorNotiUpdate, setIsErrorNotiUpdate] = useState(false)
    const [isNotiUpdate, setIsNotiUpdate] = useState(false)
    const [notiSwitch, setNotiSwitch] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const theme = useTheme()
    const { dispatch } = useContext(LoadingContext)
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const handleChooseLanguage = () => {
        setIsVisibleLanguage(false)
    }



    const logoutService = async () => {
        let res = await api.logout({
            "line_id": profile?.line_id
        })

        if (res.data?.status !== 200) {
            setIsErrorLogout(true)
            return
        }

        // liff.logout()
        localStorage.clear()
        liff.closeWindow()
        return
    }


    const handleNotificationUpdate = async (notiBoolean) => {

        let res = await api.notificationUpdate({
            "line_id": profile?.line_id,
            "is_noti": notiBoolean
        })

        if (res?.data?.status === 200) {
            return setIsNotiUpdate(true)
        }
        return setIsErrorNotiUpdate(true)

    }

    const handleLogoutAccept = () => {
        // if (liff.isLoggedIn()) {
        logoutService()
    }


    useEffect(() => {

        if (context.state?.profile?.customer.length === 0) {
            navigate('/noprofile')
        }


        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])

    useEffect(() => {
        if (profile !== null) {
            setNotiSwitch(profile?.is_noti)
        }

        dispatch({
            type: SET_LOADING,
            payload: profile ? false : true
        })

    }, [profile, dispatch]);

    useEffect(() => {

        let pictureCache = localStorage.getItem("pictureURL")
        if (pictureCache) setAvatar(pictureCache)

    }, [])


    return (
        <MasterPage>
            <Box sx={{ backgroundColor: theme.palette.primary.main, height: "100px" }}>

            </Box>
            <Box sx={{ transform: "translateY(-60%)", height: 150 }}>
                <Stack justifyContent={"center"} direction={"row"}>
                    <Typography color={theme.palette.primary.contrastText}>
                        {
                            dict.HeaderProfile_TXT[profile?.language]
                        }
                    </Typography>
                </Stack>
                <Stack justifyContent={"center"} direction={"column"} alignItems={"center"}>
                    <Avatar src={avatar} sx={{ width: 100, height: 100, margin: 1 }}>
                    </Avatar>
                    <Card sx={{ boxShadow: 0, height: 50 }}>
                        <CardContent>
                            <Stack justifyContent={"center"} direction={"row"} flexWrap={"wrap"} spacing={1}>
                                <Typography variant={"h6"} color={"black"}>
                                    {profile?.language === "th" ? profile?.firstname : profile?.firstname_en || "-"}
                                </Typography>
                                <Typography variant={"h6"} color={"black"}>
                                    {profile?.language === "th" ? profile?.lastname : profile?.lastname_en || "-"}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Box>

            <Box  >
                <Container maxWidth="xl" >
                    <Box maxWidth="xl"  >
                        <Stack mt={1} spacing={1}>


                            <Card
                                onClick={() => navigate(`/profile/project${location.search}`)}
                                sx={{ boxShadow: 0, backgroundColor: "#F1F7FA" }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <HomeOutlined />
                                            <Typography>
                                                {dict.Own_Project_TXT[profile?.language]}
                                            </Typography>

                                        </Stack>
                                        <ArrowForwardIos sx={{ fontSize: 15 }} />
                                    </Stack>
                                </CardContent>
                            </Card>


                            <Card
                                onClick={() => navigate(`/profile/profileDetail${location.search}`)}
                                sx={{ boxShadow: 0, backgroundColor: "#F1F7FA" }}>
                                <CardContent >
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <AccountBoxOutlined />
                                            <Typography>
                                                {dict.Profile_TXT[profile?.language]}
                                            </Typography>

                                        </Stack>
                                        <ArrowForwardIos sx={{ fontSize: 15 }} />
                                    </Stack>

                                </CardContent>
                            </Card>

                            <Card onClick={() => setIsVisibleLanguage(true)} sx={{ boxShadow: 0, backgroundColor: "#F1F7FA" }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <LanguageOutlined />
                                            <Typography>{dict.Change_Lang_TXT[profile?.language]}</Typography>
                                        </Stack>
                                        <ArrowForwardIos sx={{ fontSize: 15 }} />

                                    </Stack>

                                </CardContent>
                            </Card>
                            <Card
                                onClick={() => navigate(`/profile/policy${location.search}`)}
                                sx={{ boxShadow: 0, backgroundColor: "#F1F7FA" }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <PrivacyTipOutlined />
                                            <Typography>{dict.Privacy_Policy_TXT[profile?.language]}</Typography>
                                        </Stack>
                                        <ArrowForwardIos sx={{ fontSize: 15 }} />
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Card
                                onClick={() => navigate(`/profile/term${location.search}`)}
                                sx={{ boxShadow: 0, backgroundColor: "#F1F7FA" }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <InfoOutlined />
                                            <Typography>{dict.Condition_TXT[profile?.language]}</Typography>
                                        </Stack>

                                        <ArrowForwardIos sx={{ fontSize: 15 }} />

                                    </Stack>

                                </CardContent>
                            </Card>

                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} spacing={1}>

                                            <NotificationsOutlined />
                                            <Typography>{dict.Notification_TXT[profile?.language]}</Typography>
                                        </Stack >

                                        <Switch checked={notiSwitch} onChange={(e) => {
                                            setNotiSwitch(e.target.checked)
                                            handleNotificationUpdate(e.target.checked)
                                        }} />
                                    </Stack>

                                </CardContent>
                            </Card>
                            <Card onClick={() => setIsLogoutPopUp(true)} sx={{ boxShadow: 0 }}>
                                <CardContent>
                                    <Stack direction={"row"} justifyContent={"flex-start"} spacing={2}>
                                        <LogoutOutlined sx={{ color: "#E80400" }} />
                                        <Typography style={{ color: "#E80400" }}>{dict.Logout_TXT[profile?.language]}</Typography>
                                    </Stack>

                                </CardContent>
                            </Card>
                        </Stack>

                        <Stack mt={10} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant="caption">Customer Portal © 2022</Typography>
                            <Typography variant="caption">{process.env.REACT_APP_VERSION}</Typography>
                        </Stack>

                    </Box>

                </Container>

            </Box>


            <Language
                isVisible={isVisibleLanguage}
                response={(lang) => handleChooseLanguage(lang)}
                onClose={() => {
                    setIsVisibleLanguage(false)
                    window.location.reload()
                }}
                defaultLanguage={profile?.language}
            />

            <Dialog open={isLogoutPopUp} onClose={() => setIsLogoutPopUp(false)} >
                <DialogTitle >
                    {dict.Logout_TXT[profile?.language]}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dict.Question_Logout_TXT[profile?.language]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        onClick={() => setIsLogoutPopUp(false)}
                    >
                        {dict.CancelAction_TXT[profile?.language]}
                    </Button>
                    <Button
                        onClick={() => handleLogoutAccept()}
                        variant={"contained"}
                    >
                        {dict.OK_TXT[profile?.language]}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={isErrorLogout} autoHideDuration={6000} onClose={() => setIsErrorLogout(false)}>
                <Alert severity="error"><Typography variant="body2">{dict.Error_Logout_TXT[profile?.language]}</Typography></Alert>
            </Snackbar>

            <Snackbar open={isErrorNotiUpdate} autoHideDuration={6000} onClose={() => setIsErrorNotiUpdate(false)}>
                <Alert severity="error"><Typography variant="body2">{dict.Error_Noti_Set_TXT[profile?.language]}</Typography></Alert>
            </Snackbar>

            <Snackbar open={isNotiUpdate} autoHideDuration={6000} onClose={() => setIsNotiUpdate(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    <Typography variant="body2">{notiSwitch === true ? `${dict.Noti_Open_Success_TXT[profile?.language]}` : `${dict.Noti_Close_Success_TXT[profile?.language]}`}</Typography>
                </Alert>
            </Snackbar>
        </MasterPage>
    )

}
