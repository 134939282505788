import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Stack, styled, Card, Button } from '@mui/material'
import { Container } from '@mui/system'
import { ProfileContext } from '../../contexts/ProfileContext'
import { BlankPage } from '../../ui-component'
import * as api from '../../api/Unit'
import { useLocation } from 'react-router-dom';
import { BackDropLoading } from '../../ui-component'
import { ModalError } from '../../ui-component'
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType'
export const ContractInfo = () => {
    const context = useContext(ProfileContext)
    const [info, setInfo] = useState(null)
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation()
    let params = new URLSearchParams(location.search);
    let isProfileBtnShowDocument = params.get("isProfileBtnShowDocument")


    const handleClickDownload = async ({ process, doc_name }) => {
        setLoading(true)
        let res = await api.getUnitDoc({
            "contract_id": location.state?.contractDetail.contract_id,
            "line_id": profile?.line_id,
            "product_type": location.state?.contractDetail.product_type,
            "process": process,
            "doc_name": doc_name
        })
        if (res?.status === 200) {
            window.open(res?.data?.data.doc_url)
            setLoading(false)
            return
        }

        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }

    useEffect(() => {

        const loadDetail = async () => {
            setLoading(true)
            let res = await api.getUnitDetail({
                "unit_id": location.state?.contractDetail.unit_id,
                "line_id": profile?.line_id,
                "language": profile?.language,
                "product_type": location.state?.contractDetail.product_type
            })
    
            if (res?.status === 200) {
                setInfo(res.data)
                setLoading(false)
                return
            }
    
            setError(res)
            setModalError(true)
            setLoading(false)
            return
    
        }

        if (profile !== null) {
            loadDetail()
        }

    }, [profile, location.state]);

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context]);

    return (
        <Box sx={{ marginTop: 10 }} >
            {
                info !== null && <Container>
                    <CardNormal >
                        <Stack spacing={1} direction={"column"} >
                            <Typography variant={"h6"}>{dict.Contract_Detail_TXT[profile?.language]}</Typography>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body1"}>{dict.Contract_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body1"} fontWeight={"bold"}>{info?.customer_name || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Booking_Number_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.booking_number || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Contract_Number_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.contract_number || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Contract_Date_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.contract_date || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Contract_Code_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.contract_id || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                        </Stack>
                    </CardNormal>

                    <CardNormal sx={{ mt: 1 }}>
                        <Stack spacing={1} direction={"column"} >

                            <Typography variant={"h6"}>{dict.Room_Info_TXT[profile?.language]}</Typography>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body1"}>{dict.Project_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body1"} fontWeight={"bold"}>{info?.project_name || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Book_Date_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.reserve_date || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Plan_Room_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.unit_number || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{checkProductType() === "rental" ? dict.UnitNo_TXT[profile?.language] : dict.House_Number_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{checkProductType() === "rental" ? info?.unit_number : info?.house_number}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                            <BoxFlex>
                                <TextLabelLeft>
                                    <Typography variant={"body2"}>{dict.Plan_Design_TXT[profile?.language]}</Typography>
                                </TextLabelLeft>
                                <TextLabelRight>
                                    <Typography variant={"body2"} fontWeight={"bold"}>{info?.model_name || "-"}</Typography>
                                </TextLabelRight>
                            </BoxFlex>
                        </Stack>

                    </CardNormal>

                    {
                        isProfileBtnShowDocument === "true" && <Stack direction={"row"} spacing={1} sx={{ pl: 2, pr: 2 }} mt={2} >
                            <ButtonDownload variant="outlined" onClick={() => handleClickDownload({ process: "booking", doc_name: "ใบจอง" })}>
                                <Typography variant='body2'>
                                    {dict.Doc_Reservation_TXT[profile?.language]}
                                </Typography>
                            </ButtonDownload>
                            <ButtonDownload variant="outlined" onClick={() => handleClickDownload({ process: "contract", doc_name: "สัญญาจะซื้อจะขาย" })}>
                                <Typography variant='body2'>
                                    {dict.Doc_Contract_TXT[profile?.language]}
                                </Typography>
                            </ButtonDownload>
                        </Stack>
                    }

                </Container>
            }
            {
                (info === null && loading === false) && <BlankPage />
            }

            <BackDropLoading
                loading={loading}
            />
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </Box>
    )
}

const CardNormal = styled(Card)(({ theme }) => ({
    borderRadius: "10px",
    boxShadow: "0px 0px 7px 0px #00000040",
    padding: "20px"
}))

const ButtonDownload = styled(Button)(({ theme }) => ({
    width: "100%",
    minHeight: "50px",
    borderRadius: "10px",
    border: "1px",
    boxShadow: "0px 0px 7px 0px #00000040",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.main
    }
}))

const BoxFlex = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
})
const TextLabelLeft = styled(Box)({
    flexShrink: 0
})

const TextLabelRight = styled(Box)({
    flex: 1,
    textAlign: "right",
    overflowWrap: "anywhere"
})
