import React from "react";

export const LoadingContext = React.createContext();

const initialState = {
    isLoading : true
};

const LoadingReducer = (state, action) => {

    switch (action.type) {
        case "SET_LOADING": return {
            ...state, isLoading: action.payload
        }
        default: return
    }
}


export const LoadingProvider = props => {
    const [state, dispatch] = React.useReducer(LoadingReducer, initialState);
    const value = { state, dispatch };

    return (
        <LoadingContext.Provider value={value}>
            {props.children}
        </LoadingContext.Provider>
    );
}

export const SET_LOADING = "SET_LOADING"
