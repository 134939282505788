import React, { useState, useEffect, useContext } from 'react'
import { ProfileContext } from '../../contexts/ProfileContext';
import * as api from '../../api/Loan'
import { ModalError } from '../../ui-component';
import { BlankPage } from '../../ui-component';
import { BackDropLoading } from '../../ui-component';
import { LoanDetail } from './LoanDetail';
export const LoanApprove = () => {

    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [detail, setDetail] = useState(null)
    const [modalError, setModalError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const context = useContext(ProfileContext)

    const loadDetail = async () => {
        setLoading(true)
        let res = await api.getLoanCustomerChoose({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id,
            "unit_id": houseInfo?.unit_id
        })
        if (res?.status === 200) {
            setDetail(res.data)
            setLoading(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }
    useEffect(() => {
        if (profile !== null) {
            loadDetail()
        }
    }, [profile, houseInfo]);
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])

    return (
        <div>
            {
                detail === null ? <BlankPage lang={profile?.language} /> : <LoanDetail detail={detail} profile={profile}/>
            }
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
             <BackDropLoading
                loading={loading}
            />
        </div>
    )
}

