import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LanguageContext } from '../contexts/LanguageContext';
import * as dict from '../dictionary'

export const DialogConfirm = (props) => {
    const { onClose, onOk, titleText, titleDescription, trigger } = props
    const langCtx = useContext(LanguageContext)
    const [langSelected, setLangSelected] = useState("TH")
    useEffect(() => {

        if (langCtx) {
            let { languageSelected } = langCtx.state
            setLangSelected(languageSelected)
        }


    }, [langCtx])

    return (
        <>
            <Dialog
                open={(trigger || false)}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            
            >
                <DialogTitle id="alert-dialog-title">
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {titleDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>{dict.CancelBtn_TXT[langSelected]}</Button>
                    <Button onClick={() => onOk()} autoFocus>
                        {dict.AcceptBtn_TXT[langSelected]}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
