export const Payment_Date_TXT = []
Payment_Date_TXT["en"] = "Payment date"
Payment_Date_TXT["th"] = "วันที่ชำระเงิน"

export const Pay_By_TXT = []
Pay_By_TXT["en"] = "Pay by"
Pay_By_TXT["th"] = "ชำระโดย"

export const System_Check_Payment_TXT = []
System_Check_Payment_TXT["en"] = "The system is checking the payment amount."
System_Check_Payment_TXT["th"] = "ระบบกำลังตรวจสอบยอดชำระเงิน"

export const Still_In_Processs_TXT = []
Still_In_Processs_TXT["en"] = "The system is checking"
Still_In_Processs_TXT["th"] = "ระบบกำลังตรวจสอบข้อมูล"

export const Still_In_Processs_2_TXT = []
Still_In_Processs_2_TXT["en"] = "the payment."
Still_In_Processs_2_TXT["th"] = "การชำระเงินของท่าน"