import React, { useState, useEffect, useContext } from 'react'
import { Typography, Stack, Container, Chip, Paper, Box } from '@mui/material'
import { HolidayVillage, ArrowForwardIos } from '@mui/icons-material'
import { ProfileContext } from '../contexts/ProfileContext'
import { ReactComponent as PdfLogo } from '../assets/images/logo/pdfLogo.svg'
import { ReactComponent as StarLogo } from '../assets/images/logo/star.svg'
import { useTheme, styled } from '@mui/material/styles';
import { BackDropLoading } from '../ui-component'
import * as dict from '../dictionary'
import * as api from '../api'
import { checkProductType } from '../utils/checkProductType'
import { ChipRoomInfo } from '../ui-component/ChipRoomInfo'
export const Equipment = () => {
    const theme = useTheme()
    const [profile, setProfile] = useState(null)
    const [loading,setLoading] = useState(false)
    const [houseInfo, setHouseInfo] = useState(null)
    const [data, setData] = useState({
        deliver_date: "",
        deliver_status: "",
        document_pdf_url: "",
        satisfaction_url: ""
    })
    let houseInfoSelected = localStorage.getItem("houseInfoSelected")
    let houseInfoParse = JSON.parse(houseInfoSelected)
    const context = useContext(ProfileContext)
    const productType = checkProductType()


    const loadData = async () => {
        setLoading(true)
        let res = await api.getEquipmentDelivery({
            line_id: profile?.line_id,
            product_type: houseInfo?.product_type,
            project_id: houseInfo?.project_id,
            house_number: houseInfo?.house_number
        })

        setData(res.data?.data)
        setLoading(false)
    }

    useEffect(() => {

        if (profile !== null) {
            loadData()
        }

    }, [profile, houseInfo]);

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])
    return (
        <Container maxWidth="xl" >
            <Stack direction={"row"} alignItems="center" justifyContent={"flex-start"}>
                   <ChipRoomInfo
                        houseInfo={houseInfo}
                        houseInfoParse={houseInfoParse}
                        icon={<HolidayVillage/>}
                        lang={profile?.language}
                        sx={{
                            mt: 3
                        }}
                    />
            </Stack>

            <Stack direction="column" sx={{ mb: 2, mt: 3 }}>
                {
                    data?.document_pdf_url &&
                    <PaperCustom sx={{ p: 2, mt: 1 }} onClick={() => window.open(data?.document_pdf_url)}>
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>

                            <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                <BackLogo >
                                    <PdfLogo style={{ width: 19, height: 20, fill: theme.palette.secondary.main }} />
                                </BackLogo>
                                <Typography fontSize={"14px"} fontWeight={700} color={theme.palette.primary.main}> {dict.Equipment_Delivery_Report_TXT[profile?.language]} </Typography>

                            </Stack>
                            <ArrowForwardIos sx={{ color: theme.palette.primary.main }} />
                        </Stack>
                    </PaperCustom>

                }

                {
                    data?.satisfaction_url &&
                    <PaperCustom sx={{ p: 2, mt: 1 }} onClick={() => window.open(data?.satisfaction_url)}>
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>

                            <Stack direction={"row"} spacing={2}>

                                <BackLogo >
                                    <StarLogo style={{ width: 19, height: 20, fill: theme.palette.secondary.main }} />
                                </BackLogo>
                                <Box>
                                    <Typography fontSize={"14px"} fontWeight={700} color={theme.palette.primary.main}> {dict.Equipment_Review_TXT[profile?.language]} </Typography>
                                    <Typography fontSize={"10px"} fontWeight={400} color={"#828282"}>{dict.Equipment_Review_SubTitle_TXT[profile?.language]}</Typography>

                                </Box>

                            </Stack>
                            <ArrowForwardIos sx={{ color: theme.palette.primary.main }} />
                        </Stack>
                    </PaperCustom>
                }


            </Stack>
            <BackDropLoading
                loading={loading}
            />
        </Container>
    )
}

const BackLogo = styled(Box)(({ theme }) => ({
    borderRadius: 100,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const PaperCustom = styled(Paper)({
    boxShadow: "0px 0px 7px 0px #00000040",
    borderRadius: "10px",
    "&:active": {
        backgroundColor: "#1D1C1A"
    }
})