import axios from "axios";

export const getListTransferInfo = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/list`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "type": "transferInfo"
            }
        })
        return res
    } catch (error) {
        return error
    }
}

export const getDetailTransferInfo = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/detail`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "id": data.id,
            }
        })
        return res
    } catch (error) {
        return error
    }
}


export const getListLetter = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/transfer/letter/list`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "unit_id": data.unit_id,
                "contract_id": data.contract_id
            }
        })
        return res
    } catch (error) {
        return error
    }
}


export const getExpenseTransfer = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/transfer/detail`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "unit_id": data.unit_id,
                "contract_id": data.contract_id 
            }
        })
        return res
    } catch (error) {
        return error
    }
}