import React, { useState, useEffect, useContext } from 'react'
import {
    Stack, Divider, Button, Dialog, DialogTitle, List, FormControl, FormGroup, FormControlLabel,
    Typography, Box, TextField, Alert, Checkbox
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller} from "react-hook-form";
import { ProfileContext } from '../../../contexts/ProfileContext'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import * as api from '../../../api/'
import moment from 'moment';
import * as dict from '../../../dictionary'

export const FormBooking = () => {
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [optFacility, setOptFacility] = useState([])
    const [isSelectTime, setIsSelectTime] = useState(false)
    const [hourAmount, setHourAmount] = useState(0)
    const [optionSelected, setOptionSelected] = useState([])
    const [firstChecked, setFirstChecked] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { handleSubmit, control, getValues, setValue } = useForm();
    const context = useContext(ProfileContext)
    const initDisableStyle = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
    }
    const [disabledStyle, setDisabledStyle] = useState(initDisableStyle)
    const [checkedValue, setCheckedValue] = useState(initDisableStyle)
    const timeTable = [
        "08:00 - 09:00",
        "09:00 - 10:00",
        "10:00 - 11:00",
        "11:00 - 12:00",
        "12:00 - 13:00",
        "13:00 - 14:00",
        "14:00 - 15:00",
        "15:00 - 16:00",
        "16:00 - 17:00",
        "17:00 - 18:00",
        "18:00 - 19:00",
        "19:00 - 20:00",
        "20:00 - 21:00",
        "21:00 - 22:00"
    ]
    const [timeArray, setTimeArray] = useState([])
    const [timeArraySave, setTimeArraySave] = useState({
        start: "",
        end: ""
    })
    const loadOption = async (id) => {
        let res = await api.getOptionFacility({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "facilities_id": id
        })
        setOptFacility(res.data?.body)
    }
    const onError = (errors, e) => console.log(errors)
    const handleSaveBooking = async () => {
        let form = getValues()
        let date = moment(form.date).format("DD/MM/YYYY")
        navigate('/facility/confirmBooking' + location.search, {
            state: {
                "line_id": profile?.line_id,
                "language": profile?.language,
                "product_type": houseInfo?.product_type,
                "facilities_id": location.state?.id,
                "date": date,
                "bookin_start": timeArraySave?.start,
                "booking_end": timeArraySave?.end,
                "hour_amount": hourAmount,
                "house_number": houseInfo.house_number,
                "optionSelected": optionSelected,
                "project_id": houseInfo.project_id,
                "project_name": houseInfo.project_name,
                "total_price": 0,
                "unit_id": form.unit_id
            }
        })

    }
    const handleDisabled = (result) => {
        let temp = { ...disabledStyle }
        Object.keys(temp).forEach(key => temp[key] = true)
        result.forEach(element => {
            temp[element] = false
            temp[element + 1] = false
        });
        setDisabledStyle(temp)
        setTimeArray(result)
    }
    const openDisableContinue = (index) => {
        let temp = { ...disabledStyle }
        temp[index + 1] = false
        setDisabledStyle(temp)
    }
    const closeDisableContinue = (index) => {
        let temp = { ...disabledStyle }
        let temp2 = { ...checkedValue }
        Object.keys(temp).forEach(key => {  if(key > index) temp[key] = true })
        Object.keys(temp2).forEach(key => {  if(key >= index) temp2[key] = false })
        setDisabledStyle(temp)
        setCheckedValue(temp2)
    }
    const handleDisabledReverse = (result) => {
        let temp = { ...disabledStyle }
        if (result.length < 3) {
            Object.keys(temp).forEach(key => temp[key] = false)
        }
        setDisabledStyle(temp)
        setTimeArray(result)
    }
    const calculateStartTimeAndEndTime = () => {
        if (timeArray.length < 2) return
        let startIndexTime = Math.min(...timeArray)
        let endIndexTime = Math.max(...timeArray)
        let start = timeTable[startIndexTime - 1].split(" - ")[0]
        let end = timeTable[endIndexTime - 1].split(" - ")[1]
        setTimeArraySave({
            start: start,
            end: end
        })
    }
    const handleOnCheckTime = (index) => {
        if (index === firstChecked) {
            let tempCheck = { ...checkedValue }
            Object.keys(tempCheck).forEach(key => {  if(key >= index) tempCheck[key] = false })
            setCheckedValue(tempCheck)
            setTimeArray([])
            setFirstChecked(null)
            return
        }
        setCheckedValue({
            ...checkedValue,
            [index]: !checkedValue[index]
        })
        let temp = [...timeArray]
        let findDuplicate = temp.some(element => element === index)
        let result = []
        if (findDuplicate === true) {
            result = temp.filter(ele => ele !== index)
            setTimeArray(result)
            closeDisableContinue(index)
            return
        }
        result = [...temp, index]
        if (result.length === 1) {
            setFirstChecked(index)
            return handleDisabled(result)
        }
        if(result.length > 1){
            openDisableContinue(index)
        }
        setTimeArray(result)
        return
    }

    const handleOptionSelected = (optionName, optionId) => {
        let temp = [...optionSelected]
        let optTemp = []
        let findDuplicate = temp.some(element => element.value === optionId)
        if (findDuplicate === true) {
            optTemp = temp.filter(ele => ele.value !== optionId)
            setOptionSelected(optTemp)
            return
        }
        optTemp = [
            ...optionSelected,
            {
                name: optionName,
                value: optionId
            }
        ]
        setOptionSelected(optTemp)
    }


    useEffect(() => {
        if (timeArray.length === 0) {
            handleDisabledReverse(timeArray)
        }
        if (timeArray.length === 1) {
            let timeST = timeTable[timeArray - 1].split(" - ")[0]
            let timeET = timeTable[timeArray - 1].split(" - ")[1]
            setTimeArraySave({
                start: timeST,
                end: timeET
            })
        }
        if (timeArray.length > 1) {
            calculateStartTimeAndEndTime()
        }
    }, [timeArray]);


    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])

    useEffect(() => {

        if (profile !== null && houseInfo !== null) {
            loadOption(location.state?.id)
        }
    }, [profile, houseInfo]);
    return (
        <Box sx={{ height: "100vh", marginTop: 10, padding: 1 }}>
            <Stack direction={"row"} mb={1} >
                <Typography variant="h5">{dict.Specify_Date_Book_TXT[profile?.language]}</Typography>
            </Stack>
            <Box
                onSubmit={handleSubmit(handleSaveBooking, onError)}
                component="form"
                id="hook-form"
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={2} direction="column" >
                        <Controller
                            name="date"
                            control={control}
                            rules={{ required: `${dict.Fix_Specify_Date_Book_TXT[profile?.language]}` }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <MobileDatePicker
                                        label={`${ dict.Date_TXT[profile?.language] }`}
                                        inputFormat='DD/MM/YYYY'
                                        value={moment(value, "DD/MM/YYYY")}
                                        onChange={onChange}
                                        renderInput={(params) => <TextField onChange={onChange} {...params} />}
                                    />
                                    {
                                        error !== undefined && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Fix_Select_Date_Book_TXT[profile?.language]}</Alert></Box>
                                    }
                                </>
                            )}
                        />
                        <Controller
                            name="timeStart"
                            control={control}
                            defaultValue=""
                            rules={{ required: `${dict.Fix_Specify_Time_Book_TXT[profile?.language]}` }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (<>
                                <TextField onClick={() => setIsSelectTime(true)} value={value} />
                                {
                                    (timeArraySave.start == "" && timeArraySave.end == "" && error !== undefined) && <Box sx={{ marginTop: 1 }}><Alert severity="error" >{dict.Fix_Specify_Time_Book_TXT[profile?.language]}</Alert></Box>
                                }
                            </>
                            )}
                        />
                    </Stack>
                </LocalizationProvider>
                <Box sx={{ width: "100%", marginTop: 3 }} >
                    <Divider textAlign="left" >  <Typography variant="h6">{dict.Accessories_TXT[profile?.language]}</Typography> </Divider>
                    <Box sx={{ padding: 2, marginTop: 1 }}>
                        {
                            optFacility.length > 0 && optFacility?.map((opt, index) => {
                                return <FormControlLabel
                                    key={index}
                                    value={opt.optionId}
                                    label={opt.optionName}
                                    onChange={() => handleOptionSelected(opt.optionName, opt.optionId)}
                                    control={
                                        <Checkbox
                                            sx={{ fontSize: 35 }}
                                            key={index}
                                        />
                                    } />
                            })
                        }
                        {
                            optFacility.length === 0 && <Typography variant="caption">{dict.No_Accessories_TXT[profile?.language]}</Typography>
                        }
                    </Box>
                </Box>
                <Typography variant="caption" sx={{ color: "#b30000" }} >
                    *{dict.Please_Confirm_Before_TXT[profile?.language]}
                    **{dict.Cancel_Book_Auto_TXT[profile?.language]}
                </Typography>
                <Stack direction={"row"} justifyContent="center" mt={4} mb={4}>
                    <Button variant="contained" type="submit" sx={{ width: "100%" }}> {dict.Next_TXT[profile?.language]} </Button>
                </Stack>
            </Box>
            <Dialog open={isSelectTime} onClose={() => {
                setDisabledStyle(initDisableStyle)
                setCheckedValue(initDisableStyle)
                setFirstChecked(null)
                setHourAmount(timeArray.length)
                setTimeArray([])
                setIsSelectTime(false)
                setValue('timeStart', `${timeArraySave.start}-${timeArraySave.end}`)
            }}>
                <DialogTitle>{dict.Select_Book_Time_TXT[profile?.language]}</DialogTitle>
                <List sx={{ pt: 0 }}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(1)}
                                control={
                                    <Checkbox disabled={disabledStyle[1]} name="range1" checked={checkedValue[1]} />
                                }
                                label="08:00 - 09:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(2)}
                                control={
                                    <Checkbox disabled={disabledStyle[2]} name="range2" checked={checkedValue[2]} />
                                }
                                label="09:00 - 10:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(3)}
                                control={
                                    <Checkbox disabled={disabledStyle[3]} name="range3" checked={checkedValue[3]} />
                                }
                                label="10:00 - 11:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(4)}
                                control={
                                    <Checkbox disabled={disabledStyle[4]} name="range4" checked={checkedValue[4]} />
                                }
                                label="11:00 - 12:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(5)}
                                control={
                                    <Checkbox disabled={disabledStyle[5]} name="range5" checked={checkedValue[5]}/>
                                }
                                label="12:00 - 13:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(6)}
                                control={
                                    <Checkbox disabled={disabledStyle[6]} name="range6" checked={checkedValue[6]}/>
                                }
                                label="13:00 - 14:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(7)}
                                control={
                                    <Checkbox disabled={disabledStyle[7]} name="range7" checked={checkedValue[7]}/>
                                }
                                label="14:00 - 15:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(8)}
                                control={
                                    <Checkbox disabled={disabledStyle[8]} name="range8" checked={checkedValue[8]}/>
                                }
                                label="15:00 - 16:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(9)}
                                control={
                                    <Checkbox disabled={disabledStyle[9]} name="range9" checked={checkedValue[9]}/>
                                }
                                label="16:00 - 17:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(10)}
                                control={
                                    <Checkbox disabled={disabledStyle[10]} name="range10" checked={checkedValue[10]}/>
                                }
                                label="17:00 - 18:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(11)}
                                control={
                                    <Checkbox disabled={disabledStyle[11]} name="range11" checked={checkedValue[11]} />
                                }
                                label="18:00 - 19:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(12)}
                                control={
                                    <Checkbox disabled={disabledStyle[12]} name="range12" checked={checkedValue[12]} />
                                }
                                label="19:00 - 20:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(13)}
                                control={
                                    <Checkbox disabled={disabledStyle[13]} name="range13" checked={checkedValue[13]} />
                                }
                                label="20:00 - 21:00"
                            />
                            <FormControlLabel
                                onChange={() => handleOnCheckTime(14)}
                                control={
                                    <Checkbox disabled={disabledStyle[14]} name="range14" checked={checkedValue[14]} />
                                }
                                label="21:00 - 22:00"
                            />
                        </FormGroup>
                    </FormControl>
                </List>
            </Dialog>
        </Box>
    )
}
