import React, { useEffect, useState, useContext } from 'react'
import {
    Dialog, Typography, Card, CardContent,
    Box, Stack, Container, styled, Button, Divider, Grid,
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import {
    Status_TXT,
    Date_Report_TXT,
    Detail_TXT,
    Picture_TXT,
    ConfirmTitle_TXT,
    ConfirmContent_TXT,
    ConfirmAction_TXT,
    CancelAction_TXT,
    Reporter_TXT
} from '../../dictionary/Defect';
import { useLocation } from 'react-router-dom';
import * as api from '../../api/Defect'
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';
import { useTheme } from '@mui/material/styles';
import { StatusTag } from './StatusTag';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from 'moment';
import 'moment/locale/th'

export const DefectDetail = () => {

    const [detail, setDetail] = useState(null)
    const [alertDialogCancel, setAlertDialogCancel] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null);
    const theme = useTheme()
    const context = useContext(ProfileContext)
    const location = useLocation()
    const typeOpenReviewBtn = ["ปิดใบงาน", "complete", "ปิดใบงาน (มีเงื่อนไข)", "complete-t"]
    const handleCloseDialogCancel = () => setAlertDialogCancel(false)

    const handleDefectCancel = () => {
        handleCloseDialogCancel()
        let res = api.updateDefect({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": houseInfo?.product_type,
            "defect_id": detail.defect_id,
            "status": "Cancel",
            "remark": "ยกเลิกการแจ้งซ่อม",
        })

    }

    useEffect(() => {
        setDetail(location.state)
    }, [location.state])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])

    return (
        <div>
            <Container sx={{ marginTop: 8 }}>
                <Stack direction={"row"} justifyContent={"flex-start"}>
                    <Typography variant="h5" mt={2}> {detail?.subject} </Typography>
                </Stack>
                <Box mt={2}>
                    <Card variant="outlined" sx={{ width: "100%", boxShadow: "0px 0px 7px 0px #00000040" }}>
                        <CardContent>
                            <Stack direction={"row"} justifyContent={"space-between"} >

                                <Typography variant='caption' >
                                    {Status_TXT[profile?.language]}
                                </Typography>
                                <Typography variant='caption' >
                                    <StatusTag text={detail?.status} />
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} >
                                <Typography variant='caption' >
                                    {Date_Report_TXT[profile?.language]}
                                </Typography>
                                <Typography variant='caption' >
                                    {(detail !== null && profile) ? moment(detail?.issue_date).locale(profile?.language).format('LLL') : "-"}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} >

                                <Typography variant='caption' >
                                    {Reporter_TXT[profile?.language]}
                                </Typography>
                                <Typography variant='caption' >
                                    {detail?.customer_name}
                                </Typography>
                            </Stack>
                            <Stack mt={2} direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                                <Typography variant='h6' >
                                    {Detail_TXT[profile?.language]}
                                </Typography>
                            </Stack>
                            <Box sx={{ overflow: "scroll", width: "100%" }}>
                                <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                                    <Typography variant='body2' paragraph={true} >

                                        {
                                            detail?.detail
                                        }

                                    </Typography>
                                </Stack>

                            </Box>{
                                location?.state?.version === "fast" && <>
                                <Divider sx={{ marginLeft: -14, marginRight: -14 }} />
                                <Stack direction="row"
                                    justifyContent="center"
                                    mt={2}
                                    spacing={2}
                                >
                                    <Button size="large"
                                        sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", width: "100%" }}
                                        onClick={() => window.open(detail?.document_pdf_url)}
                                    >
                                        {dict.See_Doc_Defect_TXT[profile?.language]}
                                    </Button>
                                    {
                                        typeOpenReviewBtn.includes(detail?.status) &&
                                        <BtnCustom size="large"
                                            onClick={() => window.open(detail?.satisfaction_url)}
                                        >
                                            <Typography color={theme.palette.secondary.main}>
                                                {dict.Review_Defect_TXT[profile?.language]}
                                            </Typography>
                                        </BtnCustom>
                                    }
                                </Stack>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>

                <Box mt={2}>
                    <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                        <Typography fontSize={"14px"} fontWeight={"700"} color={theme.palette.primary.main}>{Picture_TXT[profile?.language]}</Typography>
                        <Typography fontSize={"14px"} fontWeight={"400"} color={theme.palette.primary.main} > {detail?.file_gallery?.length}/5</Typography>
                    </Stack>
                </Box>

                <Box mt={2}>

                    <Grid container direction="row" spacing={2} >
                        {detail?.file_gallery?.map((item) => (
                            <Grid xs={4} item sx={{ zIndex: 10 }} key={item.img}>
                                <BoxHaveImg
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        paddingLeft: "4px",
                                        paddingRight:"4px"
                                    }}>
                                    <Zoom>
                                        <img src={item.url} alt={item.description_th} style={{ width: "100%" ,height: "100%", objectFit: "cover", marginTop: "4px" }} />
                                    </Zoom>
                                </BoxHaveImg>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
            <Dialog
                open={alertDialogCancel}
                onClose={() => setAlertDialogCancel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {ConfirmTitle_TXT[profile?.language]}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ConfirmContent_TXT[profile?.language]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogCancel} >{CancelAction_TXT[profile?.language]}
                    </Button>
                    <Button variant={"outlined"} onClick={handleDefectCancel} color="error">
                        {ConfirmAction_TXT[profile?.language]}
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}

const BoxHaveImg = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    background: `linear-gradient(to right, ${theme.palette.primary.main} 100%, transparent 0%) top repeat-x , linear-gradient(${theme.palette.primary.main} 100%, transparent 60%) right repeat-y, linear-gradient(to right, ${theme.palette.primary.main} 1000%, transparent 0%) bottom repeat-x, linear-gradient(${theme.palette.primary.main} 100%, transparent 0%) left repeat-y`,
    backgroundSize: "20% 3px,3px 20%",
    "border-radius": "5%"
}))

const BtnCustom = styled(Button)(({ theme}) => ({
    borderRadius: "10px", 
    boxShadow: "0px 0px 7px 0px #00000040", 
    width: "100%", 
    backgroundColor: theme.palette.primary.main,
    "&:hover":{
        backgroundColor: theme.palette.primary.main

    }
}))