import axios from "axios";
export const getDetailDoc = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/detail`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "id": data.id,
            }
        })
        return res
    } catch (error) {
        return error
    }
}