import React from "react";
import { Stack, Typography, Card, CardContent, styled } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as dict from "../../dictionary"
function CardStatus(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const color = {
    0: "#0cc4e5",
    1: "#e16c39",
    2: "#1aae6f",
    3: "#f53636",
    4: "#b2b2b2",
    5: "#1aae6f"
  }

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        minHeight: "143px",
        boxShadow: "0px 0px 7px 0px #00000040",
        borderRadius: "10px",
      }}
      onClick={() => navigate(props?.navigate + location.search, { state: props?.state })}
    >
      <CardContent >
        <Stack direction={"column"} spacing={1} >
          <TextCustom variant="subtitle1" fontWeight={"bold"}>
            {props?.bank_name || "-"}
          </TextCustom>
          <Stack direction="row">
            <TextCustom
              variant="body2"
              sx={{ flexShrink: 0 }}
            >
              {dict.Loan_Status_TXT[props?.lang]} :
            </TextCustom>
            <TextCustom
              variant="body2"
              sx={{ color: color[props?.status], wordBreak: "break-word" }}
            >
              &nbsp; {props?.status_name || "-"}
            </TextCustom>
          </Stack>
          <Stack direction="row">
            <TextCustom
              variant="body2"
              sx={{ flexShrink: 0 }}
            >
              {dict.Loan_Status_Customer_TXT[props?.lang]} :
            </TextCustom>
            <TextCustom
              variant="body2"
              sx={{ wordBreak: "break-word" }}
            >
              &nbsp; {props?.status === "5" ? dict.Loan_Customer_Choose_TXT[props?.lang] : "-"}
            </TextCustom>
          </Stack>
          <Stack direction="row">
            <TextCustom
              variant="body2"
              sx={{ flexShrink: 0 }}
            >
              {dict.Date_Status_TXT[props?.lang]} :
            </TextCustom>
            <TextCustom
              sx={{ wordBreak: "break-word" }}
              variant="body2"
            >
              &nbsp; {props?.status_date || "-"}
            </TextCustom>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CardStatus;

const TextCustom = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}))