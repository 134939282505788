import React, { useEffect, useState, useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    Box,  
    Avatar, 
    Card, 
    CardContent, 
    Stack,
    Snackbar,
    Alert,
    Button, 
    TextField, 
    FormControl, 
    FormLabel, 
    FormHelperText, 
    DialogActions
} from '@mui/material'
import { CakeOutlined, PhoneOutlined, EmailOutlined } from '@mui/icons-material'
import { Container } from '@mui/system'
import { ProfileContext, SET_PROFILE } from '../../contexts/ProfileContext'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import moment from 'moment'
import { updateProfile } from '../../api/ProfileDetail'
import { getProfile } from '../../api/Route'
import { useForm, Controller } from "react-hook-form";
import * as dict from '../../dictionary'


export const ProfileDetail = () => {
    const context = useContext(ProfileContext)
    const [isEditPhone, setIsEditPhone] = useState(false)
    const [isEditEmail, setIsEditEmail] = useState(false)
    const [isEditFullName, setIsEditFullName] = useState(false);
    const [isEditBirthDay, setIsEditBirthDay] = useState(false)
    const [dateSelected, setDateSelected] = useState(null);
    const [profile, setProfile] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [phoneValue, setPhoneValue] = useState(null)
    const [isSuccessAlert, setIsSuccessAlert] = useState(false);
    const [isErrorAlert, setIsErrorAlert] = useState(false);
    const { control, getValues } = useForm();

    const handleResponse = async () => {

        let response = await getProfile({
            "line_id": profile?.line_id
        })

        let data = response.data
        context.dispatch({
            type: SET_PROFILE,
            payload: data
        })

    }

    const handleUpdateProfile = async () => {
        let form = getValues()
        let res = await updateProfile({
            "line_id": profile?.line_id,
            "first_name": form.firstname || profile?.firstname,
            "last_name": form.lastname || profile?.lastname,
            "first_name_en": form.first_name_en || profile?.firstname_en,
            "last_name_en": form.last_name_en || profile?.lastname_en,
            "email": form.email || profile?.email,
            "mobile_no": '0'+phoneValue.slice(3) || '0'+profile?.mobile.slice(3),
            "birthday": dateSelected
        })

        if (res.data?.status !== "200") {
            return setIsErrorAlert(true)
        }

        setIsSuccessAlert(true)
        handleResponse()
        return
    }

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
            setPhoneValue('+66'+context.state?.profile?.customer[0].mobile.slice(1))
            setDateSelected(context.state?.profile?.customer[0].birthdate)
        }
    }, [context]);

    useEffect(() => {

        let pictureCache = localStorage.getItem("pictureURL")
        if (pictureCache) setAvatar(pictureCache)

    }, [])


    return (
        <>
            <Box component="form"
                id="hook-form"
                sx={{ width: "100%" , marginTop: 10}}
                autoComplete="off"

            >
                    <Stack mt={4} direction={"column"} alignItems={"center"}>
                        <Box sx={{ display: "flex", alignItems: "end" }}>
                            <Avatar src={avatar} sx={{ width: 100, height: 100, margin: 1 }}>
                            </Avatar>
                        </Box>

                        <Card sx={{ boxShadow: 0, width: "100%" }}>
                            <CardContent>
                                <Box sx={{ display:"flex", justifyContent:"center" , alignItems:"center" }}>
                                        <Typography >
                                            {
                                                profile?.language === 'th' && `${profile?.prefix_name} ${profile?.firstname} ${profile?.lastname} `
                                            }
                                            {
                                                profile?.language === 'en' && `${profile?.prefix_name_en} ${profile?.firstname_en} ${profile?.lastname_en} `
                                            }
                                        </Typography>
                                      
                                    {/* ซ่อนไว้ก่อน <IconButton
                                            onClick={() => setIsEditFullName(true)}
                                            sx={{ position: "static", right: "70px", backgroundColor: "#F1F7FA", opacity: 0.9 }}>
                                            <EditOutlined fontSize='small' />
                                        </IconButton> */}

                                </Box>

                            </CardContent>

                        </Card>
                    </Stack>
                    {/* <DialogContent> */}
                        <Container >

                            <Stack direction={"column"} spacing={2}>
                                <Stack direction={"row"} justifyContent={"space-between"}>

                                    <Stack direction={"row"} spacing={1}>
                                        <CakeOutlined />
                                        <Typography variant={"body1"}>{profile?.birthdate || "-"}</Typography>
                                    </Stack>
                                    {/* ซ่อนไว้ก่อน <Button onClick={() => setIsEditBirthDay(true)} variant={"contained"} size={"small"}><Typography variant='caption'>{dict.Edit_TXT[profile?.language]}</Typography></Button> */}
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>

                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <PhoneOutlined />
                                        <Typography variant={"body1"}>{[profile?.mobile,profile?.mobile_2,profile?.phone_international].filter(phone => phone !== "").join(" , ") || "-"}</Typography>   
                                    </Stack>
                                    {/* ซ่อนไว้ก่อน <Button onClick={() => setIsEditPhone(true)} variant={"contained"} size={"small"}><Typography variant='caption'>{dict.Edit_TXT[profile?.language]}</Typography></Button> */}
                                </Stack>

                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={1}>
                                        <EmailOutlined />
                                        <Typography variant={"body1"}>{profile?.email || "-"}</Typography>
                                    </Stack>

                                    {/* ซ่อนไว้ก่อน <Button onClick={() => setIsEditEmail(true)} variant={"contained"} size={"small"}><Typography variant='caption'>{dict.Edit_TXT[profile?.language]}</Typography></Button> */}
                                </Stack>
                            </Stack>
                        </Container>
                    {/* </DialogContent>

                </Dialog> */}

                <Dialog open={isEditPhone} onClose={() => setIsEditPhone(false)} >
                    <DialogContent>
                        <Controller
                            name="phone"
                            control={control}
                            rules={{ required: `${dict.Pls_Enter_Phone_TXT[profile?.language]}` }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>{dict.Enter_Phone_TXT[profile?.language]}</FormLabel>

                                    <PhoneInput
                                        style={{ height: 35, padding: 10, margin: 10 }}
                                        country={"TH"}
                                        withCountryCallingCode={false}
                                        value={phoneValue}
                                        onChange={(ele) => setPhoneValue(ele)}
                                    />
                                    <FormHelperText>ex.06x xxx xxxx</FormHelperText>
                                </FormControl>)}
                        />

                    </DialogContent>
                    <DialogActions variant="outliend">
                        <Button variant="outlined" onClick={() => setIsEditPhone(false)}>{dict.CancelAction_TXT[profile?.language]}</Button>
                        <Button variant="contained" onClick={() => handleUpdateProfile()}>{dict.Save_TXT[profile?.language]}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isEditEmail} onClose={() => setIsEditEmail(false)} >
                    <DialogContent>

                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: `${dict.Pls_Enter_Email_TXT[profile?.language]}` }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>{dict.Enter_Email_TXT[profile?.language]}</FormLabel>

                                    <TextField onChange={onChange} defaultValue={profile?.email} type="email"></TextField>

                                    <FormHelperText>ex.xxx@xxx.com</FormHelperText>
                                </FormControl>)}
                        />

                    </DialogContent>
                    <DialogActions variant="outliend">
                        <Button variant="outlined" onClick={() => setIsEditEmail(false)}>{dict.CancelAction_TXT[profile?.language]}</Button>
                        <Button variant="contained" onClick={() => handleUpdateProfile()}>{dict.Save_TXT[profile?.language]}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isEditBirthDay} onClose={() => setIsEditBirthDay(false)} >
                    <DialogContent>
                        <Controller
                            name="birthDay"
                            control={control}
                            rules={{ required: 'ระบุวันเกิด' }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>{dict.Birth_Date_TXT[profile?.language]}</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDatePicker
                                            inputFormat='DD/MM/YYYY'
                                            value={moment(dateSelected, "DD/MM/YYYY")}
                                            onChange={(newValue) => {
                                                setDateSelected(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>)}
                        />

                    </DialogContent>
                    <DialogActions variant="outliend">
                        <Button variant="outlined" onClick={() => setIsEditBirthDay(false)}>{dict.CancelAction_TXT[profile?.language]}</Button>
                        <Button variant="contained" onClick={() => handleUpdateProfile()}>{dict.Save_TXT[profile?.language]}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isEditFullName} onClose={() => setIsEditFullName(false)} >
                    <DialogContent>

                        <Controller
                            name="firstname"
                            control={control}
                            rules={{ required: 'กรุณาระบุชื่อจริงของคุณ' }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>ชื่อจริง</FormLabel>

                                    <TextField onChange={onChange} defaultValue={profile?.firstname} ></TextField>

                                </FormControl>)}
                        />


                        <Controller
                            name="lastname"
                            control={control}
                            rules={{ required: 'กรุณาระบุนามสกุลของคุณ' }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>นามสกุล</FormLabel>

                                    <TextField onChange={onChange} defaultValue={profile?.lastname} type="lastName"></TextField>

                                </FormControl>)}
                        />

                        <Controller
                            name="first_name_en"
                            control={control}
                            rules={{ required: 'Please input you firstname.' }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl >
                                    <FormLabel>First Name</FormLabel>

                                    <TextField onChange={onChange} defaultValue={profile?.firstname_en} ></TextField>

                                </FormControl>)}
                        />

                        <Controller
                            name="last_name_en"
                            control={control}
                            rules={{ required: 'Please input you lastname.' }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl>
                                    <FormLabel>Last Name</FormLabel>

                                    <TextField onChange={onChange} defaultValue={profile?.lastname_en} ></TextField>

                                </FormControl>)}
                        />

                    </DialogContent>
                    <DialogActions variant="outliend">
                        <Button variant="outlined" onClick={() => setIsEditFullName(false)}>ยกเลิก</Button>
                        <Button variant="contained" onClick={() => handleUpdateProfile()}>บันทึก</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Snackbar open={isSuccessAlert} autoHideDuration={6000} onClose={() => setIsSuccessAlert(false)} >
                <Alert severity="success">{dict.System_Saved_Info_TXT[profile?.language]}</Alert>
            </Snackbar>
            <Snackbar open={isErrorAlert} autoHideDuration={6000} onClose={() => setIsErrorAlert(false)} >
                <Alert severity="error">{dict.Err_Saving_TXT[profile?.language]}</Alert>
            </Snackbar>

        </>
    )
}
