export const Phone_Tab1_TXT = []
Phone_Tab1_TXT["en"] = "Emergency Number"
Phone_Tab1_TXT["th"] = "เบอร์ฉุกเฉิน"

export const Phone_Tab2_TXT = []
Phone_Tab2_TXT["en"] = "Officer Number"
Phone_Tab2_TXT["th"] = "เบอร์เจ้าหน้าที่"

export const Phone_Tab3_TXT = []
Phone_Tab3_TXT["en"] = "Service Number"
Phone_Tab3_TXT["th"] = "เบอร์งานบริการ"