import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@mui/material'
export const ModalError = (props) => {
    const { onClose, open, error } = props;
    const handleClose = () => {
        onClose();
    };
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Error - {error?.response?.data?.error_code || error?.response?.status || error?.status}</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {error?.response?.data?.error_message || error?.response?.data?.message || error?.message}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}
