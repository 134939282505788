import {
    Stack, Card, Container,
    Typography, TextField,
    Box, Grid, Tabs, Tab, CardMedia, CircularProgress, Backdrop
} from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material';
import { useEffect, useState, useRef, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../api'
import * as dict from '../../dictionary/Parcel'
import { ProfileContext } from '../../contexts/ProfileContext';
import { ModalError } from '../../ui-component'
export const Parcel = () => {
    const [tabSelected, setTabSelected] = useState("received")
    const [parcelList, setParcelList] = useState([])
    const [tempParcelList, setTempParcelList] = useState([])
    const [loadingList, setLoadingList] = useState(false)
    const [profile, setProfile] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation()
    const searchRef = useRef()
    const context = useContext(ProfileContext)
    let navigate = useNavigate()

    const loadParcelItems = async () => {
        setLoadingList(true)
        let res = await api.getAllByUser({
            "line_id": houseInfo.line_id,
            "project": houseInfo.project_id,
            "house_number": houseInfo.house_number,
            "status": "waiting",
            "page": "1",
            "product_type": houseInfo.product_type
        })
        if (res?.status === 200) {
            setParcelList(res.data?.body)
            setTempParcelList(res.data?.body)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoadingList(false)
        return

    }

    const loadParcelHistoryItems = async () => {
        setLoadingList(true)
        let res = await api.getAllHistoryByUser({
            "line_id": houseInfo.line_id,
            "project": houseInfo.project_id,
            "house_number": houseInfo.house_number,
            "status": "received",
            "page": "1",
            "product_type": houseInfo.product_type
        })
        if (res?.status === 200) {
            setParcelList(res.data?.body)
            setTempParcelList(res.data?.body)
            setLoadingList(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoadingList(false)
        return
    }

    const handleSelectedTab = async () => {

        if (tabSelected === "history") {
            return loadParcelHistoryItems()
        }

        if (tabSelected === "received") {
            return loadParcelItems()
        }

        return
    }

    const handleSearchKeyUp = (input) => {
        setLoadingList(true)
        let tempParcel = [...tempParcelList]
        let search = tempParcel.filter(element => element.postboxID.includes(input))
        setParcelList(search)
        setLoadingList(false)
    }


    useEffect(() => {
        if (houseInfo !== null) {
            handleSelectedTab()
        }
    }, [tabSelected, houseInfo])


    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }

    }, [context])

    useEffect(() => {

        let tabSession = sessionStorage.getItem("tabSelected")
        setTabSelected(tabSession || tabSelected)

    }, []);

    return (
        <>

            <div style={{ height: "100vh" }} >

                <Tabs
                    value={tabSelected}
                    onChange={(e, val) => {
                        sessionStorage.removeItem('scrollPosition');
                        sessionStorage.removeItem('tabSelected');
                        setTabSelected(val)
                    }}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ marginTop: 8 }}
                    centered
                    variant="fullWidth"
                >
                    <Tab value="received" label={dict.ToPickUp_TXT[profile?.language]} />
                    <Tab value="history" label={dict.History_TXT[profile?.language]} />

                </Tabs>
                <Container maxWidth="xl" >
                    <Box maxWidth="xl"  >


                        <TextField
                            inputRef={searchRef}
                            sx={{ width: 1, background: "white", borderRadius: "10px", marginTop: 2 }}
                            label={dict.Search_TXT[profile?.language]}
                            onKeyUp={() => handleSearchKeyUp(searchRef.current.value)}
                        />


                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loadingList}
                        >
                            <Stack alignItems={"center"} >
                                <CircularProgress color="inherit" />
                            </Stack>

                        </Backdrop>
                        <Stack mt={2} spacing={1} >
                            {
                                parcelList?.length > 0 && parcelList.map(element => <Card
                                    variant="outlined"
                                    sx={{ borderRadius: "10px", display: "flex" }}
                                    onClick={(event) => {
                                        sessionStorage.setItem('tabSelected', tabSelected)
                                        navigate('/parcel/parcelDetail' + location.search, {
                                            state: element
                                        })
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "60px", height: "100%" }}
                                        src={element.postboxImage}
                                    />

                                    <Grid container ml={1} sx={{ padding: 1, alignItems: "center" }}>
                                        <Grid item xs={6}>
                                            <Stack direction="column"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                            >
                                                <Typography variant="caption" >
                                                    {element.postboxID}
                                                </Typography>
                                                <Typography variant="caption"  >
                                                    {dict.Receiver_TXT[profile?.language]}: {element.ownerName}
                                                </Typography>

                                            </Stack>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Stack direction="column"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                            >
                                                <Typography variant="caption" >
                                                    {element.houseNumber}
                                                </Typography>
                                                <Typography variant="caption" >
                                                    {element.createDate}
                                                </Typography>

                                            </Stack>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ArrowForwardIos sx={{ fontSize: "18px" }} />
                                        </Grid>
                                    </Grid>

                                </Card>
                                )
                            }
                        </Stack>
                    </Box>

                </Container>

            </div >
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </>
    )

}