import React from "react";
import { Stack, Typography, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as FileCheckIcon } from "../assets/images/file_check_fill.svg";
import { ReactComponent as LoanIcon } from "../assets/images/loanIcon.svg";
import { ReactComponent as PdfIcon } from "../assets/images/pdfIcon.svg";
import { ReactComponent as TransferIcon } from "../assets/images/transferIcon.svg";
import { Phone , ArrowForwardIos, MailOutline}  from '@mui/icons-material'
export const CardMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const chooseIcon = {
    "fileCheckIcon": <FileCheckIcon fill={theme.palette.primary.contrastText} />,
    "loanIcon": <LoanIcon fill={theme.palette.primary.contrastText} />,
    "pdfIcon": <PdfIcon fill={theme.palette.primary.contrastText} />,
    "transferIcon": <TransferIcon fill={theme.palette.primary.contrastText} />,
    "phoneIcon" : <Phone sx={{ color: theme.palette.primary.contrastText }} />,
    "mailIcon": <MailOutline sx={{ color: theme.palette.primary.contrastText }} />
  }
  return (
    <Card
      variant="outlined"
      onClick={props?.onClick ? props?.onClick
        : () => {
          if (props?.navigate) {
            navigate(props?.navigate + location.search, {
              state: props?.state,
            });
          }
        }
      }
      sx={{
        p: 2,
        boxShadow: "0px 0px 7px 0px #00000040",
        borderRadius: "10px"
      }}
    >
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <div style={{
            minHeight: "50px", minWidth: "50px",
            textAlign: "center", alignItems: "center",
            display: "flex", justifyContent: "center",
            border: "1px",
            boxShadow: "5px 5px 7px 0px #00000040",
            borderRadius: 100, backgroundColor: theme.palette.primary.main
          }}>
            {
              chooseIcon[props?.iconImg]
            }
          </div>

          <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
            {props?.text}
          </Typography>
        </Stack>
        <ArrowForwardIos sx={{ fontSize: "20px" }} fill={theme.palette.primary.main} />
      </Stack>

    </Card>
  );
}
