import axios from "axios";

export const messageOfficial = async (data) => {

    try {

        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/messages/official`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "mobile_no": data.mobile_no,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "house_number": data.house_number,
                "first_name": data.first_name,
                "first_name_en": data.first_name_en,
                "last_name": data.last_name,
                "last_name_en": data.last_name_en
            }
        })
        return res

    } catch (error) {
        return error
    }


}