import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  Backdrop,
  Alert,
  Grid,
  Container,
  Stepper,
  Step,
  StepButton,
  CircularProgress,
  AppBar,
  Toolbar,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { Language } from '../Language';
import * as api from '../../api/'
import MasterPage from '../../ui-component/MasterPage';
import * as dict from '../../dictionary/Register'
import { checkProductType } from '../../utils/checkProductType';
export const Register = () => {
  const [isVisibleLanguage, setIsVisibleLanguage] = useState(false);
  const [activeStep] = useState(0);
  const [loadingBackDrop] = useState(false);
  const [registerStep] = useState(1);
  const [otpAlertType, setOtpAlertType] = useState(null);
  const [validTxtType, setValidTxtType] = useState(null);
  const [warningTxtType,setWarningTxtType] = useState(null);
  const [isVisibleInputOTP, setIsVisibleInputOTP] = useState(false);
  const [otpToken, setOtpToken] = useState(null);
  const [otpRef, setOtpRef] = useState(null);
  const [countTime, setCountTime] = useState(false);
  const { handleSubmit, control, getValues } = useForm();
  const location = useLocation();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [userLanguage, setUserLanguage] = useState("th");
  const [customerType, setCustomerType] = useState("Thai");
  let navigate = useNavigate();
  const step = {
    1: `${dict.Step1_TXT[userLanguage]}`,
    2: `${dict.Step2_TXT[userLanguage]}`,
    3: `${dict.Step3_TXT[userLanguage]}`,
    4: `${dict.Step4_TXT[userLanguage]}`,
  };
  const validTxt = {
    otpNoInput: `${dict.Otp_no_input_TXT[userLanguage]}`,
    otpNoVerify: `${dict.Otp_no_verify_TXT[userLanguage]}`,
    otpExpired: `${dict.Otp_expired_TXT[userLanguage]}`,
    idCardInvalid: `${customerType === "Thai"
        ? dict.is_card_invalid_TXT[userLanguage]
        : dict.is_passport_invalid_TXT[userLanguage]
      }`,
  };

  const warningTxt = {
    otpNoPhoneInput: `${dict.Otp_no_phone_input_TXT[userLanguage]}`,
    otpNoEmailInput: `${dict.Otp_no_email_input_TXT[userLanguage]}`
  }

  const onSubmit = async (data, e) => {
    if (isVisibleInputOTP === false) {
      setValidTxtType("otpNoInput");
      return;
    }

    let form = getValues();
    if (customerType === "Thai") {
      let verify = await verifyOTP();
      if (verify.status === "204") {
        setValidTxtType("idCardInvalid");
        // setIsVerifyOTP(false)
        return;
      }

      if (verify.status === "205") {
        setValidTxtType("otpExpired");
        // setIsVerifyOTP(false)
        return;
      }

      if (verify.status !== "200") {
        setValidTxtType("otpNoVerify");
        // setIsVerifyOTP(false)
        return;
      }
    } else {
      let verifyEmail = await emailVerifyOTP();
      if (verifyEmail.status === 204) {
        setValidTxtType("idCardInvalid");
        // setIsVerifyOTP(false)
        return;
      }

      if (verifyEmail.status === 205) {
        setValidTxtType("otpExpired");
        // setIsVerifyOTP(false)
        return;
      }

      if (verifyEmail.status !== 200) {
        setValidTxtType("otpNoVerify");
        // setIsVerifyOTP(false)
        return;
      }
    }
    // setIsVerifyOTP(true)
    navigate("/registration/privacyPolicy" + location.search, {
      state: {
        citizen_id: getValues().citizen_id,
        line_id: localStorage.getItem("userId"),
        mobile_no: form.TelephoneNumber,
        language: userLanguage,
        contact_type: customerType,
      },
    });
    // setActiveStep(1)
    return;
  };
  const onError = (errors, e) => console.log(errors);

  const requestOTP = async () => {
    let form = getValues();
    if (form.mobile_no === "") return setWarningTxtType("otpNoPhoneInput")
    let response = await api.requestOTP({
      line_id: localStorage.getItem("userId"),
      product_type: checkProductType(),
      citizen_id: form.citizen_id,
      mobile_no: form.mobile_no,
    });

    setCountTime(true);
    if (response?.data?.status === 200) {
      setOtpAlertType("success");
      setOtpToken(response?.data?.token);
      setIsVisibleInputOTP(true);
      return;
    }

    setOtpAlertType("error");
    return;
  };

  const verifyOTP = async () => {
    let form = getValues();
    let status = await api.verifyOTP({
      token: otpToken,
      otp_code: form.OTP,
    });

    return status.data;
  };

  const emailRequestOTP = async () => {
    let form = getValues();
    if (form.email === "") return setWarningTxtType("otpNoEmailInput")
    let response = await api.emailRequestOTP({
      line_id: localStorage.getItem("userId"),
      product_type: checkProductType(),
      citizen_id: form.citizen_id,
      email: form.email,
    });

    setCountTime(true);
    if (response?.data?.status === 200) {
      setOtpAlertType("success");
      setOtpToken(response?.data?.data?.token);
      setOtpRef(response?.data?.data?.ref);
      setIsVisibleInputOTP(true);
      return;
    }

    setOtpAlertType("error");
    return;
  };

  const emailVerifyOTP = async () => {
    let form = getValues();
    let status = await api.emailVerifyOTP({
      token: otpToken,
      otp_code: form.OTP,
      ref: otpRef,
    });

    return status.data;
  };

  const handleChooseLanguage = (lang) => {
    setUserLanguage(lang);
  };

  const handleChangeCustomerType = (event) => {
    //alert(event.target.value);
    setCustomerType(event.target.value);
    setIsVisibleInputOTP(false);
  };

  useEffect(() => {
    setIsVisibleLanguage(true);
  }, []);

  useEffect(() => {
    if (seconds === 0 && minutes === 0) {
      setCountTime(false);
    }
  }, [seconds, minutes]);

  useEffect(() => {
    if (countTime === true) {
      setMinutes(1);
      setSeconds(0);
    }
  }, [countTime]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <MasterPage>
      <Box>
        <AppBar
          sx={{
            position: "relative",
            boxShadow: "none",
            height: 60,
          }}
        >
          <Toolbar>
            <Grid container item xs={1}>
              <LanguageIcon onClick={() => setIsVisibleLanguage(true)} />
            </Grid>
            <Grid
              container
              item
              xs={10}
              direction="row"
              justifyContent="center"
            >
              <Typography variant="h6">
                {dict.Registration_TXT[userLanguage]}
              </Typography>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container>
          {registerStep === 4 && (
            <Alert severity="error">{step[registerStep]}</Alert>
          )}

          <Snackbar
            open={otpAlertType === "success"}
            autoHideDuration={6000}
            onClose={() => setOtpAlertType("")}
          >
            <Alert severity="success">
              "{dict.OTP_Type_Success_TXT[userLanguage]}"
            </Alert>
          </Snackbar>
          <Snackbar
            open={otpAlertType === "error"}
            autoHideDuration={6000}
            onClose={() => setOtpAlertType("")}
          >
            <Alert severity="error">
              "{dict.OTP_Type_Error_TXT[userLanguage]}"
            </Alert>
          </Snackbar>
          <Snackbar
            open={validTxtType !== null}
            autoHideDuration={6000}
            onClose={() => setValidTxtType(null)}
          >
            <Alert severity="error">{validTxt[validTxtType]}</Alert>
          </Snackbar>
          <Snackbar
            open={warningTxtType !== null}
            autoHideDuration={6000}
            onClose={() => setWarningTxtType(null)}
          >
            <Alert severity="warning">{warningTxt[warningTxtType]}</Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingBackDrop}
          >
            <Stack alignItems={"center"}>
              <Typography>{step[registerStep]}</Typography>
              <CircularProgress color="inherit" />
            </Stack>
          </Backdrop>

          <Stepper sx={{ marginTop: 2 }} activeStep={activeStep}>
            <Step>
              <StepButton color="inherit">
                {dict.Registration_TXT[userLanguage]}
              </StepButton>
            </Step>
            <Step>
              <StepButton color="inherit">
                {dict.Agreed_Policy_TXT[userLanguage]}
              </StepButton>
            </Step>
          </Stepper>

          <Box
            component="form"
            id="hook-form"
            mt={2}
            sx={{ width: "100%" }}
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Stack spacing={3} direction={"column"} justifyContent={"center"}>
              <Controller
                name="contact_type"
                control={control}
                defaultValue={customerType}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    {/* <FormLabel id="contact_type">
                          {dict.Contact_Type_TXT[userLanguage]}
                        </FormLabel> */}
                    <InputLabel id="contact_type_label">
                      {dict.Contact_Type_TXT[userLanguage]}
                    </InputLabel>
                    <Select
                      labelId="Contact_Type_label"
                      id="contact_type"
                      value={customerType}
                      onChange={handleChangeCustomerType}
                      //label={dict.Contact_Type_TXT[userLanguage]}
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                          border: "1px",
                        },
                      }}
                    >
                      <MenuItem value="Thai">
                        {dict.Contact_Type_Thai_TXT[userLanguage]}
                      </MenuItem>
                      <MenuItem value="Foreigner">
                        {dict.Contact_Type_Foreigner_TXT[userLanguage]}
                      </MenuItem>
                    </Select>
                    {/* <RadioGroup
                          row
                          value={customerType}
                          onChange={(event, value) =>
                            handleChangeCustomerType(value)
                          } //{handleChangeCustomerType}
                        >
                          <FormControlLabel
                            value="Thai"
                            control={<Radio />}
                            label={dict.Contact_Type_Thai_TXT[userLanguage]}
                          />
                          <FormControlLabel
                            value="Foreigner"
                            control={<Radio />}
                            label={
                              dict.Contact_Type_Foreigner_TXT[userLanguage]
                            }
                          />
                        </RadioGroup> */}
                  </>
                )}
              />
              <Controller
                name="citizen_id"
                control={control}
                defaultValue=""
                rules={{
                  required: `${customerType === "Thai"
                      ? dict.Please_Input_Citizen_TXT[userLanguage]
                      : dict.Please_Input_Passport_TXT[userLanguage]
                    }`,
                  // pattern: { value: /(\d{13})|(\d{1}\s\d{4}\s\d{5}\s\d{2}\s\d{1})|(\d{5}\s\d{5}\s\d{3})|(\d{5}\s\d{5}\s\d{2}\s\d{1})|(\d{1}\-?\d{4}\-?\d{5}\-?\d{2}\-?\d{1})/g, message: "รหัสประชาชนยังไม่ถูกต้อง" }
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error, rules },
                }) => (
                  <>
                    {/* <TextField onChange={onChange} label={"เลขประจำตัวประชาชน หรือ เลขพาสปอร์ต*"} type={"number"} /> */}
                    <TextField
                      type={customerType === "Thai" ? "number" : ""}
                      onChange={onChange}
                      label={
                        customerType === "Thai"
                          ? dict.Citizen_Or_Passport_TXT[userLanguage]
                          : dict.Passport_TXT[userLanguage]
                      }
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                          border: "1px",
                        },
                      }}
                    />
                    {error !== undefined && (
                      <Box sx={{ marginTop: 1 }}>
                        <Alert severity="error">{error.message}</Alert>
                      </Box>
                    )}
                  </>
                )}
              />
              {customerType === "Thai" && (
                <Grid container alignItems={"center"}>
                  <Grid item xs={7}>
                    <Controller
                      name="mobile_no"
                      control={control}
                      rules={{
                        required: `${dict.Input_MobileNumber_TXT[userLanguage]}`,
                        // pattern: { value: /^(0[689]{1})+([0-9]{8})+$/g, message: "Ex.062xxxxxxx" }
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            type="number"
                            onChange={onChange}
                            label={dict.Phone_Number_TXT[userLanguage]}
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                                border: "1px",
                              },
                            }}
                          />

                          {/* <TextField onChange={onChange} label={"หมายเลขโทรศัพท์*"} type={"number"} /> */}
                          {error !== undefined && (
                            <Box sx={{ marginTop: 1 }}>
                              <Alert severity="error">{error.message}</Alert>
                            </Box>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ textAlign: "center" }}>
                    <FormControl>
                      {countTime === false && (
                        <Button
                          variant="contained"
                          onClick={() => requestOTP()}
                        >
                          {dict.Send_OTP_TXT[userLanguage]}
                        </Button>
                      )}
                      {countTime === true && (
                        <Button variant="outlined">
                          <Typography>
                            {minutes} : {seconds}
                          </Typography>
                        </Button>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {customerType !== "Thai" && (
                <Grid container alignItems={"center"}>
                  <Grid item xs={7}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: `${dict.Input_Email_TXT[userLanguage]}`,
                        // pattern: { value: /^(0[689]{1})+([0-9]{8})+$/g, message: "Ex.062xxxxxxx" }
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            onChange={onChange}
                            label={dict.Email_OTP_TXT[userLanguage]}
                            type="email"
                            InputProps={{
                              style: {
                                borderRadius: "5px",
                                border: "1px",
                              },
                            }}
                          />

                          {/* <TextField onChange={onChange} label={"หมายเลขโทรศัพท์*"} type={"number"} /> */}
                          {error !== undefined && (
                            <Box sx={{ marginTop: 1 }}>
                              <Alert severity="error">{error.message}</Alert>
                            </Box>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ textAlign: "center" }}>
                    <FormControl>
                      {countTime === false && (
                        <Button
                          variant="contained"
                          onClick={() => emailRequestOTP()}
                        >
                          {dict.Send_OTP_TXT[userLanguage]}
                        </Button>
                      )}
                      {countTime === true && (
                        <Button variant="outlined">
                          <Typography>
                            {minutes} : {seconds}
                          </Typography>
                        </Button>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {isVisibleInputOTP === true && (
                <Controller
                  name="OTP"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        type="number"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          minLength: 1,
                          maxLength: 6,
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "5px",
                            border: "1px",
                          },
                        }}
                        onChange={onChange}
                        required
                        label={dict.Fill_OTP_Txt[userLanguage]}
                        helperText={
                          customerType === "Thai"
                            ? dict.Fill_OTP_Helper_Txt[userLanguage]
                            : dict.Email_Fill_OTP_Helper_Txt[userLanguage]
                        }
                      />
                    </FormControl>
                  )}
                />
              )}
              <Button
                variant={"contained"}
                sx={{ height: "60px" }}
                type={"submit"}
              >
                {dict.GO_TO_AGREE_TXT[userLanguage]}
              </Button>
            </Stack>
          </Box>
        </Container>

        <Language
          isVisible={isVisibleLanguage}
          response={(lang) => handleChooseLanguage(lang)}
          onClose={() => setIsVisibleLanguage(false)}
          defaultLanguage={userLanguage}
        />
      </Box>
    </MasterPage>
  );
};
