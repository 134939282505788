import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  CardMedia
} from '@mui/material';
import logoNext from '../../assets/images/logo/next.png'
import logoVisa from '../../assets/images/logo/visa.png'
import logoMasterCard from '../../assets/images/logo/master-card.svg'
import logoJCB from '../../assets/images/logo/payment.svg'
import logoUnion from '../../assets/images/logo/union.svg'
import logoAmex from '../../assets/images/logo/american-express.svg'
import { useNavigate, useLocation } from 'react-router-dom';
export const CreditCardSelecting = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const handleSelect = (choiceSelected) => {
    navigate('/billpayment/amountPayment' + location.search, {
      state: { ...location.state, choiceSelected }
    })
  }

  return (
    <>
      <List sx={{

      }}>
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        } sx={{ height: 70 }}
          onClick={() => handleSelect("VISA")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoVisa}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1 }} primary="VISA" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("MASTERCARD")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoMasterCard}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1 }} primary="Master Card" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("JCB")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoJCB}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1 }} primary="JCB" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("UNIONPAY")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoUnion}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1 }} primary="UnionPay" />
        </ListItem>
        <Divider light />
        <ListItem secondaryAction={
          <CardMedia
            component="img"
            sx={{ width: 25 }}
            src={logoNext}
          />
        }
          sx={{ height: 70 }}
          onClick={() => handleSelect("AMEX")}
        >
          <ListItemIcon>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              src={logoAmex}
            />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1 }} primary="American Express" />
        </ListItem>
        <Divider light />
      </List>
    </>

  )
}
