import React from 'react'
import {
    Box, ImageList, ImageListItem,
} from '@mui/material'
import { BlankPage } from '../../../../ui-component/BlankPage'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { useLocation } from 'react-router-dom';

export const Gallery = () => {
    const location = useLocation()    
    return (
        <><Box sx={{ marginTop: 8 }}>
            <ImageList cols={2} rowHeight={164}>
                {location.state?.imgGallery.map((item) => (
                    <Zoom>
                        <ImageListItem key={item.image1024}>
                            <img
                                src={`${item.image1024}`}
                                srcSet={`${item.image1024}`}
                                alt={item.title}
                                loading="lazy"
                                width="500"
                            />
                        </ImageListItem>
                    </Zoom>

                ))}
            </ImageList>
        </Box>
            {
                location.state?.imgGallery?.length === 0 && <BlankPage lang={location.state?.lang}/>
            }
        </>
    )
}