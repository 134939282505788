import React, { useState, useEffect, useContext } from 'react'
import {
    Stack, Card,
    Typography, Box, Slide, Button, Chip, 
} from '@mui/material'
import { ProfileContext } from '../../contexts/ProfileContext'
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../api/'
import * as dict from '../../dictionary'
export const History = (props) => {

    const [profile, setProfile] = useState(null)
    const [productType, setProductType] = useState(null)
    const [detail, setDetail] = useState(null)
    const context = useContext(ProfileContext)
    const navigate = useNavigate()
    const location = useLocation()
    const loadDetail = async () => {
        let res = await api.getFacilityBookingDetail({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "booking_id": location.state?.bookingId
        })
        setDetail(res.data?.body)
    }
    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])
    useEffect(() => {
        if (profile !== null && props?.isVisible === true) {

        }
    }, [profile, props?.isVisible]);
    useEffect(() => {
        let cid_pm = localStorage.getItem("cid_pm")
        if (cid_pm === undefined || cid_pm === null) {
            return setProductType("remlite")
        }
        return setProductType("pmlite")
    }, [])
    useEffect(() => {
        if (productType) {
            loadDetail()
        }
    }, [productType]);
    return (
        <>
            <Card variant="outlined" sx={{
                width: "100%",
                display: "flex",
                height: "200px",
                marginTop: 8,
                paddingTop: 2,
                paddingBottom: 2,
                alignItems: "flex-end",
                justifyContent: "center"
            }}>
                <Box sx={{
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url(${detail?.facilitiesImage})`,
                    height: "100%",
                    width: "85%",
                    backgroundSize: "cover",
                    borderRadius: "10px",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                }}>
                </Box>
            </Card>
            <Box sx={{ padding: 5 }} >
                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                    <Chip label={detail?.statusText} color={"primary"} variant="filled" />
                </Stack>
                <Typography variant={"h5"}>
                    {detail?.facilitiesName}
                </Typography>
                <Typography variant={"body1"}>
                    {detail?.projectName}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"body2"}>
                        {dict.Date_TXT[profile?.language]}
                    </Typography>
                    <Typography variant={"body2"}>
                        {detail?.bookingDate}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"body2"}>
                        {dict.Period_TXT[profile?.language]}
                    </Typography>
                    <Typography variant={"body2"}>
                        {detail?.bookingStart} - {detail?.bookingEnd}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant='body2'>
                        {dict.Booking_Code_TXT[profile?.language]}
                    </Typography>
                    <Typography variant='body2'>
                        {detail?.bookingId}
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} mt={3}>
                    <Button variant={"outlined"} color="primary" onClick={() => {
                        navigate('/facility/selectFacility' + location.search, {
                            state: { detail: location.state.listFacility[detail?.facilitiesName] }
                        })
                    }}>
                        <Typography variant={"h6"}>{dict.Book_Again_TXT[profile?.language]}</Typography>
                    </Button>
                </Stack>
            </Box>
        </>
    )
}
