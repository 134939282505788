
import {
  Stack, Card, Container,
  Typography, Box, Grid, Select, MenuItem, FormControl, Snackbar, Chip, alpha
} from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import {
  PaymentsRounded,
  LocalShipping,
  ContactPhone,
  EventSeat,
  MonetizationOn,
  AddLocationAlt,
  FactCheck,
  Contacts,
  Handyman,
  Forum,
  AddReactionOutlined,
  Done,
  AutoStories,
  DomainAdd

} from '@mui/icons-material';
import { ReactComponent as EquipmentIcon } from "../assets/images/logo/equipment.svg"
import { ReactComponent as ReportDefectIcon } from "../assets/images/logo/reportDefect.svg"
import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_HOUSE_INFO, ProfileContext } from '../contexts/ProfileContext';
import * as dict from '../dictionary/Myhome.js'
import * as api from '../api'
import MasterPage from '../ui-component/MasterPage'
import { useTheme } from '@mui/material/styles';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { checkProductType } from '../utils/checkProductType.js';
import { BackDropLoading } from '../ui-component/BackDropLoading.js';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MyHome = () => {

  const [listMenu, setListMenu] = useState([])
  const [projectSelected, setProjectSelected] = useState("")
  const [projectList, setProjectList] = useState([])
  const [houseNoList, setHouseNoList] = useState([])
  const [houseNoSelected, setHouseNoSelected] = useState("")
  const [unitTemp, setUnitTemp] = useState([])
  const [isAlertSelected, setIsAlertSelected] = useState(false)
  const [profile, setProfile] = useState(null)
  const [imgAds, setImgAds] = useState([])
  const [logoDefault, setLogoDefault] = useState(null)
  const [loadingImg, setLoadingImg] = useState(true)
  const [loadingMenu, setLoadingMenu] = useState(true)
  let location = useLocation();
  let navigate = useNavigate();
  const theme = useTheme()
  const context = useContext(ProfileContext);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  };


  const fetchListButtonMenu = async () => {
    let response = await api.getListMenu({
      "line_id": localStorage.getItem("userId"),
      "is_rem": houseNoSelected.is_rem,
      "is_pm": houseNoSelected.is_pm,
      "is_rental": houseNoSelected.is_rental
    })

    let menu = response.data?.menu
    let alias = {
      billpayment: {
        name: "",
        icon: <PaymentsRounded fontSize='medium' sx={{ color: "white" }} />,
        path: "/billpayment"
      },
      installment: {
        name: "",
        icon: <PaymentsRounded fontSize='medium' sx={{ color: "white" }} />,
        path: "/billpayment"
      },
      parcel: {
        name: "",
        icon: <LocalShipping fontSize='medium' sx={{ color: "white" }} />,
        path: "/parcel"

      },
      phonebook: {
        name: "",
        icon: <ContactPhone fontSize='medium' sx={{ color: "white" }} />,
        path: "/phonebook"

      },
      facilities: {
        name: "",
        icon: <EventSeat fontSize='medium' sx={{ color: "white" }} />,
        path: "/facility"

      },
      loan: {
        name: "",
        icon: <MonetizationOn fontSize='medium' sx={{ color: "white" }} />,
        path: "/loan"

      },
      defect: {
        name: "",
        icon: <Handyman fontSize='medium' sx={{ color: "white" }} />,
        path: "/defect"
      },
      defectfast: {
        name: "",
        icon: <Handyman fontSize='medium' sx={{ color: "white" }} />,
        path: "/defectfast"
      },
      chatniti: {
        name: "",
        icon: <Forum fontSize='medium' sx={{ color: "white" }} />,
        path: "/chatniti"
      },
      contactsniti: {
        name: "",
        icon: <Contacts fontSize='medium' sx={{ color: "white" }} />,
        path: "/contactsniti"
      },
      addressdoc: {
        name: "",
        icon: <AddLocationAlt fontSize='medium' sx={{ color: "white" }} />,
        path: "/addressdoc"
      },
      regulations: {
        name: "",
        icon: <FactCheck fontSize='medium' sx={{ color: "white" }} />,
        path: "/regulations"
      },
      suggestion: {
        name: "",
        icon: <AddReactionOutlined fontSize='medium' sx={{ color: "white" }} />,
        path: "/suggestion"
      },
      equipment: {
        name: "",
        icon: <EquipmentIcon style={{ width: "1em", height: "1em", fontSize: "2rem", display: "inline-block" }} fill={"white"} />,
        path: "/equipment"
      },
      defectdoc: {
        name: "",
        icon: <ReportDefectIcon style={{ width: "1em", height: "1em", fontSize: "2rem", display: "inline-block" }} fill={"white"} />,
        path: "/defectdoc"
      },
      loancp: {
        name: "",
        icon: <MonetizationOn fontSize='medium' sx={{ color: "white" }} />,
        path: "/loancp"
      },
      inspectcp: {
        name: "",
        icon: <ReportDefectIcon style={{ width: "1em", height: "1em", fontSize: "2rem", display: "inline-block" }} fill={"white"} />,
        path: "/inspectcp"
      },
      transfer: {
        name: "",
        icon: <DomainAdd fontSize='medium' sx={{ color: "white" }} />,
        path: "/transfercp"
      },
      manual: {
        name: "",
        icon: <AutoStories fontSize='medium' sx={{ color: "white" }} />,
        path: "/manualcp"
      }
    }

    let list = []

    menu.map(element => {
      if (alias[element.alias]) {
        alias[element.alias].name = element.name
        list.push(alias[element.alias])
      }
    })

    setListMenu(list)
    setLoadingMenu(false)
    return
  }


  const navigateByPath = (path, state) => {

    if (houseNoSelected === null) return setIsAlertSelected(true)

    navigate(path, { state: state })

  }

  const selectHouseNo = () => {
    let temp = unitTemp
    let houseNo = temp[projectSelected] || []
    let defaultHouseNoSelected = sessionStorage.getItem("houseNoSelected")
    if (checkProductType() === "rental") {
      let noDuplicated = []
      for (let i = 0; i < houseNo.length; i++) {
        if (!noDuplicated.some(items => items.unit_number == houseNo[i].unit_number)) {
          noDuplicated.push(houseNo[i])
        }
      }
      houseNo = noDuplicated
    }
    setHouseNoList(houseNo)
    // if(defaultHouseNoSelected !== null) 
    setHouseNoSelected(houseNo.find(element => (element.house_number === "" ? element.unit_number : element.house_number) === defaultHouseNoSelected) || houseNo[0])

  }

  const prepareList = (unit) => {
    setUnitTemp(unit)
    let unit_ProjectList = Object.keys(unit)
    setProjectList(unit_ProjectList)

  }

  const handleHouseNoSelected = () => {
    if (houseNoSelected === null || houseNoSelected === "") return
    context.dispatch({
      type: SET_HOUSE_INFO,
      payload: houseNoSelected
    })
    let objInfo = houseNoSelected
    sessionStorage.setItem("houseNoSelected", houseNoSelected.house_number === "" ? houseNoSelected.unit_number : houseNoSelected.house_number)
    localStorage.setItem("houseInfoSelected", JSON.stringify(objInfo))
  }


  const loadImg = async () => {
    let res = await api.getImageProjectList({
      "line_id": profile?.line_id,
      "product_type": unitTemp[projectSelected][0].product_type,
      "project_id": unitTemp[projectSelected][0].project_id
    })
    setImgAds(res.data?.data || [])
    setLoadingImg(false)
  }

  const imageCondition = () => {

    if (imgAds?.length > 0) return imgAds?.map((element, index) => <div key={index} style={{ height: "270px" }} >
      <img src={element} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
    </div>)

    return <div style={{ height: "270px", backgroundColor: theme.palette.primary.main, display: "flex", justifyContent: "center" }}>
      <div style={{
        backgroundImage: `url(${logoDefault})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        width: "200px",
        height: "200px"
      }} />
    </div>
  }

  useEffect(() => {

    if (projectSelected) {
      sessionStorage.setItem("projectSelected", projectSelected)
      selectHouseNo()
    }

  }, [projectSelected])

  useEffect(() => {
    handleHouseNoSelected()
  }, [houseNoSelected])

  // comment เมื่อวันที่ 14/02/2024 useEffect(() => {

  //   if (houseNoList?.length > 0) {
  //     let house = houseNoList.find(ele => ele.house_number === sessionStorage.getItem("houseNoSelected"))
  //     setHouseNoSelected(house || null)
  //   }

  // }, [houseNoList])

  useEffect(() => {
    if (projectList.length > 0) {
      let defaultProjectSelected = sessionStorage.getItem("projectSelected")
      setProjectSelected(projectList.find(element => element === defaultProjectSelected) || projectList[0])
    }

  }, [projectList])

  useEffect(() => {

    if (projectSelected) {
      setLoadingImg(true)
      loadImg()
    }

  }, [projectSelected]);


  useEffect(() => {

    if (houseNoSelected) {
      setLoadingMenu(true)
      fetchListButtonMenu()
    }

  }, [houseNoSelected])

  useEffect(() => {
    if (context.state?.profile?.unit == undefined) return
    sessionStorage.setItem("tabSelected", "")

  }, [])

  useEffect(() => {

    if (context.state?.profile?.customer.length === 0){
      navigate('/noprofile')
    }

    if (context.state?.profile !== null) {
      setProfile(context.state?.profile?.customer[0])
      setLogoDefault(context.state?.profile.logo)
      return prepareList(context.state?.profile.unit)
    }

  }, [context.state?.profile])

  return (
    <MasterPage>
      <Box sx={{ height: "200px", width: "100%" }}>
        <Carousel arrows={false} responsive={responsive} autoPlay={imgAds?.length === 0 ? false : true} autoPlaySpeed={10000} infinite={true}>
          {
            imageCondition()
          }
        </Carousel>
      </Box>
      <Container style={{ padding: "5%", transform: "translateY(-5%)", }}>
        <Card sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040" }}>
          <div style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,-140%)"
          }}>
            {projectSelected !== null && <Box sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
              borderRadius: "22px",
              pl: "20px",
              pt: "5px",
              pb: "5px",
              pr: "20px",

            }}>
              <Typography variant='body1' sx={{ color: theme.palette.primary.contrastText, textWrap: "nowrap" }}>
                {projectSelected}
              </Typography>
            </Box>}
            {/* <Chip variant='filled' color="secondary">{projectSelected}</Chip> */}
          </div>
          <Box sx={{ padding: "30px 20px 30px" }}>
            {/* 
            <Box sx={{
              display: "flex"
            }}>
              <Typography
                variant="body2"
                component="div"
              >
                {
                  dict.LabelSelectCondo_TXT[profile?.language]
                }
              </Typography>
            </Box> */}
            <FormControl sx={{ mb: 1, width: "100%" }} size='small'>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-autowidth"
                value={projectSelected}
                sx={{ borderRadius: "5px", border: "1px" }}
                onChange={(event, val) => {
                  setProjectSelected(event.target.value)
                }}
              >
                {
                  projectList?.map((element, i) => <MenuItem key={i} value={element}>{element}</MenuItem>)
                }
              </Select>
            </FormControl>

            <FormControl sx={{ width: "100%" }} size='small'>
              <Select
                disabled={!projectSelected}
                value={houseNoSelected}
                sx={{ borderRadius: "5px", border: "1px" }}
                onChange={(event, val) => {
                  setHouseNoSelected(event.target.value)
                }}
              >
                {
                  houseNoList?.map((element, i) => <MenuItem key={i} value={element}>
                    <Stack direction={"row"} alignItems="center" spacing={2}>
                      <Box>{element.house_number === "" ? element.unit_number : element.house_number}</Box>
                      {element?.product_type == "pmlite" && <Box><Chip icon={<Done />} size={"small"} label={dict.Transfer_Owner_TXT[profile?.language]} /></Box>}
                    </Stack>
                  </MenuItem>)
                }
              </Select>
            </FormControl>
          </Box>
        </Card>

        <Grid
          container
          mt={3}
          spacing={3}
          direction={"row"}
          alignItems="stretch"
        >
          {
            listMenu.length > 0 && listMenu.map((element, index) =>
              <Grid item xs={4} key={index} onClick={() => navigateByPath(element.path + location.search, { title: element.name })}
              >
                {/* <Paper
                  style={{
                    borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040",
                    minHeight: 110, textAlign: "center", display: "flex", justifyContent: "center"
                  }}
                  onClick={() => navigateByPath(element.path + location.search)}
                > */}
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent={"center"}
                  spacing={1}
                >

                  <div style={{
                    height: "50px", width: "50px",
                    textAlign: "center", alignItems: "center",
                    display: "flex", justifyContent: "center",
                    border: "1px",
                    boxShadow: "5px 5px 7px 0px #00000040",
                    borderRadius: 100, backgroundColor: theme.palette.primary.main
                  }}>
                    <div style={{ marginTop: 5 }}>

                      {element.icon}

                    </div>
                  </div>


                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant="caption" sx={{ wordWrap: "break-word" }}>
                      {element.name.length > 19 ? element.name.substring(0, 15) + "..." : element.name}
                    </Typography>
                  </Box>
                </Stack>
                {/* </Paper> */}

              </Grid>
            )
          }
        </Grid>

        <Snackbar open={isAlertSelected} autoHideDuration={6000} onClose={() => setIsAlertSelected(false)}>
          <Alert onClose={() => setIsAlertSelected(false)} severity="info" sx={{ width: '100%' }}>
            {dict.Fill_Name_HouseNumber_TXT[profile?.language]}
          </Alert>
        </Snackbar>
      </Container>
      <BackDropLoading loading={loadingImg || loadingMenu} />
    </MasterPage>)

}

