import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Card,
    Typography,
    Select,
    MenuItem,
    SwipeableDrawer,
    FormControl,
    Box,
    TextField,
    InputAdornment,
    InputLabel,
    Button
} from '@mui/material'
import * as api from '../../api/Project'
import SearchIcon from '@mui/icons-material/Search';
import PlaceIcon from '@mui/icons-material/Place';
import { ProfileContext } from '../../contexts/ProfileContext';
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { BlankPage } from '../../ui-component/BlankPage';
import InfiniteScroll from 'react-infinite-scroll-component'
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType';
export const ProjectTab = (props) => {
    const [listProject, setListProject] = useState([])
    const [countTotal, setCountTotal] = useState(0)
    const [profile, setProfile] = useState(null)
    const [page, setPage] = useState(0)
    const [productType, setProductType] = useState(null)
    const [mrtFilter, setMrtFilter] = useState([])
    const [btsFilter, setBtsFilter] = useState([])
    const [provinceFilter, setProvinceFilter] = useState([])
    const [typeFilter, setTypeFilter] = useState([])
    const { control, watch, register, handleSubmit, getValues, reset } = useForm();
    const navigate = useNavigate()
    const location = useLocation()

    const context = useContext(ProfileContext)

    const loadList = async (tempPage) => {

        if (profile == null || productType == null) return
        let form = getValues()
        let meaningProvince = provinceFilter.find(ele => ele.province_id === form.provinceFilter)
        let meaningBTS = btsFilter.find(ele => ele.bts_id === form.btsFilter)
        let meaingMRT = mrtFilter.find(ele => ele.mrt_id === form.mrtFilter)
        let res = await api.getProjectList({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "keyword": form.keyword || "",
            "type": form.typeFilter || "",
            "bts_id": meaningBTS?.bts_id || "",
            "mrt_id": meaingMRT?.mrt_id || "",
            "location_id": "",
            "province_id": meaningProvince?.province_id || "",
            "district_id": "",
            "subdistrict_id": "",
            "page": `${tempPage}`,
            "limit": "10"
        })


        setCountTotal(res.data?.count)


        if (res.data?.data?.length > 0) {
            setListProject([...listProject, ...res.data?.data])
        }


    }

    const loadDropdown = async () => {
        let res = await api.getFilterDropdown()
        let data = res?.data?.data
        setBtsFilter(data.bts)
        setMrtFilter(data.mrt)
        setTypeFilter(data.project_type)
        setProvinceFilter(data.province)
    }

    const loadMore = () => {
        let tempPage = page + 1
        setPage(tempPage)
        loadList(tempPage)
    }

    const handleScroll = () => {
        console.log("handleScroll")
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

            loadMore()
        }
    };

    const handleOnSearch = async () => {
        let form = getValues()
        setPage(0)
        let meaingType = typeFilter.find(ele => ele.type === form.typeFilter)
        let meaningProvince = provinceFilter.find(ele => ele.province_id === form.provinceFilter)
        let meaningBTS = btsFilter.find(ele => ele.bts_id === form.btsFilter)
        let meaingMRT = mrtFilter.find(ele => ele.mrt_id === form.mrtFilter)

        let res = await api.getProjectList({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "keyword": form.keyword || "",
            "type": form.typeFilter || "",
            "bts_id": meaningBTS?.bts_id || "",
            "mrt_id": meaingMRT?.mrt_id || "",
            "location_id": "",
            "province_id": meaningProvince?.province_id || "",
            "district_id": "",
            "subdistrict_id": "",
            "page": `0`,
            "limit": "10"
        })
        setListProject(res.data?.data)

        let textToShowOnSearch = [form.keyword, meaingType?.name, meaningBTS?.name, meaingMRT?.name, meaningProvince?.name].filter(Boolean).join(", ");
        props?.searchFinish(textToShowOnSearch)
    }

    const onError = (errors, e) => console.log(errors)



    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    useEffect(() => {
        loadDropdown()
    }, [])

    useEffect(() => {
        if (profile !== null) {
            loadList(0)
        }
    }, [profile]);


    // useEffect(() => {
    //     if (isLoad) {
    //         loadMore()
    //     }
    // }, [isLoad])

    useEffect(() => {

        if (props?.isClearFilter === true) {
            reset()
            loadList(0)
            props?.resetClearBoalean()
        }

    }, [props?.isClearFilter])

    return (
        <>

            {props?.isVisible === true && <>
               
                <Box sx={{ minHeight: "100vh", backgroundColor: "#F2F2F2" }}>

                    {/* <Stack direction={"column"} alignItems={"center"} > */}
                    <InfiniteScroll
                        dataLength={countTotal} //This is important field to render the next data
                        next={loadMore}
                        hasMore={listProject?.length < countTotal && countTotal !== 0}
                        loader={<h4>Loading...</h4>}
                    // endMessage={
                    //     <p style={{ textAlign: 'center' }}>
                    //         <b>Yay! You have seen it all</b>
                    //     </p>
                    // }
                    // below props only if you need pull down functionality

                    >
                        <Box sx={{ marginTop: 10 }} />

                        {
                            listProject?.length > 0 && listProject.map((element,index) => <Card variant="outlined" key={index} sx={{
                                marginTop: 2,
                                display: "flex",
                                height: "100%",
                                justifyContent: "center"
                            }} onClick={() => {
                                // setIdSelected(element.id)
                                // setIsDetailVisible(true)
                                navigate(`/projectInfo/detail${location.search}`, {
                                    state: {
                                        idSelected: element.id,
                                        productType: element.product_type,
                                    }
                                })
                            }}>

                                <Stack direction={"column"} alignItems={"center"} sx={{ width: "100%", padding: 2 }}>

                                    <Box
                                        sx={{
                                            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url('${element["images"]["image1024"]}')`,
                                            height: "200px",
                                            width: "85%",
                                            borderRadius: "10px",
                                            marginTop: 2,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                        }}
                                    >

                                        <Box sx={{ height: "70px", width: "100%" }}>
                                            <Stack direction="row" justifyContent={"center"} spacing={0.2}>
                                                <Typography variant={"body1"} ml={2} sx={{ color: "white", textShadow: "1px 0.5px #FFFFFF", textAlign: "justify" }}>
                                                    {element.title}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" justifyContent={"center"} spacing={0.2}>
                                                <PlaceIcon sx={{ color: "white" }} />
                                                <Typography variant={"body1"} sx={{ color: "white", textShadow: "1px 0.5px #FFFFFF" }}>{element["location"]}</Typography>

                                            </Stack>
                                        </Box>
                                    </Box>
                                </Stack>

                            </Card>)
                        }
                        <Box sx={{ marginBottom: 10 }}></Box>

                    </InfiniteScroll>
                    {/* </Stack> */}
                    {
                        listProject?.length === 0 && <BlankPage lang={profile?.language}/>
                    }
                </Box>

                <SwipeableDrawer
                    anchor={"top"}
                    open={props.isSearch}
                    onOpen={() => {}}
                    onClose={() => props?.searchClose()}
                >
                    <Box
                        sx={{ height: "100%", width: "100%" }}
                        onSubmit={handleSubmit(handleOnSearch, onError)}
                        component="form"
                    >
                        <Stack spacing={2} direction="column" sx={{ padding: 3 }} >

                            <Controller
                                control={control}
                                name="keyword"
                                render={({ field: { onChange, value }, fieldState: { error } }) => (

                                    <TextField
                                        id="outlined-search"
                                        label={dict.Find_Project_TXT[profile?.language]}
                                        type="search"
                                        value={value}
                                        onChange={onChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ marginTop: 2, }}
                                    />
                                )} />

                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-helper-label">{dict.House_Condo_TXT[profile?.language]}</InputLabel>
                                <Controller
                                    control={control}
                                    name="typeFilter"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (

                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            defaultValue={""}
                                            value={value || ""}
                                            label="บ้าน,คอนโด"
                                            onChange={onChange}
                                        >
                                            {
                                                typeFilter.map(element => <MenuItem value={element.type}>
                                                    {element.name}
                                                </MenuItem>)
                                            }
                                        </Select>
                                    )} />
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="country-label">{dict.Province_TXT[profile?.language]}</InputLabel>
                                <Controller
                                    control={control}
                                    name="provinceFilter"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Select
                                            labelId="country-label"

                                            defaultValue={""}
                                            value={value || ""}
                                            label={dict.Province_TXT[profile?.language]}
                                            onChange={onChange}
                                        >
                                            {
                                                provinceFilter.map(element => <MenuItem value={element.province_id}>
                                                    {element.name}
                                                </MenuItem>)
                                            }
                                        </Select>
                                    )} />
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="bts-label">BTS</InputLabel>
                                <Controller
                                    control={control}
                                    name="btsFilter"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (

                                        <Select
                                            labelId="bts-label"

                                            defaultValue={""}
                                            value={value || ""}
                                            label="BTS"
                                            onChange={onChange}
                                        >
                                            {
                                                btsFilter.map(element => <MenuItem value={element.bts_id}>
                                                    {element.name}
                                                </MenuItem>)
                                            }
                                        </Select>
                                    )} />
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="bts-label">MRT</InputLabel>
                                <Controller
                                    control={control}
                                    name="mrtFilter"
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Select
                                            labelId="bts-label"

                                            defaultValue={""}
                                            value={value || ""}
                                            label="MRT"
                                            onChange={onChange}
                                        >
                                            {
                                                mrtFilter.map(element => <MenuItem value={element.mrt_id}>
                                                    {element.name}
                                                </MenuItem>)
                                            }
                                        </Select>
                                    )} />
                            </FormControl>
                            <Button variant="contained" type={"submit"} >{dict.Search_Project_TXT[profile?.language]}</Button>
                            <Button variant="contained" color={"secondary"} onClick={() => {
                                reset({
                                    mrtFilter: null,
                                    btsFilter: null,
                                    provinceFilter: null,
                                    typeFilter: null,
                                    keyword: null
                                })
                                loadList(0)
                                props?.searchFinish("")
                                // props?.searchClose()
                            }}>{dict.Reset_Search_TXT[profile?.language]}</Button>

                        </Stack>

                    </Box>
                </SwipeableDrawer>
            </>
            }

        </>
    )
}
