import axios from "axios";


export const billPaymentDetail = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/billpayment/detail`,
            data: {
                line_id: data.line_id,
                contract_id: data.contract_id,
                load_type: data.load_type,
                language: data.language,
                term_id: data.term_id,
                reference_id: data.reference_id,
                reference_type: data.reference_type,
                product_type: data.product_type,
                payment_id: data.payment_id
            }
        })
        return res
    } catch (error) {
        return error

    }

}

export const createOrderKbankQr = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/kbankpay/createqr`,
            data: {
                company_id: data.company_id,
                contract_id: data.contract_id,
                amount: data.amount,
                description: data.description,
                project_id: data.project_id,
                unit_id: data.unit_id,
                customer_id: data.customer_id,
                term_id: data.term_id,
                term_name: data.term_name,
                term_name_eng: data.term_name_eng
            }
        })
        return res
    } catch (error) {
        return error

    }

}


export const createOrderMpayQr = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/mpay/qr/order`,
            data: {
                company_id: data.company_id,
                contract_id: data.contract_id,
                amount: data.amount,
                project_id: data.project_id,
                project_name: data.project_name,
                unit_id: data.unit_id,
                unit_number: data.unit_number,
                customer_id: data.customer_id,
                customer_name: data.customer_name,
                term_id: data.term_id,
                term_name: data.term_name,
                term_name_eng: data.term_name_eng,
                language: data.language
            }
        })
        return res
    } catch (error) {
        return error

    }

}

export const createOrderMobileBanking = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/mpay/banking/order`,
            data: {
                bank_name:data.bank_name,
                company_id: data.company_id,
                contract_id: data.contract_id,
                amount: data.amount,
                project_id: data.project_id,
                project_name:data.project_name,
                unit_id: data.unit_id,
                unit_number: data.unit_number,
                customer_id: data.customer_id,
                customer_name: data.customer_name,
                term_id: data.term_id,
                term_name: data.term_name,
                term_name_eng: data.term_name_eng,
                language: data.language
            }
        })
        return res
    } catch (error) {
        return error

    }

}


export const createOrderCreditCard = async (data) => {

    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/mpay/credit-card/order`,
            data: {
                credit_card_type:data.credit_card_type,
                company_id: data.company_id,
                contract_id: data.contract_id,
                amount: data.amount,
                project_id: data.project_id,
                project_name: data.project_name,
                unit_id: data.unit_id,
                unit_number: data.unit_number,
                customer_id: data.customer_id,
                customer_name: data.customer_name,
                term_id: data.term_id,
                term_name: data.term_name,
                term_name_eng: data.term_name_eng,
                language: data.language
            }
        })
        return res
    } catch (error) {
        return error
    }

}

export const createKbankCreditCard = async (data) => {
    let url = `${process.env.REACT_APP_LINEOA_V2_HOST_API}
                /kbankpay/charge?contractId=${data.contractId}
                &unitId=${data.unitId}&amount=${data.amount}
                &termName=${data.termName}&termId=${data.termId}&termNameEng=${data.termNameEng}
                &companyId=${data.companyId}&projectId=${data.projectId}
                &customerId=${data.customerId}&dccCurrency=${data.dccCurrency}`
                .replace(/\s/g, '')
    try {
        let res = await axios({
            method: 'post',
            url: url,
            data:{
                token : data.token
            }
        })
        return res
    } catch (error) {
        return error
    }
}