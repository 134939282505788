import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Card,
    Typography, 
    Box, 
    CardActionArea, 
    CardContent
} from '@mui/material'
import { ProfileContext } from '../../../../contexts/ProfileContext';
import * as api from '../../../../api/PlanDialog'
import { BlankPage } from '../../../../ui-component/BlankPage';
import { useLocation, useNavigate } from 'react-router-dom';
import * as dict from '../../../../dictionary'
import { checkProductType } from '../../../../utils/checkProductType';
export const Plan = () => {

    const [productType, setProductType] = useState(null)
    const [profile, setProfile] = useState(null)
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const handleClickPlan = async (item) => {
        let res = await api.getProjectModelDetail({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "project_id": item.project_id,
            "model_id": item.id
        })

        navigate(`/projectInfo/plan/detail${location.search}`,{
            state: {
                planDetail: res?.data?.data?.detail,
                gallery:res?.data?.data?.gallery
            }
        })
   
    }

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    useEffect(() => {
        setProductType(checkProductType())
    }, [])

    return (
        <>   
                <Box sx={{ marginTop: 8}}>
                    <Stack direction={"column"} alignItems={"center"} >
                        {
                            location.state?.modelList?.length > 0 && location.state?.modelList?.map((element, i) => <Card variant="outlined" sx={{
                                width: "100%",
                                marginTop: 2,
                                display: "flex",
                                height: "200px",
                                paddingTop: 2,
                                paddingBottom: 2,
                                alignItems: "flex-end",
                                justifyContent: "center"
                            }} key={i}>
                                <Box sx={{
                                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url(${element?.images?.image1024})`,
                                    height: "100%",
                                    width: "85%",
                                    backgroundSize: "cover",
                                    borderRadius: "10px",
                                    backgroundRepeat: "no-repeat",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "center",
                                }} onClick={() => {
                                    handleClickPlan(element)
                                }}>

                                    <CardActionArea>
                                        <CardContent>

                                            <Typography variant="body2" color="white">
                                                {element.type}
                                                {dict.Area_TXT[profile?.language]} : {element.area || "-"} {dict.Square_Meter_TXT[profile?.language]} | {dict.Bedroom_TXT[profile?.language]} : {element.bedroom || "-"} | {dict.Bathroom_TXT[profile?.language]} : {element.bathroom || "-"} |
                                                {dict.LivingRoom_TXT[profile?.language]} : {element.livingroom || "-"} | {dict.ParkingSpace_TXT[profile?.language]} : {element.car_amount || "-"}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Box>
                            </Card>
                            )}
                    </Stack>
                    {
                        location.state?.modelList?.length === 0 && <BlankPage lang={profile?.language}/>
                    }
                </Box>
        
        </>

    )

}

