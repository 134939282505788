import React, { useState, useEffect, useContext } from 'react'
import {
    Stack,
    Card,
    Typography,
    Box,
    Tabs,
    Tab
} from '@mui/material'
import { ProfileContext } from '../../contexts/ProfileContext';
import { BlankPage } from '../../ui-component/BlankPage';
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../api/Project'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType';
export const PromotionTab = (props) => {
    const [isTab, setIsTab] = useState("promotion")
    const [promotionList, setPromotionList] = useState([])
    const [profile, setProfile] = useState(null)
    const [productType, setProductType] = useState(null)
    const [page, setPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)

    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const typeMeaning = {
        "ประกาศจากโครงการ": "announcement",
        "โฆษณา": "banner",
        "ไลฟ์สไตล์": "lifestyle",
        "ข่าวสาร": "news",
        "โปรโมชัน": "promotion"
    }


    const loadListPromotion = async (typeSelected, page) => {
        let res = await api.getPromotionList({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "type": typeMeaning[typeSelected],
            "page": page,
            "limit": "10"
        })
        setCountTotal(res.data?.count)
        if (page === 0) {
            return setPromotionList(res.data?.data)
        }

        if (res.data?.data.length > 0) {
            setPromotionList([...promotionList, ...res.data?.data])
        }

    }

    const loadMore = async () => {
        let tempPage = page + 1
        setPage(tempPage)
        handleChangeTab(tempPage)
    }


    const handleChangeTab = (page) => {
        window.scrollTo(0,0); 
        if (productType == null) return

        if (isTab === "promotion") {
            loadListPromotion("โปรโมชัน", page)
        }

        if (isTab === "lifeStyle") {
            loadListPromotion("ไลฟ์สไตล์", page)
        }

    }

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    useEffect(() => {
        if (profile !== null && props?.isVisible === true) {
            handleChangeTab(0)
        }
    }, [profile, props?.isVisible]);

    useEffect(() => {
        handleChangeTab(0)
    }, [isTab])

    useEffect(() => {
        setProductType(checkProductType())
    }, [])


    useEffect(() => {
        let tabSession = sessionStorage.getItem("tabSelected")
        setIsTab(tabSession || isTab)
    }, []);

    return (
        <>
            {props?.isVisible === true && <> <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 , mt:8}}>
                <Tabs
                    textColor="secondary"
                    indicatorColor="secondary"
                    variant="fullWidth"
                    value={isTab}
                    sx={{ backgroundColor: "white" }}
                    onChange={(event, newValue) => {
                        setIsTab(newValue)
                        sessionStorage.setItem('tabSelected', newValue)
                    }} centered>
                    <Tab label={dict.Promotion_TXT[profile?.language]} value={"promotion"} />
                    <Tab label={dict.LifeStyle_TXT[profile?.language]} value={"lifeStyle"} />
                </Tabs></Box>

                <Box sx={{ backgroundColor: "#F2F2F2", minHeight: "100vh" }}>
                    <InfiniteScroll
                        dataLength={countTotal} //This is important field to render the next data
                        next={loadMore}
                        hasMore={promotionList?.length < countTotal}
                        loader={<h4>Loading...</h4>}
                    >
                        <Box sx={{ marginTop: 15 }}/>
                        {
                            promotionList?.length > 0 && promotionList.map((element, index) => <Card variant="outlined" key={index} sx={{
                                marginTop: 2, display: "flex",
                                height: "100%",
                                justifyContent: "center"
                            }}>
                                <Stack direction={"column"} alignItems={"center"} sx={{ width: "100%", padding: 2 }}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        <Stack direction={"column"}>
                                            <Typography variant={"caption"}>{dict.Announcement_TXT[profile?.language]} {element.postdate}</Typography>
                                        </Stack>
                                    </Box>

                                    <Box sx={{
                                        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.6) 10%), url('${element["images"]["image1024"]}')`,
                                        height: "200px",
                                        width: "85%",
                                        borderRadius: "10px",
                                        marginTop: 2,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "center",

                                    }} onClick={() => {
                                        // loadDetail(element.id)
                                        // setIsDetail(true)
                                        navigate(`/projectInfo/promotionDetail${location.search}`, {
                                            state: {
                                                id: element.id,
                                                title: element.title
                                            }
                                        })
                                    }}>
                                        <Box sx={{ height: "70px", width: "100%" }}>
                                            <Typography variant={"body1"} sx={{ color: "white", textAlign: "justify", marginLeft: 1 }}>
                                                {element.title}
                                            </Typography>

                                        </Box>
                                    </Box>
                                </Stack>

                            </Card>)
                        }
                        <Box sx={{ marginBottom: 10 }}/>
                    </InfiniteScroll>

                    {
                        promotionList?.length === 0 && <BlankPage lang={profile?.language}/>
                    }

                </Box>
            </>
            }
        </>
    )
}
