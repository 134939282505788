import React, { useState, useEffect } from 'react'
import {
     Typography, Grid, Container,
     AppBar
} from '@mui/material'
import { useLocation } from 'react-router-dom';
import * as api from '../../api/Loan'
import { ModalError } from '../../ui-component';
import { BlankPage } from '../../ui-component';
import { LoanDetailComponent } from './LoanDetailComponent';
import { BackDropLoading } from '../../ui-component';
export const LoanNoti = () => {

    const [profile, setProfile] = useState(null)
    const [detail, setDetail] = useState(null)
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    let productType = params.get("productType")
    let lineId = params.get("lineId")
    let language = params.get('language')
    let loanId = params.get("loanId")
    let bankName = decodeURIComponent(params.get("bankName"))
    let color = decodeURIComponent(params.get("color"))

    const loadDetail = async () => {
        setLoading(true)
        let res = await api.getLoanById({
            "line_id": profile?.line_id,
            "language": profile?.language,
            "product_type": productType,
            "loan_id": loanId,
        })
        if (res?.status === 200) {
            setDetail(res.data?.data)
            setLoading(false)
            return
        }
        setError(res)
        setModalError(true)
        setLoading(false)
        return
    }
    useEffect(() => {
        if (profile !== null) {
            loadDetail()
        }
    }, [profile]);

    useEffect(() => {
        setProfile({
            language,
            line_id: lineId
        })
    }, [lineId])

    useEffect(() => {
        document.title = "Loan"
    }, [])

    return (
        <div>
            < AppBar position="fixed"
                sx={{
                    boxShadow: 'none',
                    height: 60,
                    backgroundColor: color
                }}
            >
                <Container maxWidth="xl" >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                    >
                        <Grid container item xs={10} direction="row"
                            justifyContent="center"
                        >
                            <Typography  >
                                {bankName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </AppBar >


            {
                detail === null ? <BlankPage /> : <LoanDetailComponent detail={detail} profile={profile}/>
            }

            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
               <BackDropLoading
                loading={loading}
            />
        </div>
    )
}

