import { Routes, Route, useLocation , useNavigate} from "react-router-dom";
import {
  About,
  Parcel,
  ProjectPage,
  MyHome,
  PrivacyPolicy,
  Defect,
  PhoneBook,
  Facility,
  ContactJuristic,
  DefectDetail,
  Booking,
  History,
  AddressSendDoc,
  JuristicList,
  Regulations,
  RegulationDetail,
  BillPayment,
  LoanApprove,
  DefectFormAdd,
  ParcelDetail,
  Register,
  Language,
  Paid,
  Suggestion,
  Term,
  Policy,
  AmountPayment,
  HistoryDetail,
  SelectFacility,
  FormBooking,
  ConfirmBooking,
  Gallery,
  ProjectDetail,
  Plan,
  PlanDetail,
  MapDialog,
  AdvertiseDialog,
  InfoDialog,
  PromotionDetail,
  CampaignDetail,
  AdsDetail,
  Profile,
  ProfileDetail,
  ProjectDialog,
  ContractInfo,
  PriceInfo,
  BankSelecting,
  CreditCardSelecting,
  ResultSuccess,
  ResultFailed,
  Equipment,
  DefectDoc,
  LoanNoti,
  LoanCP,
  LoanCPDocument,
  LoanCPDocumentDetail,
  LoanCPCalculate,
  LoanCPStatus,
  LoanCPStatusDetail,
  InspectCP,
  InspectCPStatus,
  Etc,
  TransferExpense,
  TransferInfo,
  TransferLetter,
  TransferProcess,
  Manual,
  ManualDetail,
  NoProfile
} from "../views";
import React, { useEffect, useContext, useCallback } from "react";
import liff from "@line/liff";
import * as api from "../api/Route";
import { SET_PROFILE, ProfileContext } from "../contexts/ProfileContext";
import { LoadingContext, SET_LOADING } from "../contexts/LoadingContext";
import { Transfer } from "../views/Transfer/Transfer";
export default function Router(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const { dispatch } = useContext(LoadingContext);
  const context = useContext(ProfileContext);
  const ctxDispatch = context.dispatch

  const setLoading = useCallback((trigger) => {
    dispatch({
      type: SET_LOADING,
      payload: trigger,
    });
  }, [dispatch]);

  const setProfileContext = useCallback((data) => {
    let customerProfile = data;
    if (customerProfile === null) {
      return;
    }
    ctxDispatch({
      type: SET_PROFILE,
      payload: customerProfile,
    });
  }, [ctxDispatch]);


  const getCustomerProfile = useCallback(async (userId) => {
    if(!userId) return
    setLoading(true);
    let response = await api.getProfile({
      line_id: userId,
    });
    let data = response?.data;
    setProfileContext(data);
    setLoading(false);
  }, [setLoading, setProfileContext]);


  const initGetProfile = useCallback((cid, cid_pm) => {
    liff
      .getProfile()
      .then((profile) => {
        let obj = {
          cid: cid,
          cid_pm: cid_pm,
          profile: profile,
        };
        let userId = profile.userId;
        let pictureURL = profile.pictureUrl;
        localStorage.setItem("userId", userId);
        localStorage.setItem("pictureURL", pictureURL);
        getCustomerProfile(userId);
        localStorage.setItem("profile_lineOA", JSON.stringify(obj));
      })
      .catch((err) => {
        console.log("error", err);
      });
  },[getCustomerProfile]);

  const initLiffApp = useCallback((liffId, cid, cid_pm) => {
    if (
      location.pathname === "/billpayment/resultSuccess" ||
      location.pathname === "/billpayment/resultFailed" ||
      location.pathname === "/loan/noti"
    ) {
      return;
    }

    liff
      .init({
        liffId: liffId, // Use own liffId
        withLoginOnExternalBrowser: true,
      })
      .then(() => {
        // start to use LIFF's api

        if (!liff.isLoggedIn()) {
          return liff.login();
        }

        initGetProfile(cid, cid_pm);

        return localStorage.setItem("isLoggedIn", true);
      })
      .catch((err) => {
        console.log(err);
      });
  },[initGetProfile, location.pathname]);


 
  useEffect(() => {
    window.scrollTo(0, 0);

    const listenChangeLocation = () => {
      ///-----------------------------------
      let params = new URLSearchParams(location.search);
      let liffId = params.get("liff");
      let cid = params.get("cid");
      let cid_pm = params.get("cid_pm");
      let cid_rental = params.get("cid_rental");
      let kbank = params.get("kbank");

      localStorage.setItem("kbank", kbank);

      const resetUser = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userId");
      };

      if (!cid) {
        localStorage.removeItem("cid");
      }

      if (!cid_pm) {
        localStorage.removeItem("cid_pm");
      }

      if (!cid_rental) {
        localStorage.removeItem("cid_rental");
      }

      let cid_cache = localStorage.getItem("cid");
      // if (cid_cache == undefined || cid_cache == null) {
      //     localStorage.setItem("cid", cid)
      // }

      if (cid && cid !== cid_cache) {
        resetUser();
        localStorage.setItem("cid", cid);
      }

      let cid_pm_cache = localStorage.getItem("cid_pm");
      // if ((cid_pm_cache == undefined || cid_pm_cache == null)) {
      //     localStorage.setItem("cid_pm", cid_pm)
      // }
      if (cid_pm && cid_pm !== cid_pm_cache) {
        resetUser();
        localStorage.setItem("cid_pm", cid_pm);
      }

      let cid_rental_cache = localStorage.getItem("cid_rental");
      if (cid_rental && cid_rental !== cid_rental_cache) {
        resetUser();
        localStorage.setItem("cid_rental", cid_rental);
      }
      // let liffId_cache = localStorage.getItem("liffId");
      // if (liffId_cache == undefined || liffId_cache == null) {
      //     localStorage.setItem("liffId", liffId)
      // }
      localStorage.setItem("liffId", liffId);

      // if (cid !== cid_cache) {
      //     localStorage.removeItem("isLoggedIn")
      //     localStorage.removeItem("userId")
      //     localStorage.setItem("cid", cid)
      // }

      // if (cid_pm !== cid_pm_cache && cid_pm !== null) {
      //     localStorage.setItem("cid_pm", cid_pm)
      // }

      let profile = localStorage.getItem("profile_lineOA");
      if (profile === undefined || profile === null) {
        localStorage.setItem("isLoggedIn", "");
      }

      let login = localStorage.getItem("isLoggedIn");
      if (login !== "true") {
        initLiffApp(
          liffId,
          localStorage.getItem("cid"),
          localStorage.getItem("cid_pm")
        );
        return;
      }

      // let userIdFromLineOA = localStorage.getItem("userId")
      // if(userIdFromLineOA !== undefined) {
      //     getCustomerProfile(userIdFromLineOA)
      // }

      //clear cache
      // localStorage.clear()
    };

    listenChangeLocation();
  }, [location, initLiffApp]);

  useEffect(() => {
    const checkUserId = () => {
      let userIdFromLineOA = localStorage.getItem("userId");
      if (userIdFromLineOA === undefined) return;

      if (context?.state.profile === null) {
        return getCustomerProfile(userIdFromLineOA);
      }
    };
    checkUserId();
  }, [location, context?.state.profile, getCustomerProfile]);

  return (
    <Routes>
      <Route path="language" element={<Language />} />
      <Route path="registration" element={<Register />} />
      <Route path="registration/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="about" element={<About />} />
      <Route path="myhome" element={<MyHome />} />
      <Route path="parcel" element={<Parcel />} />
      <Route path="parcel/parcelDetail" element={<ParcelDetail />} />
      <Route path="defectfast" element={<Defect version={"fast"} />} />
      <Route path="defect" element={<Defect version={"std"} />} />
      <Route path="defect/defectDetail" element={<DefectDetail />} />
      <Route path="defect/formAdd" element={<DefectFormAdd />} />
      <Route path="phonebook" element={<PhoneBook />} />
      <Route path="facility" element={<Facility />} />
      <Route path="facility/booking" element={<Booking />} />
      {/* <Route path="facility/detail" element={<FacilityDetail />} /> */}
      <Route path="facility/selectFacility" element={<SelectFacility />} />
      <Route path="facility/formBooking" element={<FormBooking />} />
      <Route path="facility/confirmBooking" element={<ConfirmBooking />} />
      <Route path="facility/history" element={<History />} />
      <Route path="billpayment" element={<BillPayment />} />
      <Route path="billpayment/amountPayment" element={<AmountPayment />} />
      <Route path="billpayment/paid" element={<Paid />} />
      <Route path="billpayment/historyDetail" element={<HistoryDetail />} />
      <Route path="billpayment/bankSelecting" element={<BankSelecting />} />
      <Route
        path="billpayment/creditCardSelecting"
        element={<CreditCardSelecting />}
      />
      <Route path="billpayment/resultSuccess" element={<ResultSuccess />} />
      <Route path="billpayment/resultFailed" element={<ResultFailed />} />
      <Route path="profile" element={<Profile />} />
      <Route path="profile/contractInfo" element={<ContractInfo />} />
      <Route path="profile/policy" element={<Policy />} />
      <Route path="profile/priceInfo" element={<PriceInfo />} />
      <Route path="profile/profileDetail" element={<ProfileDetail />} />
      <Route path="profile/project" element={<ProjectDialog />} />
      <Route path="profile/term" element={<Term />} />
      <Route path="loan" element={<LoanApprove />} />
      <Route path="loan/result" element={<LoanApprove />} />
      <Route path="loan/noti" element={<LoanNoti />} />
      <Route path="loancp" element={<LoanCP />} />
      <Route path="loancp/document" element={<LoanCPDocument />} />
      <Route path="loancp/document/detail" element={<LoanCPDocumentDetail />} />
      <Route path="loancp/calculate-loan" element={<LoanCPCalculate />} />
      <Route path="loancp/status" element={<LoanCPStatus />} />
      <Route path="loancp/status/detail" element={<LoanCPStatusDetail />} />
      <Route path="projectInfo" element={<ProjectPage />} />
      <Route path="projectInfo/gallery" element={<Gallery />} />
      <Route path="projectInfo/detail" element={<ProjectDetail />} />
      <Route path="projectInfo/plan" element={<Plan />} />
      <Route path="projectInfo/plan/detail" element={<PlanDetail />} />
      <Route path="projectInfo/mapDialog" element={<MapDialog />} />
      <Route path="projectInfo/advertise" element={<AdvertiseDialog />} />
      <Route path="projectInfo/info" element={<InfoDialog />} />
      <Route path="projectInfo/promotionDetail" element={<PromotionDetail />} />
      <Route path="projectInfo/campaignDetail" element={<CampaignDetail />} />
      <Route path="chatniti" element={<ContactJuristic />} />
      <Route path="addressdoc" element={<AddressSendDoc />} />
      <Route path="contactsniti" element={<JuristicList />} />
      <Route path="suggestion" element={<Suggestion />} />
      <Route path="regulations" element={<Regulations />} />
      <Route path="regulations/detail" element={<RegulationDetail />} />
      <Route path="adsDetail" element={<AdsDetail />} />
      <Route path="equipment" element={<Equipment />} />
      <Route path="defectdoc" element={<DefectDoc />} />
      <Route path="inspectcp" element={<InspectCP />} />
      <Route path="inspectcp/status" element={<InspectCPStatus />} />
      <Route path="transfercp" element={<Transfer />} />
      <Route path="transfercp/expense" element={<TransferExpense />} />
      <Route path="transfercp/info" element={<TransferInfo />} />
      <Route path="transfercp/letter" element={<TransferLetter />} />
      <Route path="transfercp/info/process" element={<TransferProcess />} />
      <Route path="etc" element={<Etc />} />
      <Route path="manualcp" element={<Manual />} />
      <Route path="manualcp/detail" element={<ManualDetail />} />
      <Route path="noprofile" element={<NoProfile/>} />
    </Routes>
  );
}
