import React, { useState, useEffect, useContext } from 'react'
import {
    Container,
    Typography,
    Box
} from '@mui/material'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProfileContext } from '../../../../contexts/ProfileContext';
import 'react-medium-image-zoom/dist/styles.css'
import { ZoomCarousel } from '../../../../ui-component'
import { useLocation } from 'react-router-dom';
import * as dict from "../../../../dictionary"

export const PlanDetail = (props) => {

    const [openCarousel, setOpenCarousel] = useState(false)
    const [profile, setProfile] = useState(null)
    const context = useContext(ProfileContext)
    const location = useLocation()


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
        <>
            <Box sx={{ marginTop: 10 , padding: 2}}>
                <Carousel responsive={responsive}>
                    {
                       location.state?.gallery ? location.state?.gallery.length > 0 && location.state?.gallery.map((element, index) => {
                            return <div key={index} onClick={() => setOpenCarousel(true)}>
                                <img src={element["image1024"]} style={{ maxHeight: '200px' }} />
                            </div>

                        }) : <div/>
                    }
                </Carousel>

                {
                    location.state?.gallery.length === 0 && <Box sx={{ textAlign: "center", alignItems: "center", margin: 5, padding: 5, backgroundColor: "#F0F0F0" }}>
                        <ImageNotSupportedIcon sx={{ fontSize: 40 }} />
                        <Typography variant={"h4"}>Image Not Found</Typography>
                    </Box>
                }
            </Box>

            <Box>
                <Container>
                    <Box sx={{ padding: 2 }}>
                        <Typography variant={"h5"}>{dict.Plan_TXT[profile?.language]} : {location.state?.planDetail?.unit_type}</Typography>
                        <Typography variant={"body2"}>{dict.StartingPrice_TXT[profile?.language]} : {(location.state?.planDetail?.price)?.toLocaleString()}</Typography>
                        <Typography variant={"body2"}>{dict.Location_TXT[profile?.language]} : {location.state?.planDetail?.location}</Typography>
                        <Typography variant='body2'>{dict.Area_TXT[profile?.language]} : {location.state?.planDetail?.area} {dict.Square_Meter_TXT[profile?.language]}</Typography>
                        <Typography variant='body2'>{dict.Bedroom_TXT[profile?.language]} : {location.state?.planDetail?.bedroom}</Typography>
                        <Typography variant='body2'>{dict.Bathroom_TXT[profile?.language]} : {location.state?.planDetail?.bathroom}</Typography>
                        <Typography variant='body2'>{dict.LivingRoom_TXT[profile?.language]} : {location.state?.planDetail?.livingroom}</Typography>
                        <Typography variant='body2'>{dict.ParkingSpace_TXT[profile?.language]} : {location.state?.planDetail?.car_amount}</Typography>
                        <br />
                        <Typography variant='h5'>{dict.Detail_TXT[profile?.language]} :</Typography>

                        <div dangerouslySetInnerHTML={{ __html: (location.state?.planDetail?.description) }} />

                    </Box>


                </Container>
            </Box>
            <ZoomCarousel
                open={openCarousel}
                onClose={() => setOpenCarousel(false)}
                gallery={location.state?.gallery}
            />

        </>

    )

}

