
import React, { useState , useEffect, useContext} from 'react'
import {
    Stack, Divider, Button,
    Typography, Box, CircularProgress
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm} from "react-hook-form";
import { CardResponse } from '../../../ui-component';
import * as api from '../../../api/'
import * as dict from '../..//../dictionary'
import { ProfileContext } from '../../../contexts/ProfileContext';
export const ConfirmBooking = () => {
    const { handleSubmit } = useForm();
    const [loadingResponse, setLoadingResponse] = useState(false)
    const [profile, setProfile] = useState(null)
    const [alertDialog, setAlertDialog] = useState({
        type: "success",
        title: "",
    })
    const [openAlertDialog, setOpenAlertDialog] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(ProfileContext)

    const handleDialogSuccessClose = () => {
        // setAlertDialog({
        //     type: alertDialog.type,
        //     title: "",
        //     trigger: false
        // })
        setOpenAlertDialog(false)
        if (alertDialog.type === "success") {
            navigate(`/facility${location.search}`)
        }
    }
    const handleResponse = () => {
        setLoadingResponse(false)
        setOpenAlertDialog(true)
        setAlertDialog({
            type: "success",
            title: `${dict.System_Save_Book_TXT[profile?.language]}`,
        })
    }
    const handleSaveBooking = async () => {
        setLoadingResponse(true)
        let optionId = location.state?.optionSelected.map(element => {
            return element.value
        })
        let res = await api.bookingFacility({
            "line_id": location.state?.line_id,
            "language": location.state?.language,
            "product_type": location.state?.product_type,
            "facilities_id": location.state?.facilities_id,
            "date": location.state?.date,
            "bookin_start": location.state?.bookin_start,
            "booking_end": location.state?.booking_end,
            "hour_amount": location.state?.hour_amount,
            "house_number": location.state?.house_number,
            "option_id": optionId || [],
            "project_id": location.state?.project_id,
            "total_price": 0,
            "unit_id": location.state?.unit_id
        })
        let resultCode = res.data?.header?.resultcode
        let resultMessage = res.data?.header?.message
        if (resultCode === 200) {
            handleResponse()
            return
        }
        if (resultCode === 400) {
            setLoadingResponse(false)
            setOpenAlertDialog(true)
            setAlertDialog({
                type: "error",
                title: resultMessage,
            })
            return
        }
    }

    const onError = (errors, e) => console.log(errors)

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])

    return (
        <Box
            onSubmit={handleSubmit(handleSaveBooking, onError)}
            sx={{ height: "100%", marginTop: 8, padding: 2 }}>
            <Stack direction={"column"} spacing={1} >
                <Box >
                    <Divider textAlign="left" sx={{ marginTop: 3 }} >  <Typography variant="h6">{dict.Room_info_TXT[profile?.language]}</Typography> </Divider>
                    <Box sx={{ padding: 1 }}>
                        <Typography variant='body1' color="text.secondary">{dict.Room_TXT[profile?.language]}: {location.state?.house_number}</Typography>
                        <Typography variant='body1' color="text.secondary">{dict.Project_TXT[profile?.language]}: {location.state?.project_name}</Typography>
                    </Box>
                </Box>
                <Box >
                    <Divider textAlign="left" sx={{ marginTop: 3 }} >  <Typography variant="h6">{dict.Specify_Date_Book_TXT[profile?.language]}</Typography> </Divider>
                    <Box sx={{ padding: 1 }}>
                        <Typography variant='body1' color="text.secondary">{dict.Date_TXT[profile?.language]} : {location.state?.date}</Typography>
                        <Typography variant='body1' color="text.secondary">{dict.Time_TXT[profile?.language]} :  {location.state?.bookin_start}-{location.state?.booking_end}</Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: 2 }}>
                    <Divider textAlign="left" >  <Typography variant="h6">{dict.Accessories_TXT[profile?.language]}</Typography> </Divider>
                    {
                        location.state?.optionSelected?.map((opt, index) =>
                            <Stack direction={"row"} sx={{ width: "100%" }}>
                                <Typography variant='body2'>{index + 1}. {opt.name}</Typography>
                            </Stack>

                        )
                    }
                </Box>
                <Typography variant="caption" sx={{ color: "#b30000" }} >
                    *{dict.Please_Confirm_Before_TXT[profile?.language]}
                    **{dict.Cancel_Book_Auto_TXT[profile?.language]}
                </Typography>
            </Stack>
            <Divider />
            {loadingResponse == true &&
                <Box sx={{ display: 'flex', justifyContent: "center", marginTop: 3 }}>
                    <CircularProgress />
                </Box>
            }
            {loadingResponse == false &&
                <Button variant="contained" sx={{ width: "100%", marginTop: 3 }} onClick={() => {
                    handleSaveBooking()
                }}> {dict.Save_TXT[profile?.language]} </Button>
            }
            <CardResponse
                type={alertDialog.type}
                titleDescription={alertDialog.title}
                open={openAlertDialog}
                onClose={() => {
                        handleDialogSuccessClose()
                }}
                onOk={() => {
                        handleDialogSuccessClose()
                }}
            />
        </Box>
    )
}
