import axios from "axios";

export const updateProfile = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/profile/update`,
            data: {
                "line_id": data.line_id,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "first_name_en": data.first_name_en,
                "last_name_en": data.last_name_en,
                "email": data.email,
                "mobile_no": data.mobile_no,
                "birthday": data.birthday
            }
        })
        return result

    } catch (error) {
        return error
    }
}