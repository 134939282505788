import React, { useEffect, useState, useContext } from 'react'
import * as api from '../../api/About'
import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { ProfileContext } from '../../contexts/ProfileContext'
import { ModalError } from '../../ui-component'
export const Term = () => {

    const [detail, setDetail] = useState(null)
    const [profile, setProfile] = useState(null);
    const [modalError, setModalError] = useState(false);
    const [error, setError] = useState(null);
    const context = useContext(ProfileContext)

    useEffect(() => {

        const loadDetail = async () => {

            let res = await api.getAboutTerm({
                "line_id": profile?.line_id
            })

            if (res?.status === 200) {
                setDetail(res.data?.data)
                return
            }

            setError(res)
            setModalError(true)
            return

        }

        if (profile !== null) {
            loadDetail()
        }
    }, [profile]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (
        <>
            <Container sx={{ marginTop: 10 }}>
                <Box sx={{ width: "100%" }}>
                    <Box mt={7} sx={{ width: "100%", overflow: "scroll" }}>
                        <div dangerouslySetInnerHTML={{ __html: detail }} />
                    </Box>
                </Box>

            </Container>
            <ModalError
                onClose={() => setModalError(false)}
                open={modalError}
                error={error}
            />
        </>

    )
}
