import React from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PhoneIcon from '@mui/icons-material/Phone';
function CardCallCenter(props) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        background: "#FFFFFF",
        boxShadow: "0px 2px 10px 0px #0000001A",
        borderRadius: "10px",
      }}
    >
      <Stack
        spacing={0.2}
        direction="column"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        mt={0.1}
      >
        <Typography fontWeight={"bold"} sx={{ wordBreak: "break-word"}}>
          {props?.title}
        </Typography>
        <Typography variant="caption" sx={{ color: "#828282" }}>
          {props?.tel}
        </Typography>
      </Stack>

      <Button href={`tel:${props?.tel}`} sx={{
          minHeight: "50px", minWidth: "50px",
          textAlign: "center", alignItems: "center",
          alignContent:"center",
          border: "1px",
          borderRadius: 100, backgroundColor: theme.palette.primary.main
        }}>

          <PhoneIcon sx={{ color: theme.palette.primary.contrastText }} />

      </Button>
    </Box>
  );
}

export default CardCallCenter;
