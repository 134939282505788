import {
    Stack,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
  } from "@mui/material";
import CardCallCenter from "./CardLoanCP/CardCallCenter";
import CloseIcon from "@mui/icons-material/Close";

export const DialogPhone = ({ title , handleClose , open,  list}) => {

    return <>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
                sx: { borderRadius: "10px", width: "100%", minHeight: "600px" },
            }}
        >
            <DialogTitle
                sx={{ m: 0, p: 2, mt: 0.4 }}
                id="customized-dialog-title"
            >
               <Typography variant={"h6"} fontWeight={"bold"}> { title } </Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    mt: 0.8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Stack mt={1} spacing={2} direction="column">
                    {list?.map((item, index) => (
                            <CardCallCenter key={index} {...item} />
                    ))}
                </Stack>
            </DialogContent>
        </Dialog>
    </>
}