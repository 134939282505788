import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const CarouselCustom = (props) => {

    const { images } = props

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };
    return (
        <>
            <Carousel responsive={responsive}>

                {
                    images.length > 0 ? images?.map(element => <div key={element.sort_id} style={{ height: "200px" }} onClick={() => window.open(element.url)}>
                        <img alt="©customer portal v2" src={element.url} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                    </div>) : <div />
                }
            </Carousel>
        </>
    )
}