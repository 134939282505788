import axios from "axios";

export const suggestionCreate = async (data) => {
    try {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/suggestion/create`,
            data:
            {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "title": data.title,
                "description": data.description,
                "mobile_no": data.mobile_no,
                "project_id": data.project_id,
                "unit_number": data.unit_number,
                "house_number": data.house_number,
                "file_gallery": data.file_gallery
            }


        })
        return res
    } catch (error) {
        return error
    }
}