import styled from "@emotion/styled";
import {
  Stack,
  Container,
  Box,
  Typography,
  Divider,
  TextField,
  FormControl
} from "@mui/material";
import React from "react";
import { useState, useContext, useEffect } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import NumberFormat from "react-number-format";
import * as dict from "../../../dictionary"
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

export const LoanCPCalculate = () => {
  const [profile, setProfile] = useState(null);
  const context = useContext(ProfileContext);
  const formatCurrency = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const [P, setP] = useState();
  const [r, setR] = useState();
  const [n, setN] = useState();

  const PMT = ((((P * r) / 100) + (2 * P)) * 31) / (365 * n);

  useEffect(() => {
    if (context.state?.profile !== null) {
      setProfile(context.state?.profile?.customer[0]);
    }

  }, [context]);

  return (
    <>
      <Box sx={{ height: "calc(100vh-60px)" }}>
        <Container
          sx={{
            minHeight: "100%",
            paddingTop: 2,
            paddingBottom: 5,
          }}
        >
          <Stack mt={1} spacing={2} direction={"column"}>
            <BoxStyled>
              <Stack py={2.6} px={4}>
                <Typography
                  variant="h6"
                  sx={{ color: "#3A6777", fontWeight: 700 }}
                >
                  {dict.Loan_Calculation_TXT[profile?.language]}
                </Typography>
              </Stack>
              <Divider />
              <br />
              <Stack spacing={0.8} direction={"column"} py={1.2} px={4}>
                <Typography variant="body2" sx={{ color: "black" }}>
                  {dict.Amount_To_Borrower_TXT[profile?.language]}
                </Typography>
                <FormControl>
                  <TextField
                    size="small"
                    value={P}
                    onChange={(e) => setP(e.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      sx: {

                      }
                    }}
                    sx={{
                      " & .MuiOutlinedInput-notchedOutline": {
                        boxShadow: "0px 0px 3px 0px #00000026",
                        borderRadius: "7px",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={0.8} direction={"column"} py={1.2} px={4}>
                <Typography variant="body2" sx={{ color: "black" }}>
                  {dict.Interest_Rate_TXT[profile?.language]}
                </Typography>
                <FormControl sx={{ display: "inline-block" }}>
                  <TextField
                    size="small"
                    value={r}
                    onChange={(e) => setR(e.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    sx={{
                      width: "75%",
                      " & .MuiOutlinedInput-notchedOutline": {
                        boxShadow: "0px 0px 3px 0px #00000026",
                        borderRadius: "7px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  <TextField
                    size="small"
                    value={"%"}
                    disabled
                    sx={{
                      width: "25%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DFE4EB !important",
                      },
                      "& .MuiOutlinedInput-input.MuiInputBase-input":
                      {
                        borderWidth: 1,
                        textAlign: "center",
                        backgroundColor: "#DFE4EB !important",
                        borderRadius: "7px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      },
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={0.8} direction={"column"} py={1.2} px={4}>
                <Typography variant="body2" sx={{ color: "black" }}>
                  {dict.Period_Time_TXT[profile?.language]}
                </Typography>
                <FormControl sx={{ display: "inline-block" }}>
                  <TextField
                    size="small"
                    value={n}
                    onChange={(e) => setN(e.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    sx={{
                      width: "75%",
                      " & .MuiOutlinedInput-notchedOutline": {
                        boxShadow: "0px 0px 3px 0px #00000026",
                        borderRadius: "7px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  <TextField
                    size="small"
                    value={dict.Year_TXT[profile?.language]}
                    disabled   
                    sx={{
                      width: "25%",
                      '& .MuiOutlinedInput-input.MuiInputBase-input ': {
                        borderWidth: 1,
                        textAlign: "center",
                        backgroundColor: "#DFE4EB !important",
                        borderRadius: "7px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      },
                      '& .MuiOutlinedInput-notchedOutline ': {
                        borderColor: "#DFE4EB !important",
                      }
                    }}
                
                  />
                </FormControl>
              </Stack>
              <Stack
                spacing={2}
                direction={"column"}
                alignItems={"end"}
                py={2}
                px={4}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#01002A",
                    fontSize: "16px",
                    marginTop: "18px !important",
                  }}
                >
                  {dict.Monthly_Payment_Amount_TXT[profile?.language]}
                </Typography>

                {PMT ? (
                  <div style={{ wordBreak: "break-all" }}>
                    <Typography
                      sx={{
                        color: "#3A6777",
                        fontWeight: 700,
                        fontSize: "32px",

                      }}
                    >
                      {formatCurrency(PMT?.toFixed(2))}&nbsp;{dict.Baht_TXT[profile?.language]}
                    </Typography>
                  </div>
                ) : (
                  <Typography
                    sx={{
                      color: "#3A6777",
                      fontWeight: 700,
                      fontSize: "32px",
                    }}
                  >
                    0.00
                  </Typography>
                )}

              </Stack>
            </BoxStyled>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 700;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 0px #00000040;
  background-color: #ffffff;
`;
