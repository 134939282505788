import axios from 'axios'

export const getInspectStatus = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/fastinspect/transfer_unit`,
            data: {
                "line_id": data.line_id,
                "project_id": data.project_id,
                "product_type": data.product_type,
                "house_number": data.house_number
            }
        })
        return result
    } catch (error) {
        return error
    }

}
