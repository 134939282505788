import {
    Stack,
    Typography,
    Box,
    Divider,
    Link
} from '@mui/material'
import * as api from '../api/ProjectContact'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../src/contexts/ProfileContext'
import { useTheme } from '@mui/material/styles';
import lineIcon from '../assets/images/logo/line.png'
import telephoneIcon from '../assets/images/logo/telephone.png'
import mailBoxIcon from '../assets/images/logo/email.png'
import { BackDropLoading } from '../ui-component';
import * as dict from '../dictionary'
export const JuristicList = () => {
    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [detail, setDetail] = useState(null)
    const context = useContext(ProfileContext)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const theme = useTheme()
    const loadDetail = async () => {
        setLoadingBackDrop(true)
        let res = await api.getJuristicContact({
            "line_id": profile?.line_id,
            "product_type": houseInfo?.product_type,
            "project_id": houseInfo?.project_id
        })
        setDetail(res.data?.data)
        setLoadingBackDrop(false)
    }

    useEffect(() => {

        if (profile !== null) {
            loadDetail()
        }

    }, [profile, houseInfo]);


    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }

    }, [context]);

    return (
        <>
            <BackDropLoading
                loading={loadingBackDrop}
            />
            <Box sx={{ marginTop: 8 }}>

                <Box sx={{ padding: 3 }}>
                    <Typography variant={"body2"} sx={{ color: theme.palette.text.primary }}>{dict.Contact_TXT[profile?.language]}</Typography>
                    <br />
                    {
                        detail?.length > 0 && detail.map(element => <>
                            <Stack direction={"column"} spacing={3} >
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                        <img
                                            src={telephoneIcon}
                                            width={"50px"}
                                            height={"50px"}
                                        />
                                        <Stack direction={"column"}>
                                            <Typography variant='body1' sx={{ fontStyle: "bold", color: theme.palette.text.primary }}>{element.tel}</Typography>
                                            <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>{dict.Contact_Number_TXT[profile?.language]}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Link href={`tel:${element.tel}`} underline="none" variant="body1">{dict.Tel_TXT[profile?.language]}</Link>
                                </Stack>
                                <Divider />

                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                        <img
                                            src={lineIcon}
                                            width={"50px"}
                                            height={"50px"}
                                        />
                                        <Stack direction={"column"}>
                                            <Typography variant='body1' sx={{ fontStyle: "bold" , color: theme.palette.text.primary }}>{element.line}</Typography>
                                            <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>{dict.LineOA_TXT[profile?.language]}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Link href={`http://line.me/ti/p/~${element.line}`} underline="none" variant="body1">{dict.Contact_TXT[profile?.language]}</Link>
                                </Stack>
                                <Divider />

                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} >
                                    <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                        <img
                                            src={mailBoxIcon}
                                            width={"50px"}
                                            height={"50px"}
                                        />
                                        <Stack direction={"column"}>
                                            <Typography variant='body1' sx={{ fontStyle: "bold" , color: theme.palette.text.primary}}>{element.email}</Typography>
                                            <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>{dict.Primary_Email_Juristic_TXT[profile?.language]}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Link href={`mailto:${element.email}`} underline="none" variant="body1" >{dict.Contact_TXT[profile?.language]}</Link>
                                </Stack>
                                <Divider />
                            </Stack>
                        </>
                        )
                    }
                </Box>

            </Box>
        </>
    )
}