import React from 'react';

import {
    AppBar, Toolbar, Box , Dialog , Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const ZoomCarousel = (props) => {


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    return (
        <>
             <Dialog
                fullScreen
                TransitionComponent={Transition}
                onClose={() => {
                    props.onClose()
                }}
                open={props.open}

            >
                    <>

                        <Box sx={{ backgroundColor: "#000000", height: "100vh" }}>
                            <AppBar sx={{
                                background: 'transparent ',
                                position: 'relative',
                                boxShadow: 'none',
                                height: 60,
                            }}>
                                <Toolbar>
                                    <Close

                                        sx={{ fontSize: 30, color: "white" }}
                                        onClick={() => props?.onClose()}
                                        aria-label="close"
                                    />

                                </Toolbar>


                            </AppBar>
                            <Box sx={{
                             
                                marginTop:"50%"
                            }}>
                                <Carousel responsive={responsive}>
                                    {
                                        props?.gallery ? props?.gallery?.map(element => <div >

                                            <img alt={"©customer portal v2"} src={element["image1024"]} style={{ objectFit: "contain", width: "100%", height: 200 }} />

                                        </div>) : <div />
                                    }
                                </Carousel>
                            </Box>




                        </Box>

                    </>
            
                </Dialog>
        </>
    );
}