export const ToPickUp_TXT = []
ToPickUp_TXT["en"] = "TO PICK UP"
ToPickUp_TXT["th"] = "ที่ต้องไปรับ"

export const History_TXT = []
History_TXT["en"] = "History"
History_TXT["th"] = "ประวัติ"

export const Search_TXT = []
Search_TXT["en"] = "Search"
Search_TXT["th"] = "ค้นหาพัสดุ"

export const Receiver_TXT = []
Receiver_TXT["en"] = "Receiver"
Receiver_TXT["th"] = "ผู้รับ"

export const SendTo_TXT = []
SendTo_TXT["en"] = "To"
SendTo_TXT["th"] = "ส่งถึง"

export const Type_TXT = []
Type_TXT["en"] = "Type"
Type_TXT["th"] = "ประเภท"

export const DateReceived_TXT = []
DateReceived_TXT["en"] = "Login"
DateReceived_TXT["th"] = "วันที่รับเข้า"

export const OwnerDateReceived_TXT = []
OwnerDateReceived_TXT["en"] = "Received Date"
OwnerDateReceived_TXT["th"] = "วันที่รับพัสดุ"


export const Tip_TXT = []
Tip_TXT["en"] = "Scan qrcode for receive parcel"
Tip_TXT["th"] = "แสดงคิวอาร์โค๊ด ที่นิติบุคคล เพื่อรับพัสดุ"

export const Sender_TXT = []
Sender_TXT["en"] = "Sender"
Sender_TXT["th"] = "ผู้จ่ายพัสดุ"

export const Confirm_TXT = []
Confirm_TXT["en"] = "CONFIRM"
Confirm_TXT["th"] = "ได้รับพัสดุแล้ว"