import React from 'react'
import emptyIcon from '../assets/images/logo/box.png'
import {
    Typography,
    Box,
    Stack
} from '@mui/material'

export const BlankPageDetail = ({ lang }) => {
    return (
        <div>

            <Box sx={{ display: "flex", justifyContent: "center", minHeight: "70vh", opacity: 0.3 }}>

                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>

                    <img
                        src={emptyIcon}
                        width={"100px"}
                        height={"100px"}
                        alt={"blank page"}
                    />
                    <Typography variant='h6'>{lang === "th" ? "ยังไม่มีข้อมูล" : "No Information"}</Typography>
                </Stack>
            </Box>

        </div>
    )
}
