import {
    Stack,
    Container,
    Typography, 
    Box
} from '@mui/material'
import * as api from '../api/AddressSendDoc'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../src/contexts/ProfileContext'
import { BackDropLoading } from '../ui-component/BackDropLoading'
import * as dict from '../dictionary'
import { useTheme } from '@mui/material'
export const AddressSendDoc = () => {
    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [detail, setDetail] = useState(null)
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const theme = useTheme()
    const context = useContext(ProfileContext)

    useEffect(() => {

        const loadDetail = async () => {
            setLoadingBackDrop(true)
            let res = await api.getAddressSendDoc({
                "line_id": profile?.line_id,
                "product_type": houseInfo?.product_type,
                "unit_id": houseInfo?.unit_id,
                "mobile": profile?.mobile,
                "mobile_2": profile?.mobile_2,
                "phone_international": profile?.phone_international,
                "email": profile?.email
            })
            setDetail(res.data?.data)
            setLoadingBackDrop(false)
        }
        
        if (profile !== null) {
            loadDetail()
        }

    }, [profile, houseInfo]);


    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }

    }, [context]);

    return (
        <Box>
            <Box sx={{ marginTop: 10 }}>
                <Container sx={{ padding: 3 }}>
                    <Stack direction={"column"} spacing={2}>
                        <Typography variant={"h6"} sx={{ color: theme.palette.text.primary, fontWeight:"bold"}}>{dict.Name_On_Contract_TXT[profile?.language]}</Typography>
                        <Typography variant={"body1"} sx={{ color: theme.palette.text.primary}}>{profile?.language === "th" ? detail?.mailing_customer_name : detail?.mailing_customer_name_en}</Typography>
                        <Typography variant={"h6"} sx={{ color: theme.palette.text.primary, fontWeight:"bold"}}>{dict.MobilePhone_Number_TXT[profile?.language]}</Typography>
                        <Typography variant={"body1"} sx={{ color: theme.palette.text.primary}}>{[detail?.mobile,detail?.mobile_2,detail?.phone_international].filter(phone => phone !== "-").join(" , ") || "-"}</Typography>
                        <Typography variant={"h6"} sx={{ color: theme.palette.text.primary , fontWeight:"bold"}}>{dict.Email_TXT[profile?.language]}</Typography>
                        <Typography variant={"body1"} sx={{ color: theme.palette.text.primary}}>{detail?.email}</Typography>
                        <Typography variant={"h6"} sx={{ color: theme.palette.text.primary , fontWeight:"bold"}}>{dict.Delivery_Address_TXT[profile?.language]}</Typography>
                        <Typography variant={"body1"} sx={{ color: theme.palette.text.primary}}>{profile?.language === "th" ? detail?.mailing_full_address : detail?.mailing_full_address_en}</Typography>
                    </Stack>

                    {/* <Box sx={{ marginTop: 2 }}>
                        <Typography variant={"body2"}>* {dict.Change_Delivery_Address_TXT[profile?.language]}</Typography>
                    </Box> */}

                </Container>

            </Box>
            <BackDropLoading
                loading={loadingBackDrop}
            />
        </Box>
    )
}
