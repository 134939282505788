import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { ModalError } from "../../../ui-component";
import { LoanDetail } from "../../Loan/LoanDetail";
export const LoanCPStatusDetail = () => {
    const [modalError, setModalError] = useState({ errorResponse: null, open: false });
    const [detail, setDetail] = useState(null)
    const [profile, setProfile] = useState(null)
    const location = useLocation()
    const context = useContext(ProfileContext)
    useEffect(() => {
        if (profile !== null) {
            setDetail(location.state?.detail)

        }
    }, [profile, location.state?.detail]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])
    return (
        <>
            <Box sx={{ height: "calc(100vh - 60px)" }}>
                <LoanDetail detail={detail} profile={profile} />
            </Box>
            <ModalError
                onClose={() => setModalError({ errorResponse: null, open: false })}
                open={modalError.open}
                error={modalError.errorResponse}
            />
        </>
    );
};
