import React, { useState, useEffect, useContext } from 'react'
import {
    Grid, Box,
    Typography, Stack, Button
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import * as dict from '../../dictionary'
import { ProfileContext } from '../../contexts/ProfileContext';
import moment from 'moment';


export const HistoryDetail = (props) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
    const [data, setData] = useState(null)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scalePdf, setScalePdf] = useState(0.5)
    const context = useContext(ProfileContext)
    const [profile, setProfile] = useState(null)
    const location = useLocation()
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    useEffect(() => {

        if (location.state) {
            setData(location.state)
        }

    }, [location.state]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }
    }, [context])

    return (

        <Box sx={{ padding: 2, marginTop: 8 }}>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant={"h5"}>{profile?.language === "th" ? data?.term_name : data?.term_name_eng} : </Typography>
                <Typography variant={"h5"}>{data?.pay_amount?.toLocaleString() || data?.paid_amount?.toLocaleString()}</Typography>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant={"body1"}>{dict.Payment_Date_TXT[profile?.language]} : </Typography>
                <Typography variant={"body1"}>{moment(data?.payment_date).format("DD/MM/YYYY")}</Typography>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant={"body1"}>{dict.Pay_By_TXT[profile?.language]} : </Typography>
                <Typography variant={"body1"}>{data?.receipt_payment_type_text}</Typography>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant={"body1"}>{dict.Status_TXT[profile?.language]} : </Typography>
                <Typography variant={"body1"}>{data?.status_upload}</Typography>
            </Stack>
            <Grid container sx={{ marginTop: 2 }}>


                <Grid item xs={12}>
                    {data?.payment_date && <>
                        <Stack direction={"row"} spacing={2} justifyContent={"space-between"} sx={{ margin: 1 }}>

                            <Box>
                                <Button variant={"contained"} onClick={() => window.open(data?.receipt_link)}> Download </Button>

                            </Box>

                            <Stack direction={"row"} spacing={1}>
                                <Button variant={"contained"} onClick={() => setScalePdf(scalePdf + 0.5)}> <ZoomInIcon /> </Button>
                                <Button variant={"contained"} disabled={scalePdf === 0.5} onClick={() => setScalePdf(scalePdf - 0.5)}> <ZoomOutIcon /> </Button>

                            </Stack>

                        </Stack>
                        <div style={{
                            height: "70vh",
                            overflow: "scroll",
                            borderColor: "#E3E3E3",
                            border: "solid",

                        }}>

                            {
                                (data?.receipt_link) && <Document
                                    file={data?.receipt_link} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page scale={scalePdf} pageNumber={pageNumber} />
                                </Document>
                            }
                        </div>
                        <Stack direction={"row"} spacing={1} justifyContent={"center"} sx={{ margin: 1 }} alignItems={"center"}>

                            <Button disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}><SkipPreviousIcon /></Button>
                            {pageNumber}/{numPages}
                            <Button disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}><SkipNextIcon /></Button>

                        </Stack>
                    </>
                    }

                    {
                        data?.status_upload === `System in progress.` && <Box sx={{ minHeight: "300px", border: 1, display:"flex", justifyContent:"center",textAlign:"center" , alignItems:"center" }}>
                            <Box >
                            <Typography variant="h6" sx={{ color: "red"}} >{dict.Still_In_Processs_TXT[profile?.language]}</Typography>
                                <Typography variant="h6" sx={{ color: "red" }} >{dict.Still_In_Processs_2_TXT[profile?.language]}</Typography>

                            </Box>
                    

                        </Box>
                    }


                </Grid>

            </Grid>
        </Box>

    )
}
