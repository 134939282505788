import axios from "axios";

export const getDocumentBeforeTransfer = async (data) => {

    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/fastinspect/document_before_transfer`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "house_number":data.house_number,
            }
        })

        return result
    } catch (error) {
        return error
    }

}
