
import React, { useState, useEffect, useRef, useContext } from 'react'
import {
    Container,
    Card,
    Typography,
    AppBar,
    Toolbar,
    Box,
    Tab,
    Tabs,
    Chip,
    Stack,
    Grid,
    CardMedia,
    Divider,
    SwipeableDrawer,
    Paper,
    List,
    ListItem,
    Button
} from '@mui/material';
import { ArrowBackIos, HolidayVillage, FileDownload } from '@mui/icons-material';
import logoQrcode from '../../assets/images/logo/qr-code-logo.png'
import logoDebitCard from '../../assets/images/logo/debit-card.png'
import logoMobileBank from '../../assets/images/logo/mobile-banking.png'
import { ProfileContext } from '../../contexts/ProfileContext';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackDropLoading } from '../../ui-component'
import { ChipRoomInfo } from '../../ui-component/ChipRoomInfo';
import * as api from '../../api/BillPayment'
import * as dict from '../../dictionary'
import { useTheme } from '@mui/material'

export const BillPayment = () => {
    const [tabSelected, setTabSelected] = useState("waiting")
    const [isVisibleMenuMethod, setIsVisibleMenuMethod] = useState(false)
    const [methodType, setMethodType] = useState(null)
    const [houseInfo, setHouseInfo] = useState(null)
    const [billPaymentPM, setBillPaymentPM] = useState([])
    const [billPaymentREM, setBillPaymentREM] = useState([])
    const [loadingBackDrop, setLoadingBackDrop] = useState(false)
    const [lineId, setLineId] = useState(null)
    const [profile, setProfile] = useState(null)
    const [detail, setDetail] = useState(null)
    let houseInfoSelected = localStorage.getItem("houseInfoSelected")
    const [houseInfoParse] = useState(JSON.parse(houseInfoSelected))
    const myRef = useRef(null)
    const context = useContext(ProfileContext)
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()

    let isKbank = localStorage.getItem("kbank")


    const payOrPaid = (element) => {
        if (element.pay_amount) {
            return element.pay_amount?.toLocaleString()
        }
        return element.paid_amount?.toLocaleString()
    }

    const checkDisabledCreditMethod = (term_id) => {
        let term = parseInt(term_id)
        if (term === 998) {
            return true
        }
        if (term >= 200 && term <= 299) {
            return true
        }
        return false
    }

    const checkDisabled = (element, index, temp) => {

        if (element.product_type === "rental") {
            return false
        }

        if (index === 0 || (element.invoice_due_date === temp[0].invoice_due_date)) {
            return false
        }
        return true
    }

    const backgroundDisabled = (element, index, temp) => {

        if (element.product_type === "rental") {
            return false
        }

        if (tabSelected !== "waiting") return

        if (index === 0 || (element.invoice_due_date === temp[0].invoice_due_date)) {
            return ""
        }
        return "#E0E0E0"
    }

    const statusShows = (date) => {
        let expiration = moment(date).format("YYYY-MM-DD");
        let current_date = moment().format("YYYY-MM-DD");
        let days = moment(expiration).diff(current_date, 'days');

        if (days < 0) return <Typography variant='caption' fontWeight={"bold"} sx={{ color: "#EB5757" }}> {dict.Over_Payment_Due_TXT[profile?.language]} </Typography>
        return <Typography variant='caption' fontWeight={"bold"} sx={{ color: "#F2994A" }}> {dict.Wait_Payment_TXT[profile?.language]} </Typography>
    }

    const receiptStatusShows = (label) => {
        if(label === "รอยืนยันเงิน" || label === "Waiting Confirm"){
            return <Typography variant='caption' fontWeight={"bold"} sx={{ color: "#FFA500" }} >{label}</Typography>
        }
        return <Typography variant='caption' fontWeight={"bold"} sx={{ color: "#81B622" }} >{label}</Typography>
    }

    const InsideCard = ({ element }) => {
        return <Box sx={{ width: "100%", p: 3 }}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ flexShrink: 0 }}>
                    {dict.Bill_Type_TXT[profile?.language]}

                </Typography>
                <Typography variant="body2" fontWeight={"bold"} sx={{ wordBreak: "break-word"}}>
                    {profile?.language === "th" ? element.term_name : element.term_name_eng || "-"}

                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" mt={1}>
                <Typography variant="body2" >
                    {dict.Pay_Due_TXT[profile?.language]}

                </Typography>
                <Typography variant="body2" fontWeight={"bold"}>
                    {tabSelected === "waiting" ? moment(element.invoice_due_date).format("DD/MM/YYYY") : moment(element.payment_date).format("DD/MM/YYYY")}
                </Typography>
            </Stack>
            {
                tabSelected === "history" && <Stack direction="row" justifyContent="space-between" mt={1}>
                    <Typography variant="body2" >
                        {dict.Pay_Date_TXT[profile?.language]}

                    </Typography>
                    <Typography variant="body2" fontWeight={"bold"}>
                        {/* {tabSelected === "waiting" ? moment(element.invoice_due_date).format("DD/MM/YYYY") : moment(element.payment_date).format("DD/MM/YYYY")} */}
                        {moment(element.payment_date).format("DD/MM/YYYY")}
                    </Typography>
                </Stack>
            }
            <Stack direction="row" justifyContent="space-between" mt={1}>
                <Typography variant="body2" sx={{ flexShrink: 0 }} >
                    {dict.Bill_Amount_TXT[profile?.language]}

                </Typography>
                <Typography variant="body2" fontWeight={"bold"} sx={{ wordBreak: "break-word"}}>
                    {tabSelected === "waiting" ? (element.balance?.toLocaleString()) : payOrPaid(element)} {dict.Baht_TXT[profile?.language]}
                </Typography>
            </Stack>
            {
                tabSelected === "history" && <Stack direction="row" justifyContent="space-between" mt={1}>
                    <Typography variant="body2" >
                        {dict.Pay_Via_TXT[profile?.language]}

                    </Typography>
                    <Typography variant="body2" fontWeight={"bold"} >
                        {element.receipt_payment_type_text || "-"}
                    </Typography>
                </Stack>
            }
            <Stack direction="row" justifyContent="space-between" mt={1}>
                <Typography variant="body2" >
                    {dict.Bill_Status_TXT[profile?.language]}
                </Typography>
                <Typography variant="caption" >
                    {tabSelected === "waiting" ? <Chip label={statusShows(element.invoice_due_date)} /> : <Chip label={receiptStatusShows(element.status_upload)} />}
                </Typography>
            </Stack>
            {
                (tabSelected === "history" && (element.status_upload === "ชำระเงินสำเร็จ" || element.status_upload === "Payment Confirmed")) && <>
                    <Divider sx={{ ml: -10, mr: -10, mt: 2 }} />
                    <Stack direction={"row"} justifyContent={"center"} mt={1}>
                        <Typography variant='body1' fontWeight={"bold"} color={theme.palette.primary.main}>
                            {dict.Receipt_Payment_TXT[profile?.language]}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={1} mt={1}>
                        <Button variant="outlined" startIcon={<FileDownload />} sx={{ width: "100%", borderRadius: "5px" }} onClick={() => window.open(element.receipt_link_eng)}><Typography variant='body2'>English</Typography></Button>
                        <Button variant="outlined" startIcon={<FileDownload />} sx={{ width: "100%", borderRadius: "5px" }} onClick={() => window.open(element.receipt_link)}><Typography variant='body2'>ภาษาไทย</Typography></Button>
                    </Stack>
                </>
            }


        </Box>
    }

    useEffect(() => {

        const loadBillPaymentHistory = async () => {
            setLoadingBackDrop(true)
            let res = await api.getBillPaymentHistory({
                "line_id": profile?.line_id,
                "project_id": houseInfo.project_id || houseInfoParse.project_id,
                "unit_number": houseInfo.unit_number || houseInfoParse.unit_number,
                "is_rem": houseInfo.is_rem || houseInfoParse.is_rem,
                "is_pm": houseInfo.is_pm || houseInfoParse.is_pm,
                "is_rental": houseInfo.is_rental || houseInfoParse.is_rental
            })
            setLoadingBackDrop(false)
            if (res == null) return
    
            let objData = res.data
            let tempPM = []
            let tempREM = []
            objData?.map(element => {
                if (element.product_type === "remlite") {
                    return tempREM.push(element)
                }
                return tempPM.push(element)
            })
            setBillPaymentPM(tempPM)
            setBillPaymentREM(tempREM)
        }

        const loadBillPayment = async () => {

            setLoadingBackDrop(true)
            let res = await api.getBillPayment({
                "line_id": lineId,
                "project_id": houseInfo?.project_id || houseInfoParse.project_id,
                "unit_number": houseInfo?.unit_number || houseInfoParse.unit_number,
                "is_rem": houseInfo?.is_rem || houseInfoParse.is_rem,
                "is_pm": houseInfo?.is_pm || houseInfoParse.is_pm,
                "is_rental": houseInfo.is_rental || houseInfoParse.is_rental
            })
            setLoadingBackDrop(false)
            if (res == null) return
    
    
            let objData = res.data
            let tempPM = []
            let tempREM = []
            objData?.map(element => {
                if (element.product_type === "remlite") {
                    return tempREM.push(element)
                }
                return tempPM.push(element)
            })
            setBillPaymentPM(tempPM)
            setBillPaymentREM(tempREM)
        }

        const handleSelectedTab = async () => {

            if (tabSelected === "waiting") {
                return loadBillPayment()
            }
    
            if (tabSelected === "history") {
                return loadBillPaymentHistory()
            }
    
            return
        }

        if (lineId !== null) {
            setBillPaymentREM([])
            setBillPaymentPM([])
            handleSelectedTab()
        }

    }, [tabSelected, lineId, houseInfo, houseInfoParse, profile?.line_id])

    useEffect(() => {
        if (methodType !== null) {

            switch (methodType) {
                case 'qr-kbank':
                case 'credit-kbank':
                case 'qr': navigate('/billpayment/amountPayment' + location.search, {
                    state: { ...detail, methodType }
                })
                    break;
                case 'credit': navigate('/billpayment/creditCardSelecting' + location.search, {
                    state: { ...detail, methodType }
                })
                    break;
                case 'mobile-banking': navigate('/billpayment/bankSelecting' + location.search, {
                    state: { ...detail, methodType }
                })
                    break;
                default:
            }
        }
    }, [methodType, location.search, detail, navigate])

    useEffect(() => {

        if (location.state?.forceReloadPage === true) {
            window.history.replaceState({}, document.title)
            window.location.reload()
        }

    }, [location])

    useEffect(() => {

        let profileLineOA = JSON.parse(localStorage.getItem("profile_lineOA"))
        if (profileLineOA !== null || profileLineOA !== undefined) {
            let lineIdCache = profileLineOA?.profile?.userId
            setLineId(lineIdCache)
        }

        setTabSelected(tabSelected)

    }, [tabSelected])

    useEffect(() => {

        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context.state?.houseInfo !== null) {
            setHouseInfo(context.state?.houseInfo)
        }

    }, [context])

    return (
        <>
            <AppBar sx={{
                position: 'fixed',
                boxShadow: 'none',
                height: 60,
            }}>
                <Toolbar>
                    <Grid container item xs={1}>
                        <ArrowBackIos onClick={() => navigate(`/myhome${location.search}`)} />
                    </Grid>
                    <Grid container item xs={10} direction="row"
                        justifyContent="center"
                    >
                        <Typography>
                            {location.state?.title}
                        </Typography>

                    </Grid>

                </Toolbar>


            </AppBar>

            <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10, mt: 7 }} >
                <Tabs
                    value={tabSelected}
                    onChange={(e, val) => setTabSelected(val)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    centered
                    variant="fullWidth"
                    sx={{ backgroundColor: "white" }}

                >
                    <Tab value="waiting" label={dict.Wait_Pay_TXT[profile?.language]} />
                    <Tab value="history" label={dict.History_TXT[profile?.language]} />

                </Tabs>
            </Box>

            <Container maxWidth="xl" sx={{ mb: 5 }} >

                <BackDropLoading
                    loading={loadingBackDrop}
                />


                <Stack direction={"row"} alignItems="center" justifyContent={"flex-start"}>
                    <ChipRoomInfo
                        houseInfo={houseInfo}
                        houseInfoParse={houseInfoParse}
                        icon={<HolidayVillage />}
                        lang={profile?.language}
                        sx={{
                            mt: 17
                        }}
                    />
                </Stack>
                {
                    billPaymentPM?.length > 0 && <>

                        <Box sx={{ marginTop: 3 }}>
                            <Divider textAlign="left" ><Typography variant='subtitle1'>{dict.General_Expense_TXT[profile?.language]}</Typography></Divider>
                        </Box>

                        <Stack mt={2} spacing={2} >
                            {billPaymentPM?.map((element, index) => {
                                return <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", display: "flex", backgroundColor: backgroundDisabled(element, index, billPaymentPM) }}
                                    onClick={() => {
                                        if (checkDisabled(element, index, billPaymentPM) === true && tabSelected === "waiting") {
                                            return
                                        }
                                        if (tabSelected === "waiting") {
                                            setDetail({ ...element, language: profile.language })
                                            setIsVisibleMenuMethod(true)
                                            return
                                        }


                                    }}>
                                    <InsideCard element={element} />
                                </Card>
                            })
                            }

                        </Stack>
                    </>}

                {
                    billPaymentREM?.length > 0 && <>
                        <Box sx={{ marginTop: 3 }}>
                            <Divider ref={myRef} textAlign="left" ><Typography variant='subtitle1'>{dict.Pending_Pay_TXT[profile?.language]}</Typography></Divider>
                        </Box>


                        <Stack mt={2} spacing={2} >
                            {billPaymentREM?.map((element, index) => {

                                return <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{ borderRadius: "10px", boxShadow: "0px 0px 7px 0px #00000040", display: "flex", backgroundColor: backgroundDisabled(element, index, billPaymentREM) }}
                                    onClick={() => {
                                        if (checkDisabled(element, index, billPaymentREM) === true && tabSelected === "waiting") {
                                            return
                                        }
                                        if (tabSelected === "waiting") {
                                            setDetail(element)
                                            setIsVisibleMenuMethod(true)
                                            return
                                        }

                                    }}>
                                    <InsideCard element={element} />
                                </Card>
                            })}
                        </Stack>
                    </>
                }

            </Container >

            <SwipeableDrawer
                anchor={'bottom'}
                disableSwipeToOpen
                open={isVisibleMenuMethod}
                onClose={() => {
                    setIsVisibleMenuMethod(false)
                    // เอาออกก่อนไม่แน่ใจว่ามีผลอะไรไหม setDetail(null)
                }}
                onOpen={() => setIsVisibleMenuMethod(true)}
            >
                <Box sx={{ minHeight: "35vh" }}>
                    <Paper variant="outlined" sx={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography>{dict.Choose_Method_TXT[profile?.language]}</Typography>
                    </Paper>
                    <List>
                        <ListItem
                            sx={{ height: "80px", display: "flex", alignItems: "center" }}
                            onClick={() => {
                                if (isKbank === "true") {
                                    return setMethodType("qr-kbank")
                                }
                                return setMethodType("qr")
                            }}
                        >
                            <Stack spacing={2} direction="row" alignItems="center">
                                <CardMedia
                                    component="img"
                                    sx={{ width: 55 }}
                                    src={logoQrcode}
                                />
                                <Typography>{dict.Pay_via_Qr_TXT[profile?.language]}</Typography>
                            </Stack>
                        </ListItem>
                        <Divider light />
                        {
                            checkDisabledCreditMethod(detail?.term_id) === false && <> <ListItem
                                sx={{ height: "80px", display: "flex", alignItems: "center", }}
                                onClick={() => {
                                    if (isKbank === "true") {
                                        return setMethodType("credit-kbank")
                                    }
                                    return setMethodType("credit")
                                }} >
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 55 }}
                                        src={logoDebitCard}
                                    />
                                    <Typography>{dict.Pay_via_Card_TXT[profile?.language]}</Typography>
                                </Stack>
                            </ListItem>
                                <Divider light />
                            </>
                        }
                        {
                            isKbank !== "true" && <ListItem
                                sx={{ height: "80px", display: "flex", alignItems: "center" }}
                                onClick={() => {
                                    setMethodType("mobile-banking")
                                }} >
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 55 }}
                                        src={logoMobileBank}
                                    />
                                    <Typography >{dict.Pay_via_MobileBank_TXT[profile?.language]}</Typography>
                                </Stack>
                            </ListItem>
                        }
                        <Divider light />
                    </List>
                </Box>

            </SwipeableDrawer>
        </>


    )
}
