import React from 'react'
import { 
    Box, 
    Typography, 
    CardMedia,
    Grid
} from '@mui/material'
import checkLogo from '../../assets/images/logo/check.png'
export const ResultSuccess = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "70vh", p: 5 }}>
            <Grid container direction={"column"} justifyContent="center" alignItems="center" >
                <CardMedia
                    component="img"
                    sx={{ width: "50%"}}
                    src={checkLogo}
                />
                <Typography>การชำระเงินเสร็จสิ้น</Typography>
                <Typography>Payment Successful</Typography>
            </Grid>

        </Box>
    )
}
