
import React, { useState } from "react"
import { useLocation } from "react-router-dom";
import logoTH from '../assets/images/logo/thailand.png'
import logoEN from '../assets/images/logo/united-states.png'
import * as api from '../api'
import { BackDropLoading } from "../ui-component";
import {
    Stack,
    Typography,
    Box, Grid,
    CardMedia, Dialog, Slide, Alert
} from '@mui/material'
import { CheckCircle } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const Language = (props) => {
    const location = useLocation()

    const [isVisibleAlert, setIsVisibleAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const chooseApiLanguage = async (lang) => {
        let profile_lineOA = localStorage.getItem("profile_lineOA")
        let profile = JSON.parse(profile_lineOA)

        if (location.pathname === "/profile") {
            return await api.changeLanguage({
                "language": lang,
                "line_id": profile.profile.userId,
            })
        }

        if (location.pathname === "/registration" || location.pathname === "/etc") {
            return await api.selectLanguage({
                "company_id": localStorage.getItem("cid"),
                "company_id_pm": localStorage.getItem("cid_pm"),
                "company_id_rental": localStorage.getItem("cid_rental"),
                "language": lang,
                "line_id": profile.profile.userId,
                "name": profile.profile.displayName
            })
        }
    }

    const handleSelect = async (lang) => {
        setLoading(true)
        let result = await chooseApiLanguage(lang)
        setLoading(false)
        if (result.data?.result !== true) {
            return setIsVisibleAlert(true)
        }
        if (typeof props.response === "function") {
            props.response(lang)
        }
        return props.onClose()
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            // onClose={() => props.onClose()}
            open={props.isVisible}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    width: "100%",
                    minWidth: "320px !important",
                }
            }}
        // onBackdropClick={(e) => e.preventDefault() }
        >
            <Box
                padding={"30px"}
            >


                <Stack spacing={4} alignItems="center">
                    <Box sx={{ textAlign: "center" }}>

                        <Typography mt={2} variant={"body1"} color="InfoText">เลือกภาษา (Select Language) </Typography>

                    </Box>

                    <Stack direction={"column"} spacing={4} justifyContent="center">

                        <Box
                            sx={{
                                boxShadow: props?.defaultLanguage === "th" && '0px 2px 10px 0px #0000001A',
                                width: "250px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                pl: 2,
                                pr: 2,
                                pb: 1,
                                pt: 1,
                                justifyContent: "center",
                            }}
                            onClick={() => handleSelect("th")}

                        >
                            <Grid container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 37 }}
                                        src={logoTH}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography >ภาษาไทย (TH)</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {props?.defaultLanguage === "th" && <CheckCircle color={"success"} />}
                                </Grid>

                            </Grid>
                        </Box>

                        <Box
                            sx={{
                                boxShadow: props?.defaultLanguage === "en" && '0px 2px 10px 0px #0000001A',
                                width: "250px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                pl: 2,
                                pr: 2,
                                pb: 1,
                                pt: 1,
                                justifyContent: "center",
                            }}
                            onClick={() => handleSelect("en")}
                        >
                            <Grid container alignItems={"center"}>
                                <Grid item xs={4}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 37 }}
                                        src={logoEN}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography >English (EN)</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {props?.defaultLanguage === "en" && <CheckCircle color={"success"} />}
                                </Grid>

                            </Grid>
                        </Box>

                    </Stack>

                </Stack>

            </Box>

            {
                isVisibleAlert && <Alert variant="filled" severity="error">
                    เกิดข้อผิดพลาดระบบไม่สามารถเลือกภาษาให้คุณได้
                    <br />
                    An error has occurred. The system cannot select the language for you.
                </Alert>
            }

            <BackDropLoading
                loading={loading}
            />

        </Dialog >

    )

}