import './App.css';
import HeaderChildren from './layout/HeaderChildren';
import Router from './routes/index.js'
import { useEffect, useState, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LanguageContext } from './contexts/LanguageContext';
import { ProfileContext } from './contexts/ProfileContext';
import { LoadingContext } from './contexts/LoadingContext'
import "@fontsource/prompt"
import "@fontsource/noto-sans"; 

function App() {
  const languageContext = useContext(LanguageContext);
  const profileContext = useContext(ProfileContext)
  const loadingContext = useContext(LoadingContext)
  const [language, setLanguage] = useState("EN")
  const [theme, setTheme] = useState(createTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f44336",
      },
      text: {
        primary: "#fffff"
      }
    },
    typography: {
      fontFamily: "Prompt",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
    },
  }))


  const conditionShowHeader = () => {

    if(loadingContext?.state?.isLoading === true) return

    return <HeaderChildren />

  }


  const loadTheme = (profile) => {

    let theme = createTheme({
      palette: {
        primary: {
          main: profile?.primary_color || "#3f51b5",
        },
        secondary: {
          main: profile?.second_color || "#f44336",
        },
        text: {
          primary: profile?.text_color || "#fffff"
        }
      },
      typography: {
        fontFamily: "Prompt",
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '10px',
            },
          },
        },
      },
    })

    setTheme(theme)
  }


  useEffect(() => {
    if (languageContext?.state) {
      const { languageSelected } = languageContext?.state
      setLanguage(languageSelected)
    }

  }, [languageContext])

  useEffect(() => {
    if (profileContext.state?.profile !== null) {
      loadTheme(profileContext.state?.profile?.color)
    }
  }, [profileContext])

  // useEffect(() => {
  //   if (loadingContext?.state?.isLoading !== undefined) {
  //     setIsLoading(loadingContext?.state?.isLoading)
  //   }
  // }, [loadingContext]);


  return (
    <div className="App">
      <ThemeProvider theme={theme} >
        {conditionShowHeader()}
        < Router setting={{ language: language }} />
      </ThemeProvider>
    </div>
  );
}

export default App;
