import axios from "axios";

export const getProjectList = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/all`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "keyword": data.keyword,
                "type": data.type,
                "bts_id": data.bts_id,
                "mrt_id": data.mrt_id,
                "location_id": data.location_id,
                "province_id": data.province_id,
                "district_id": data.district_id,
                "subdistrict_id": data.subdistrict_id,
                "page": data.page,
                "limit": data.limit
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getPromotionList = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/post/list`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "type": data.type,
                "page": data.page,
                "limit": data.limit
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getImageProjectList = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/images`,
            data: {
                "line_id": data.line_id,
                "product_type": data.product_type,
                "project_id":data.project_id,
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getPromotionDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/post/detail`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "id": data.id
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getProjectDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/detail`,
            data: {
                "project_id": data.project_id,
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type
            }
        })
        return result

    } catch (error) {
        return error
    }
}


export const getPostDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/post/detail`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "id": data.id
            }
        })
        return result

    } catch (error) {
        return error
    }
}

export const getFilterDropdown = async (data) => {
    try {
        let result = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/filter`,
        })
        return result

    } catch (error) {
        return error
    }
}