export const Pls_Name_Suggestion_TXT = []
Pls_Name_Suggestion_TXT["en"] = "Please name the suggestion title."
Pls_Name_Suggestion_TXT["th"] = "กรุณาตั้งชื่อหัวข้อเสนอแนะ"

export const FeedBack_TXT = []
FeedBack_TXT["en"] = "Feedback details"
FeedBack_TXT["th"] = "รายละเอียดข้อเสนอแนะ"

export const SuggestionForTeam_TXT = []
SuggestionForTeam_TXT["en"] = "Suggestions for the management team (juristic person)"
SuggestionForTeam_TXT["th"] = "ข้อเสนอแนะสำหรับทีมบริหาร (นิติบุคคล)"

export const Provide_FeedBack_TXT = []
Provide_FeedBack_TXT["en"] = "Please provide feedback."
Provide_FeedBack_TXT["th"] = "กรุณากรอกรายละเอียดข้อเสนอแนะ"

export const Limit_Photo_TXT = []
Limit_Photo_TXT["en"] = "Add photos (up to 3 photos)"
Limit_Photo_TXT["th"] = "เพิ่มรูป (ไม่เกิน 3 รูป)"

export const Tel_Callback_TXT = []
Tel_Callback_TXT["en"] = "Telephone number (in case of contact)"
Tel_Callback_TXT["th"] = "เบอร์โทรศัพท์ (กรณีติดต่อกลับ)"

export const Recommend_Save_TXT = []
Recommend_Save_TXT["en"] = "Your recommendation has been successfully saved."
Recommend_Save_TXT["th"] = "ระบบได้บันทึกคำแนะนำของคุณเข้าสู่ระบบเรียบร้อยแล้ว"

export const Saving_Data_TXT = []
Saving_Data_TXT["en"] = "Saving Data"
Saving_Data_TXT["th"] = "กำลังบันทึกข้อมูล"

export const Suggestion_Title_TXT = []
Suggestion_Title_TXT["en"] = "Title"
Suggestion_Title_TXT["th"] = "หัวข้อเสนอแนะ"