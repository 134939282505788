export const Contract_Detail_TXT = []
Contract_Detail_TXT["en"] = "Contract Details"
Contract_Detail_TXT["th"] = "รายละเอียดสัญญา"

export const Contract_TXT = []
Contract_TXT["en"] = "Contractor"
Contract_TXT["th"] = "ผู้ทำสัญญา"

export const Booking_Number_TXT = []
Booking_Number_TXT["en"] = "Booking Number"
Booking_Number_TXT["th"] = "เลขที่ใบจอง"

export const Contract_Number_TXT = []
Contract_Number_TXT["en"] = "Contract Number"
Contract_Number_TXT["th"] = "เลขที่สัญญา"

export const Contract_Date_TXT = []
Contract_Date_TXT["en"] = "Contract Date"
Contract_Date_TXT["th"] = "วันที่ทำสัญญา"

export const Contract_Code_TXT = []
Contract_Code_TXT["en"] = "Contract Code"
Contract_Code_TXT["th"] = "รหัสสัญญา"

export const Room_Info_TXT = []
Room_Info_TXT["en"] = "Room Conversion Information"
Room_Info_TXT["th"] = "ข้อมูลแปลงห้อง"

export const Plan_Room_TXT = []
Plan_Room_TXT["en"] = "Plan/Room"
Plan_Room_TXT["th"] = "แปลง/ห้อง"

export const House_Number_TXT = []
House_Number_TXT["en"] = "House Number"
House_Number_TXT["th"] = "บ้านเลขที่"

export const Plan_Design_TXT = []
Plan_Design_TXT["en"] = "Plan Design"
Plan_Design_TXT["th"] = "แบบบ้านห้อง"

export const Book_Date_TXT = []
Book_Date_TXT["en"] = "Book Date"
Book_Date_TXT["th"] = "วันที่จอง"

export const Price_info_TXT = []
Price_info_TXT["en"] = "Price Information"
Price_info_TXT["th"] = "ข้อมูลราคา"

export const NetSelling_TXT = []
NetSelling_TXT["en"] = "Net Selling Price"
NetSelling_TXT["th"] = "ราคาขายสุทธิ"

export const HousePrice_TXT = []
HousePrice_TXT["en"] = "House Price"
HousePrice_TXT["th"] = "ราคาบ้าน"

export const Price_Per_WA_TXT = []
Price_Per_WA_TXT["en"] = "Price Per"
Price_Per_WA_TXT["th"] = "ราคาต่อตารางวา"

export const Booking_Price_TXT = []
Booking_Price_TXT["en"] = "Booking Money"
Booking_Price_TXT["th"] = "เงินจอง"

export const Contract_Money_TXT = []
Contract_Money_TXT["en"] = "Contract Money"
Contract_Money_TXT["th"] = "เงินทำสัญญา"

export const Deposit_TXT = []
Deposit_TXT["en"] = "Deposit"
Deposit_TXT["th"] = "เงินดาวน์"

export const Deposit_per_month_TXT = []
Deposit_per_month_TXT["en"] = "Deposit Payment Per Month"
Deposit_per_month_TXT["th"] = "ดาวน์ต่อเดือน"

export const Number_Deposit_TXT = []
Number_Deposit_TXT["en"] = "Number Of Deposit"
Number_Deposit_TXT["th"] = "จำนวนงวดดาวน์"

export const Money_Transfer_TXT = []
Money_Transfer_TXT["en"] = "Money Transfer"
Money_Transfer_TXT["th"] = "เงินโอน"

export const Discount_TXT = []
Discount_TXT["en"] = "Discount"
Discount_TXT["th"] = "ส่วนลด"

export const Discount_Pro_Day_TXT = []
Discount_Pro_Day_TXT["en"] = "Discount On Promotion Day"
Discount_Pro_Day_TXT["th"] = "ส่วนลด ณ วันโปรโมชั่น"

export const Discount_Transfer_TXT = []
Discount_Transfer_TXT["en"] = "Discount On Transfer Date"
Discount_Transfer_TXT["th"] = "ส่วนลด ณ วันโอน"

export const Own_Project_TXT = []
Own_Project_TXT["en"] = "Owned Project"
Own_Project_TXT["th"] = "โครงการที่เป็นเจ้าของ"

export const Profile_TXT = []
Profile_TXT["en"] = "Profile"
Profile_TXT["th"] = "ข้อมูลส่วนตัว"

export const Privacy_Policy_TXT = []
Privacy_Policy_TXT["en"] = "Privacy Policy"
Privacy_Policy_TXT["th"] = "นโยบายความเป็นส่วนตัว"

export const Condition_TXT = []
Condition_TXT["en"] = "Condition"
Condition_TXT["th"] = "เงื่อนไข"

export const Notification_TXT = []
Notification_TXT["en"] = "Notification"
Notification_TXT["th"] = "การแจ้งเตือน"

export const Logout_TXT = []
Logout_TXT["en"] = "Logout"
Logout_TXT["th"] = "ออกจากระบบ"

export const Question_Logout_TXT = []
Question_Logout_TXT["en"] = "Do you want to log out?"
Question_Logout_TXT["th"] = "คุณต้องการออกจากระบบหรือไม่ ?"

export const Error_Logout_TXT = []
Error_Logout_TXT["en"] = "Error unable to logout"
Error_Logout_TXT["th"] = "เกิดข้อผิดพลาด ไม่สามารถออกจากระบบได้"

export const Error_Noti_Set_TXT = []
Error_Noti_Set_TXT["en"] = "Error Unable to set notifications"
Error_Noti_Set_TXT["th"] = "เกิดข้อผิดพลาด ไม่สามารถตั้งค่าการแจ้งเตือนได้"

export const Noti_Open_Success_TXT = []
Noti_Open_Success_TXT["en"] = "Notification successfully opened"
Noti_Open_Success_TXT["th"] = "เปิดการแจ้งเตือนสำเร็จแล้ว"

export const Noti_Close_Success_TXT = []
Noti_Close_Success_TXT["en"] = "Notification has been closed successfully."
Noti_Close_Success_TXT["th"] = "ปิดการแจ้งเตือนสำเร็จแล้ว"

export const Change_Lang_TXT = []
Change_Lang_TXT["en"] = "เปลี่ยนภาษา (Change Language)"
Change_Lang_TXT["th"] = "เปลี่ยนภาษา (Change Language)"

export const OK_TXT = []
OK_TXT["en"] = "OK"
OK_TXT["th"] = "ตกลง"

export const Pls_Enter_Phone_TXT = []
Pls_Enter_Phone_TXT["en"] = "Please enter your phone number."
Pls_Enter_Phone_TXT["th"] = "โปรดระบุหมายเลขโทรศัพท์"

export const Enter_Phone_TXT = []
Enter_Phone_TXT["en"] = "Specify a new phone number."
Enter_Phone_TXT["th"] = "ระบุหมายเลขโทรศัพท์ใหม่"

export const Edit_TXT = []
Edit_TXT["en"] = "Edit"
Edit_TXT["th"] = "แก้ไข"

export const Pls_Enter_Email_TXT = []
Pls_Enter_Email_TXT["en"] = "Please enter a valid email address."
Pls_Enter_Email_TXT["th"] = "โปรดระบุอีเมล์ที่ใช้งาน"

export const Enter_Email_TXT = []
Enter_Email_TXT["en"] = "Enter a new email address."
Enter_Email_TXT["th"] = "ระบุอีเมล์ใหม่"

export const Birth_Date_TXT = []
Birth_Date_TXT["en"] = "Birthdate"
Birth_Date_TXT["th"] = "วันเกิด"

export const System_Saved_Info_TXT = []
System_Saved_Info_TXT["en"] = "The system has saved your information."
System_Saved_Info_TXT["th"] = "ระบบได้บันทึกข้อมูลของคุณแล้ว"

export const Err_Saving_TXT = []
Err_Saving_TXT["en"] = "Error saving data."
Err_Saving_TXT["th"] = "เกิดข้อผิดพลาดในการบันทึกข้อมูล"

export const Doc_Reservation_TXT = []
Doc_Reservation_TXT["en"] = "Reservation Document"
Doc_Reservation_TXT["th"] = "เอกสารใบจอง"


export const Doc_Contract_TXT = []
Doc_Contract_TXT["en"] = "Contract Document"
Doc_Contract_TXT["th"] = "เอกสารใบสัญญา"