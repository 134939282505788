export const TitleSuccess_TXT = []
TitleSuccess_TXT["th"] = "บันทึกข้อมูลสำเร็จ"
TitleSuccess_TXT["en"] = "Successfully"

export const TitleError_TXT = []
TitleError_TXT["th"] = "เกิดข้อผิดพลาด"
TitleError_TXT["en"] = "Error"

export const CardRespondAccept_TXT = []
CardRespondAccept_TXT["th"] = "ตกลง"
CardRespondAccept_TXT["en"] = "OK"

export const DefaultTextError_TXT = []
DefaultTextError_TXT["th"] = "ระบบเกิดความขัดข้อง ไม่สามารถบันทึกข้อมูลได้"
DefaultTextError_TXT["en"] = "System wrong cannot be saved."
