import axios from 'axios'

export const getProjectModelDetail = async (data) => {
    try {
        let result = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/model/detail`,
            data: {
                "line_id": data.line_id,
                "language": data.language,
                "product_type": data.product_type,
                "project_id": data.project_id,
                "model_id": data.model_id            
            }
        })
        return result

    } catch (error) {
        return error
    }
}