import axios from "axios";

export const getManualList = async (data) => {
  try {
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/list`,
      data: {
        line_id: data.line_id,
        product_type: data.product_type,
        project_id: data.project_id,
        type: "knowledgeInfo",
      },
    });
    return result;
  } catch (error) {
    return error;
  }
};

export const getManualDetail = async (data) => {
  try {
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_LINEOA_V2_HOST_API}/lineoa/project/post/detail`,
      data: {
        line_id: data.line_id,
        product_type: data.product_type,
        id: data.id,
      },
    });
    return result;
  } catch (error) {
    return error;
  }
};
